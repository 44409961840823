const nb = {
  language: 'nb',
  userRoles: {
    admin: 'Admin',
    planner: 'Normal',
    read_only_planner: 'Lesetilgang',
    plus_planner: 'Plus',
    partner: 'Partner',
    customer: 'Kunde',
    customs_agent: 'Tollagent',
    handler: 'Behandler'
  },
  emptyStates: { notFoundTitle: 'Ingen resultater' },
  application: {
    recipient: 'Mottaker',
    save: 'Lagre',
    update: 'Oppdater',
    required: '*Obligatorisk felt',
    requiredShort: '*Obligatorisk',
    lastUpdated: 'Sist oppdatert',
    requiredLocation: '* Lokasjon mangler',
    search: 'Søk',
    logOut: 'Logg ut',
    proceed: 'Fortsett',
    courier: 'Sjåfør',
    couriers: 'Sjåfører',
    consignments: 'Leveranser',
    address: 'Addresse',
    estimated: 'Estimert',
    adjusted: 'Justert',
    actual: 'Faktisk',
    funeral: 'Begravelse',
    tracking: 'Sporing',
    trackingPage: 'Sporingsside',
    delivery: 'Levering',
    phoneNumberShort: 'Tlf nr.',
    yes: 'Ja',
    no: 'Nei',
    notSelected: 'Ikke valgt',
    httpErrorToString: 'HTTP-feil: kode {{status}} {{statusText}}',
    anErrorOccurred: 'En feil har oppstått!',
    noAccessTitle: 'Ingen tilgang',
    noAccessMessage: 'Du har ikke tilgang til å denne funksjonaliten',
    autoComplete: 'Søk etter adresse ...',
    autoCompleteWithoutDots: 'Søk etter adresse',
    totalWeight: 'Totalvekt',
    totalVolume: 'Totalt volum',
    title: {
      root: 'Velkommen',
      planner: 'Planlegger',
      admin: 'Administrasjon',
      plannerLive: 'Planlegger - Live',
      plannerLiveSlot: 'Planlegger - Live - Slot',
      plannerCouriers: 'Planlegger - Sjåfører',
      plannerImport: 'Planlegger - Import',
      plannerUnresolved: 'Planlegger - Uløste',
      plannerConsignments: 'Planlegger - Leveranser',
      plannerSearch: 'Planlegger - Search',
      plannerPlanning: 'Planlegger - planlegging',
      plannerInvoicing: 'Planlegger - fakturering',
      plannerVehicle: 'Planlegger - Vehicles',
      report: 'Rapporter',
      recipient: 'Pakkesporing - sporing av pakker - sporing - Bring',
      orderConfirmation: 'Ordrebekreftelse',
      customerPortal: 'Kundeportal',
      jobs: 'Jobbovervåking',
      holidays: 'Helligdager',
      shipmentLabels: 'Labels',
      addresses: 'Adresser',
      airexpressInvoicing: 'Air Express Fakturering',
      routeReceiptList: 'Kvitteringsliste',
      loadList: 'Lasteliste',
      airexpressAdminTerminals: 'Terminaler',
      manageReturns: 'Administrer returer',
      preAdviceFollowup: 'Administrer forhåndsvarsel'
    },
    language: { en: 'Engelsk', nb: 'Norsk', sv: 'Svensk', dk: 'Dansk', fi: 'Finsk' },
    country: {
      AllCountry: 'Alle land',
      Country: 'Land',
      AT: 'Østerrike',
      BE: 'Belgia',
      BA: 'Bosnia og Herzegovina',
      HR: 'Kroatia',
      CZ: 'Tsjekkia',
      DK: 'Danmark',
      GB: 'Storbritannia',
      EE: 'Estland',
      FO: 'Færøyene',
      FR: 'Frankrike',
      DE: 'Tyskland',
      GR: 'Hellas',
      HU: 'Ungarn',
      IS: 'Island',
      IE: 'Irland',
      IT: 'Italia',
      LT: 'Litauen',
      MK: 'Nord-Makedonia',
      NL: 'Nederland',
      NO: 'Norge',
      PL: 'Polen',
      RU: 'Russland',
      ES: 'Spania',
      SE: 'Sverige',
      CH: 'Sveits',
      UA: 'Ukraina'
    },
    timeDistance: 'Planlagt tid og avstand',
    driverReturnToAddress: 'Ikke spesifiser en returadresse',
    plannedTime: 'Planlagt tid',
    plannedDistance: 'Planlagt avstand',
    plannedWeight: 'Planlagt vekt',
    distance: 'Avstand',
    helpLink: '(Hva er dette?)',
    groups: 'Grupper',
    departments: 'Avdelinger',
    selectAll: 'Velg alle'
  },
  serviceLevel: { notSet: 'Ikke valgt', none: 'Av', label: 'Nedgrader servicenivå' },
  dropdown: {
    selectedCustomers: '{{count}} kunde',
    selectedCustomers_plural: '{{count}} kunder',
    allCustomers: 'Alle kunder',
    filterCustomers: 'Filtrer kunder',
    selectedDepartments: '{{count}} avdeling',
    selectedDepartments_plural: '{{count}} avdelinger',
    allDepartments: 'Alle avdelinger',
    filterDepartments: 'Filtrer avdelinger',
    selectedServiceCodes: '{{count}} tjeneste',
    selectedServiceCodes_plural: '{{count}} tjenester',
    allServiceCodes: 'Alle tjenester',
    filterServiceCodes: 'Filtrer tjenester',
    selectedVasCodes: '{{count}} vas',
    selectedVasCodes_plural: '{{count}} vases',
    allVasCodes: 'Alle vases',
    filterVasCodes: 'Filtrer vases',
    selectedDestinations: '{{count}} destinasjon',
    selectedDestinations_plural: '{{count}} destinasjoner'
  },
  button: {
    edit: 'Rediger',
    back: 'Tilbake',
    cancel: 'Avbryt',
    done: 'Ferdig',
    clear: 'Tøm',
    close: 'Lukk',
    save: 'Lagre',
    delete: 'Slett',
    export: 'Eksport',
    new: 'Lag ny',
    search: 'Søk',
    add: 'Legg til',
    remove: 'Fjern',
    assign: 'Tildel',
    fullEdit: 'Full redigering',
    continue: 'Fortsett',
    confirm: 'Bekreft'
  },
  modals: {
    preplan: {
      create: 'Preplan sending',
      change: 'Bytt preplanned sjåfør',
      shipmentInfo: 'Preplan sending {{shipmentId}} ({{pickupName}} til {{deliveryName}})',
      pickDriver: 'Velg en sjåfør..',
      error: 'Kunne ikke preplan sending',
      notification: {
        preplannedMessage: 'Sendingen var preplanned til {{courierId}}',
        unpreplannedMessage: 'Sendingen var unpreplanned'
      }
    },
    maps: {
      save: 'Lagre',
      cancel: 'Avbryt',
      delete: 'Slett',
      editMode: 'Rediger',
      mapArea: '{{routeName}} kartområde',
      saved: 'Lagret',
      deleted: 'Slettet'
    }
  },
  consignment: {
    includePreAdvice: 'Preavisering',
    onlyFailedPreAdvice: 'Preavisering feilet',
    onlyReturnPreAdvice: 'Retur till avsender',
    consignmentDetails: 'Forsendelsesdetaljer',
    search: 'Søk etter leveranser',
    noShipmentsFound: 'Fant ingen leveranser',
    clickButtonToSearch: 'Klikk på Søk-knappen for å søke',
    consignmentsForDate: 'Leveranser for {{date}}',
    showOnlyEditable: 'Kun redigerbare',
    showOnlyNotAssigned: 'Ikke tildelt',
    withoutDate: 'Uten dato',
    extendedSearch: 'Utvidet søk',
    showDeleted: 'Vis slettede',
    showDeviated: 'Vis avvik',
    deletedBy: 'Sletted av',
    deletionReason: 'Årsak',
    numberOfConsignments: 'Fant {{number}} leveranser',
    showAll: 'Alle',
    edit: 'Endre',
    deleteOrders: 'Slett',
    copyOrder: 'Kopier',
    duplicateOrder: 'Duplisere',
    editOrder: 'Rediger',
    deletedOrderNotCopyable: 'Den slettede ordren kan ikke kopieres grunnet manglende ordredetaljer i Glow',
    restoreOrder: 'Gjenopprett',
    deletedOrderNotRestorable: 'Den slettede ordren kan ikke gjenopprettes grunnet manglende ordredetaljer i Glow',
    deleteConfirmation: 'Er du sikker på at du vil slette denne sendingen ?',
    deleteMultipleConfirmation: 'Er du sikker på at du vil slette valgte sendinger?',
    copyConfirmation: 'Er du sikker på at du vil kopiere denne sendingen ?',
    earliestPickupTime: 'Tidligste hentetidspunkt',
    latestPickupTime: 'Seneste hentetidspunkt',
    editConsignment: ' Rediger leveringsinformasjon',
    changeDateAndTime: 'Sett dato og tider',
    searchShipments: 'Søk i leveranser:',
    searchCustomers: 'Søk i kunder:',
    filterBy: 'Filtrer på:',
    limitedShipmentsShownMessage: 'Viser maksimalt {{count}} ordre, vurder å begrense ditt søk',
    limitedUnresolvedAddressesShownMessage: 'Viser maksimalt {{count}} ufullstendige adresser',
    bulkActions: 'Endre sendinger',
    bulkActionsMultiple: 'Endre sendinger ({{count}})',
    addToRecurringGroup: 'Legg til i recurring group',
    moveShipmentsToDifferentDepartment: 'Flytt til en annen avdeling',
    addEvents: 'Legg til hendelse',
    disabledEditDeleteHelperText:
      'Denne ordren kan ikke redigeres eller slettes, vennligst kontakt ditt lokale Bringkontor for å gjøre endringer på ordren.',
    editPickupConsignment: 'Oppdater henteinformasjon',
    editDeliveryConsignment: 'Oppdater leveringsinformasjon',
    editPickupAndDeliveryTime: 'Oppdater henting og leveringstid',
    editConsignmentLocation: ' Lokasjon',
    editConsignmentTime: ' Tid',
    editConsignmentName: ' Navn',
    editConsignmentPhone: ' Telefonnummer',
    editConsignmentInstructions: ' Instruksjoner',
    earliestDeliveryTime: 'Tidligste leveringstid',
    latestDeliveryTime: 'Seneste leveringstid',
    enterPickupInstructions: 'Angi henteinstruksjoner',
    enterDeliveryInstructions: 'Angi leveringsinstruksjoner',
    enterDeliveryName: 'Angi navn for levering',
    enterPickupName: 'Angi navn for henting',
    enterPickupPhone: 'Angi telefonnummer for henting',
    enterDeliveryPhone: 'Angi telefonnummer for Levering',
    specificationSize: 'Størrelse',
    enterSpecificationSize: 'Legg inn størrelse',
    editSpecificationSize: 'Rediger størrelse',
    enterSpecificationDescription: 'Legg inn detaljer',
    editSpecificationDescription: 'Rediger detaljer',
    editPickupInfoDescription: 'For å kunne redigere må du velge minst ett av valgene',
    updateLocationFor: 'Oppdater informasjon om {{count}} leveranser',
    updateLocationFor_plural: 'Oppdater henteinformasjon for {{count}} leveranser',
    comment: 'Kommentar',
    filter: 'Filtrer leveranser',
    state: 'Status',
    show: 'Vis',
    delivery: 'Leveres',
    pickup: 'Hentes',
    pickups: '{{count}} opphenting',
    pickups_plural: '{{count}} opphentinger',
    estimatedDelivery: 'Estimert levering',
    requestedTimePickup: 'Forventet hentet',
    requestedTimeDelivery: 'Forventet levering',
    customerReference: 'Kundereferanse',
    timeNotSet: 'Ikke satt',
    shipmentId: 'Sendingsnummer',
    packageId: 'Kollinummer',
    noCourier: 'Ingen sjåfør',
    noSlot: 'Ingen slot',
    showEvents: 'Vis hendelser →',
    hideEvents: 'Skjul hendelser ←',
    events: 'Hendelser',
    sendMessage: 'Send melding',
    sendCommentFailed: 'Lagring av kommentar feilet',
    recipient: 'Mottaker',
    unresolved: 'Ufullstendige leveranser',
    description: 'Detaljer',
    pickupAddress: 'Henteadresse',
    numberOfPackages: 'Kolli',
    registeredPickupAddress: 'Registrert henteadresse',
    pickupZipCode: 'Postnummer',
    pickupZipArea: 'Område',
    pickupLocation: 'Lokasjon',
    deliveryAddress: 'Leveringsaddresse',
    deliveryZipCode: 'Postnummer',
    deliveryZipArea: 'Område',
    deliveryLocation: 'Lokasjon',
    registeredDeliveryAddress: 'Registrert leveringsadresse',
    updated: 'Forsendelsen ble oppdatert',
    moved: 'Sendingen har blitt flyttet',
    unresolvedMessage:
      'Det finnes {{count}} forsendelse der vi ikke finner posisjon i kartet. Vennligst oppdater disse..',
    unresolvedMessage_plural:
      'Det finnes {{count}} forsendelser der vi ikke finner adressen. Vennligst legg inn adressene.',
    foundUnresolvedMessage:
      'Vi har funnet følgende gyldige adresser for forsendelsen, om de er korrekte trykk lagre. Eventuelt søk opp korrekt adresse i kartet nedenfor.',
    remove: 'Fjern',
    alterSlot: 'Endre slot (ikke optimaliser)',
    alterSlotAndReoptimize: 'Endre slot (optimaliser ny slot med samme rekkefølge)',
    alterSlotNotAvailable: 'Ikke tilgjengelig så lenge optimisering pågår.',
    optimizingNewSlot: 'Optimaliserer levering til endret slot',
    selectSlot: 'Velg ny slot for denne leveransen',
    slotSelect: 'Velg slot',
    confirmSlotChange: 'Bekreft endring av slot',
    pickupInstructions: 'Henteinstrukser',
    deliveryInstructions: 'Leveringsinstrukser',
    specificationDescription: 'Beskrivelse',
    tracking: 'Sporingslenke',
    recipientPhone: 'Mottakers telefonnummer',
    customerRef: 'Kundereferanse',
    service: 'Tjeneste',
    message: {
      CREATED: 'Opprettet',
      ASSIGNED: 'Tildelt slot',
      UNASSIGNED: 'Ikke tildelt slot',
      COLLECTED: 'Plukket opp',
      DELIVERED: 'Levert',
      DEVIATED: 'Avvik',
      COMMENT: 'Kommentar',
      RESOLVED: 'Løst',
      UNRESOLVED: 'Uløst',
      OFFERED: 'Tilbudt',
      REJECTED: 'Avvist',
      ACCEPTED: 'Akseptert',
      RETURNED: 'Returnert',
      DELETED: 'Slettet'
    },
    manualOverrideTitle: 'Manuell overstyring',
    adjustTabTitle: 'Redigere',
    mapPanelTitle: 'Kart',
    recipientName: 'Mottakers navn',
    receivedBy: 'Mottatt av:',
    manuallyChangeLocations: 'Manuelt endre lokasjoner',
    manualOverrideDeviationTimeConfirm: 'Bekreft manuell avvikelse for valgt sending',
    manualOverrideDeliveryTimeConfirm: 'Bekreft manuell levering for valgt sending',
    onManuallyChangeLocationsConfirm: 'Er du sikker på at du vil gjøre sendingen uløst?',
    unresolveDeliveryAddress: 'Gjør leveringsadresse ufullstendig',
    unresolvePickupAddress: 'Gjør opphentingsadresse ufullstendig',
    reference: 'Referanse',
    shipmentIdNumber: 'Nr',
    shipmentDetails: 'Leveransedetaljer',
    customer: 'Kunde',
    subcustomer: 'Kundenummer',
    dateAndTime: 'Dato og tid',
    filterCustomer: 'Filtrer kunde',
    searchShipmentDateError: 'Angi datoperiode under 120 dager.',
    searchPhraseLength: 'Søkeord bør være minst {{minLength}} tegn for utvidet søk.',
    moveOrder: 'Flytt leveranse',
    moveOrderTitle: 'Flytt leveransen til en annen avdeling',
    moveOrderDefault: 'Velg avdeling',
    label: 'Etikett',
    createLabel: 'Lag etikett',
    createLabelShipment: 'Lag etikett (Sending)',
    createMultipleLabel: 'Lag etikett ({{count}})',
    viewLabel: 'Se etikett',
    viewLabels: 'Se etiketter',
    generateLabelErrorDescription: 'Beklager, kan ikke generere etiketter nå',
    generatingLabels: 'Etikett PDF URL-er vil snart komme til syne under',
    labelsGeneratedSuccessfully: 'Alle etikett PDF-er ble generert vellykket',
    vas: 'Tillegstjenester (VAS)',
    price: 'Pris',
    orderSources: { glow_customerBooking: 'GLOW kundebooking', glow_recurring: 'Gjentagende' },
    shipmentSyncStatus: { no: 'Nei', yes: 'Ja', partial: 'Delvis' },
    unresolvedSuccessfully: 'Sendingen er nå uløst.',
    wrongCoordinates: 'Feil koordinater?',
    moveShipmentToUnresolved: 'Flytt sendingen til "Uløste" for å rette på koordinatene.',
    mapUnavailable: 'Kartet vises når sendingen er løst.',
    selectOtherActions: 'Velg andre handlinger'
  },
  numberSeries: { numberSeries: 'Nummerserier' },
  preadvice: {
    searchPlaceholder: 'Skriv minst 3 tegn for å filtrere',
    selectedCustomers: 'Valgte kunder ({{count}})',
    customerSearch: 'Søkefelt - Skriv minst 3 tegn for å søke etter kunder',
    settingTargetCustomers:
      'Denne preaviseringen retter seg mot en spesifikk kunde. Fjern alle valgte kunder for å kunne bytte innstilling til kundgrupper',
    nameDescription: 'Navn og beskrivelse',
    createNewPreadvice: 'Opprett ny preaviseringsregel',
    bizArea: 'Velg forretningsområde',
    filterDepartmentAndGroups: 'Filtrer på avdelinger eller avdelingsgrupper',
    filterCustomersAndGroups: 'Filtrer på kunder eller kundgrupper',
    preadvice: 'Avisering',
    hdDepartments: 'HD-avdelinger',
    ceDepartments: 'Budavdelinger',
    departmentGroups: 'Avdelingsgrupper',
    customers: 'Kunder',
    customerGroups: 'Kundegrupper',
    customersCount: 'Antall kunder',
    customerGroupsCount: 'Antall kundegrupper',
    departmentsCount: 'Antall avdelinger',
    departmentGroupsCount: 'Antall avdelingsgrupper',
    locked: 'Avisering låst',
    updated: 'Avisering oppdatert',
    rule: 'Regel',
    ruleTrigger: 'Preadvice på',
    result: {
      NO_RULE: 'Ingen regel matchet',
      NO_DELIVERY_TIME_WINDOW: 'Fant ingen tidsvinduer',
      SMS_SENT: 'Preadvice-regel sjekket. SMS lagt i kø',
      EMAIL_SENT: 'Preadvice-regel sjekket. Epost lagt i kø',
      SMS_AND_EMAIL_SENT: 'Preadvice-regel sjekket. SMS og Epost lagt i kø',
      NO_VALID_PHONE_OR_EMAIL: 'Verken gyldig telefonnummer eller epostadresse',
      FEATURE_OFF: 'Preadvice er skrudd av',
      ALREADY_PRE_ADVICED: 'Leveringstid allerede satt. Ingen handling utført',
      CANCELLED: 'Preadvice avbrutt',
      WAITING_FOR_PACKAGES: 'Venter på pakker',
      ALREADY_IN_PROGRESS: 'Venter på at mottaker skal booke tid',
      ERROR: 'Det skjedde en feil',
      WRONG_TRIGGER: 'Trigger matcher ikke regel. Ignorerer',
      MANUAL_ATTEMPT: 'Preadvice-forsøk inkrementert manuelt',
      PRE_ADVICE_LIMIT_REACHED: 'Grense for antall preadvice nådd',
      PRE_ADVICE_LOCKED: 'Preadvice er låst',
      ORDER_DELIVERED: 'Ordren er allerede levert',
      RETURN_LAST_LEG_CREATION_SKIPPED: 'Returben ble ikke opprettet'
    },
    error: {
      PRE_ADVICE_RULE_COLLISIONS: {
        label: 'Regelkonflikt!',
        detail:
          'Regel for {{service}} vil overstyre regelsett {{policySetNames}} gjennom knytning der til {{relations}}.',
        department: 'avdeling',
        department_group: 'avdelingsgruppe',
        customer: 'kunde',
        customer_group: 'kundegruppe'
      }
    },
    noPoliciesFound: 'Ingen regelsett',
    policies: 'Regelsett for automatisk avisering',
    policy: {
      servicePoliciesDescription: 'Avhengig av hvilken type varsling du velger, vil ulike inndatafelt vises',
      exception: 'Unntak',
      deleteConfirm: 'Er du sikker på at du vil slette dette regelsettet?',
      name: 'Navn',
      new: 'Nytt aviseringsrelegelsett',
      edit: 'Endre aviseringsrelegelsett',
      description: 'Beskrivelse',
      save: 'Lagre',
      saveAnyway: 'Lagre likevel',
      servicePolicies: 'Aviseringsregler',
      targets: 'Kunde-/avdelingsknytninger',
      servicePolicy: {
        edit: 'Endre regelsett',
        new: 'Legg til regel for tjeneste',
        remove: 'Fjern valgte',
        serviceCode: 'Tjeneste',
        serviceCodeLegend: 'Legg til tjenestekode, f.eks 3123',
        vasCodeLegend: 'Legg til tilleggstjeneste f.eks SWAP',
        sameDayLegend: 'Huk av av hvis du vil at tjenesten skal utføres som Same Day Service',
        triggerEvent: 'Avisering på',
        triggerEventLegend:
          'Hva som skal forsårsåke at preavisering sendes. At vi mottar EDI til Glow, når sendingen ankommer HUB eller kommer til DIP, eller hvis den ikke skal trigges velger du MANUAL',
        cutoffLegend:
          'Cutoff-tidspunkt. Etter dette tidspunktet tilbys ikke lenger første ledige tidsvindu til mottaker, men dagen etter.',
        relativeCutoffMinutes: 'Cutoffminutter',
        relativeCutoffMinutesLegend:
          'Minimum antall minutter fra aviseringstidspunktet til det neste tilgjengelige tidsvinduet. Overstyrer cutofftid.',
        calendarDays: 'Kalenderdager',
        calendarDaysLegend: 'Hvor mange dager skal vises til mottaker i kalenderen.',
        noServicePoliciesFound: 'Ingen aviseringsregler',
        returnToSenderAfterDays: 'Retur til avsender',
        returnToSenderAfterDaysLegend:
          'Etter hvor mange dager skal sendingen returneres til avsender i tilfelle vi ikke har oppnådd kontakt med mottaker.',
        schemeLegend: 'Type preavisering som skal sendes; Push, Pull, Return eller Pick up parcel.',
        searchCustomer: 'Søk kunde',
        remindAfterHours: 'Påminnelse til mottaker',
        remindAfterHoursLegend:
          'Relevant for Push og Pull: etter hvor mange timer skal det sendes påminnelse til mottaker om å avtale leveringstid.',
        preAdviceAttempts: 'Aviseringsforsøk',
        preAdviceAttemptsLegend: 'Hvor mange påminnelser som skal sendes før sendingen returneres.',
        reminderToSenderAfterHours: 'Påminnelse til avsender',
        reminderToSenderAfterHoursLegend:
          'Etter hvor mange timer skal det sendes påminnelse til avsender om at det ikke er er avtalt levering.',
        deliveryAttempts: 'Leveringsforsøk',
        deliveryAttemptsLegend: 'Hvor mange leveringsforsøk før sendingen returneres til avsender.',
        label: { days: 'Dager', hours: 'Timer', smss: 'stk' },
        legend: { days: 'Dager', hours: 'Timer', smss: 'Maks antall SMS' },
        returnToPup: 'Retur til pickup point'
      }
    }
  },
  holidays: { holidays: 'Helligdager', addHoliday: 'Legg til ny helligdag', country: 'Land', date: 'Dato' },
  department: {
    searchDepartment: 'Filtrer etter avdelingsnavn eller Alystra-ID',
    searchDepartmentGroups: 'Søk etter avdelingsnavn eller beskrivelse',
    createNewDepartment: 'Opprett ny avdeling',
    addressAndContact: 'Adresse og kontaktinformasjon',
    searchAddressError: 'Manglende verdier for adresse. Du må bruke søkefeltet for å få riktig adresse.',
    addressContactIngress:
      'For å få riktig adresse, bruk søkefeltet. Adresse, postnummer og by vil automatisk bli fylt ut.',
    settingsInGlow: 'Innstillinger på mottakerens sporingsside',
    settingsBodyText: 'Avhengig av ditt valg vil forskjellig innhold og kommunikasjon bli vist/send til mottakerne.',
    emailAddress: 'E-postadresse',
    routePlanning: 'Ruteplanlegging',
    autoRouteCreationTime: 'Automatisk ruteoppretting',
    autoRouteOptimizationTime: 'Automatisk ruteoptimalisering',
    clearTime: 'Fjern tiden',
    manageDepartment: 'Administrer avdeling',
    listAllGroups: 'Liste alle avdelingsgrupper',
    departmentTitle: 'Avdeling',
    department: 'avdeling',
    department_plural: 'avdelinger',
    departmentWithCount: '{{count}} avdeling',
    departmentWithCount_plural: '{{count}} avdelinger',
    departmentGroups: 'Avdelingsgrupper',
    numberOfDepartmentGroups: 'Avdelingsgrupper',
    numberOfDepartments: 'Antall avdelinger',
    search: 'Søk etter avdelinger',
    noDepartmentName: 'Navn mangler',
    noDepartmentId: 'Id mangler',
    noCouriers: 'Ingen sjåfører',
    courierCount_plural: '{{count}} sjåfører',
    courierCount: '{{count}} sjåfør',
    noCourier: 'Ingen sjåfører',
    couriers: 'Antall sjøfører',
    plannerCount_plural: '{{count}} planleggere',
    plannerCount: '{{count}} planlegger',
    noPlanner: 'Ingen planleggere',
    planners: 'Antall planleggere',
    choose: 'Velg avdeling',
    new: 'Ny avdeling',
    description: 'Beskrivelse',
    name: 'Navn',
    created: 'Avdeling opprettet',
    updated: 'Avdeling oppdatert',
    departmentType: 'Avdelingstype',
    notSet: 'Ikke valgt',
    courier: 'Bud',
    express: 'Ekspress',
    hd: 'Hjemlevering',
    other: 'Annet',
    all: 'Alle',
    terminalName: 'Terminalnavn',
    terminalNameTooltip: 'Fylles ut dersom avdelingsnavn ikke er det samme som navn på hentested',
    selectArea: 'Velg område...',
    address: 'Adresse',
    zipCode: 'Postnummer',
    zipArea: 'Poststed',
    orderBillingType: {
      title: 'Ordretype for fakturering',
      selectBillingType: 'Velg ordretype',
      customerBillingNumber: 'Kundens faktureringsnummer',
      addMoreOrderBillingInfo: 'Legg til ny ordretype'
    },
    departmentId: 'Avdelings Id',
    disableSms: 'Deaktiver sporings- og ETA-SMS på rute',
    smsShouldNotBeSent: 'Sporings- og ETA-SMS skal ikke sendes til mottakere på denne avdelingens ruter.',
    autoRecurrentTime: 'Automatisk gjentagende tid',
    autoInvoiceTitle: 'Automatisk fakturering',
    autoInvoiceEnable: 'Sett automatisk fakturering',
    autoInvoiceTime: 'Tidspunkt',
    autoInvoiceOffset: {
      legend: 'Ordre som skal skal faktureres',
      oneDayEarlier: 'Levert 1 dag siden',
      twoDaysEarlier: 'Levert 2 dager siden',
      threeDaysEarlier: 'Levert 3 dager siden',
      fourDaysEarlier: 'Levert 4 dager siden',
      fiveDaysEarlier: 'Levert 5 dager siden'
    }
  },
  departmentGroup: {
    countries: 'Land',
    departmentTypes: 'Avdelingstyper',
    manageDepartments: 'Administrer avdelinger i gruppe',
    createDepartmentGroup: 'Opprett ny avdelingsgruppe',
    noSelectedDepartments: 'Ingen valgte avdelinger',
    selectedDepartments: 'Valgte avdelinger ({{count}})',
    selectAllFor: 'Velg alle for {{name}}',
    departmentGroup: 'Avdelingsgruppe',
    name: 'Navn',
    deleteConfirm: 'Er du sikker på at du vil slette avdelingsgruppen?',
    edit: 'Rediger avdelingsgruppe'
  },
  menu: {
    defaultDisabled: 'Velg en kunde for å aktivere dette menypunktet',
    home: 'Hjem',
    admin: 'Administrasjon',
    planner: 'Planlegger',
    report: 'Rapporter',
    customer: 'Kunde',
    apiOrder: 'Finn API-ordre',
    priceMotor: 'Prismotor',
    departments: 'Avdelinger',
    users: 'Brukere',
    customers: 'Kunder',
    customerGroups: 'Kundegrupper',
    numberSeries: 'Nummerserier',
    jobsMonitoring: 'Jobbovervåking',
    holidays: 'Helligdager',
    settings: 'Innstillinger',
    preadvice: 'Avisering',
    userRoles: 'Brukerroller',
    integrations: 'Integrasjoner',
    routePlanning: 'Ruteplanlegging',
    setCoordinates: 'Angi koordinater',
    shipments: 'Leveranser',
    corrections: 'Korrigeringer',
    units: 'Enheter',
    couriers: 'Sjåfører',
    vehicles: 'Vehicles',
    importExport: 'Import / Eksport',
    addresses: 'Adresser',
    invoicing: 'Fakturering',
    manageReturns: 'Administrer returer',
    preAdviceFollowup: 'Administrer forhåndsvarsel',
    optimizing: 'Optimalisering',
    services: 'Tjenester',
    matrix: 'Matrise',
    vendors: 'Leverandører',
    backToOldShipmentsPage: 'Tilbake til den gamle siden.',
    mainNavigation: 'Hovednavigasjon',
    closePanel: 'Lukk panel',
    expandPanel: 'Utvid panel',
    productionTimeWindow: 'Produksjonstidsvindu'
  },
  eventsList: { timeWindow: 'Tidsvindu', from: 'Fra', to: 'Til' },
  consignmentEvent: {
    message: {
      resolved: 'Adressekoordinater satt',
      comment: 'Kommentar',
      created: 'Registrert',
      completed: 'Fullført',
      assigned: 'Tildelt sjåfør',
      plannedOnRoute: 'Planlagt på rute',
      plannedOnRouteMoreInfo: 'Planlagt på rute {{routeName}} ({{unitName}})',
      delivered: 'Levert',
      collected: 'Hentet',
      returned: 'Returnert',
      deviated: 'Avvik',
      damaged: 'Skadet',
      notification: 'Melding',
      slotChanged: 'Slot endret',
      unassigned: 'Ikke tildelt sjåfør',
      rejected: 'Avvist',
      image: 'Bilde',
      error: 'Feil',
      started: 'Startet',
      stopped: 'Stoppet',
      accepted: 'Akseptert',
      offered: 'Tilbudt',
      unitName: 'Enhet',
      driverName: 'Sjåfør',
      'delivery-id-check': 'ID-sjekk',
      'delivered-manually-overridden': 'Levert manuelt overstyrt',
      'deviated-manually-overridden': 'Avvik manuelt overstyrt',
      'collected-manually-overridden': 'Opphenting manuelt endret',
      'pickup-location-changed': 'Henteadresse endret',
      'delivery-time-estimated': 'Oppdatert leveringstid',
      'slot-manually-changed': 'Slot manuelt overstyrt',
      'pickup-time-changed': 'Opphentingstid endret',
      'manually-change-location': 'Manuell overstyring av lokasjoner',
      'pickup-consignment-data-changed': 'Henteinformasjon har blitt oppdatert',
      'delivery-consignment-data-changed': 'Leveringsinformasjon har blitt oppdatert',
      'specification-size-data-changed': 'Størrelse oppdatert',
      'specification-description-data-changed': 'Detaljer oppdatert',
      'delivery-time-purchased': 'Mersalg: Tilpasset tidsvindu bestilt av mottaker',
      'digital-identity-verified': 'Mottakerens identitet bekreftet digitalt',
      'digital-identity-verification-failed': 'Kunne ikke verifisere mottakerens identitet digitalt',
      'flex-delivery-ordered': 'Forenklet leveranse bestilt av mottaker',
      'flex-delivery-ordered-by-sender': 'Forenklet leveranse bestilt av avsender',
      'flex-delivery-changed': 'Forenklet leveranse endret',
      'flex-delivery-confirmation-sms-sent': 'Forenklet leveransebekreftelse SMS sendt',
      'return-to-pickup-point-delivery-deviated': 'Vil overleveres på utleveringssted',
      recipientName: 'Mottakernavn',
      receivedBy: 'Mottatt av',
      'reject-order-correction': 'Avvist ordre-korreksjon',
      'accept-order-correction': 'Akseptert ordre-korreksjon',
      'handed-over-to-other-unit': 'Overlevert til annen enhet',
      deliveredWithoutSignature: 'Levert uten signatur',
      deliveredWithoutSignatureReason: {
        left_package_outside: 'Plassert som instruert',
        did_not_get_signature: 'Personlig'
      },
      'returned-manually-overridden': 'Manuell endring av retur',
      'eta-sms-sent': 'ETA SMS sendt',
      calculatedEta: 'Beregnet ankomst: {{eta}}',
      etaSmsLabel: 'Beregnet ankomst',
      scanned: 'Ankommet terminal',
      'arrived-at-terminal': 'Ankommet distribusjonsterminal',
      'order-sorted': 'Sortert',
      'not-arrived-at-distributing-terminal': 'Ikke ankommet distribusjonsterminal',
      'pickup-eta-sms-sent': 'Pickup ETA SMS sendt',
      'handed-in-at-posten-terminal': 'Ankommet sorteringsterminal',
      'pickup-sms-sent': 'Sporings-SMS sendt',
      'pre-pickup-sms-sent': 'Sporings-SMS forhåndssendt',
      'pickup-address-unresolved': 'Opphentingadresse ble gjort ufullstendig',
      'delivery-address-unresolved': 'Leveringsadresse ble gjort ufullstendig',
      'in-transit': 'I transitt',
      'upcoming-pickup-sms-sent': 'Nærstående leveranse SMS sendt',
      'order-sorted-same-day': 'Dato satt til i dag via terminal app',
      'signature-sms-sent': 'Signatur-SMS sendt',
      'flex-delivery-cancelled': 'Simplified delivery avbestilt',
      'delivery-time-ordered': 'Leveringsdato endret av mottaker',
      'delivery-time-ordered-internal': 'Leveringsdato endret',
      'delivery-time-updated-from-hf-sync': 'Leveringstid synkronisert fra HF',
      'planned-delivery-date-ordered': 'Planlagt leveringsdato bestilt',
      'delay-sms-sent': 'SMS om forsinkelse sendt',
      'manual-delay-sms-sent': 'Manuell forsinkelse SMS sendt',
      'route-planning-complete': 'Rute ferdig',
      'signature-delivered': 'Signatur fra mottaker',
      showPicture: 'Vis bilde',
      'external-address-wash': 'Leveringsinformasjon har blitt oppdatert',
      'order-note-changed': 'Notat endret',
      'order-data-changed': 'Ordre endret',
      'order-moved-to-department': 'Flyttet mellom avdelinger',
      'order-moved-to-department-with-data': 'Sendingen er flyttet fra',
      'package-measurements-changed': 'Pakkestørrelse endret',
      'driver-deviated-from-route': 'Sjåføren avvek fra ruta',
      'delivered-outside-service-time-window': 'Levering eller avvik utenfor tjenesten sitt tidsvindu',
      'delivered-outside-estimated-time-window': 'Levering eller avvik utenfor det estimerte tidsvinduet',
      'seen-by-recipient': 'Sporingsside sett av mottaker',
      'first-estimated-times-seen': 'Første estimerte tid sett av mottaker',
      'pre-advice-push-sms-sent': 'Preadvice PUSH-SMS sendt',
      'pre-advice-push-return-sms-sent': 'Preadvice PUSH-RETURN-SMS sendt',
      'pre-advice-pull-sms-sent': 'Preadvice PULL-SMS sendt',
      'pre-advice-pull-reminder-sms-sent': 'Preadvice PULL påminnelse til mottaker sendt',
      'pre-advice-pup-sms-sent': 'Preadvice PUP-SMS sendt',
      'pre-advice-return-sms-sent': 'Preadvice Return-SMS sendt',
      'pre-advice-return-email-sent': 'Preadvice Return-email sendt',
      'delivery-location-deviation': 'Levering eller avvik ikke på forventet sted',
      'pre-advice-pull-reminder-to-sender-email-sent': 'Preadvice PULL påminnelse til avsender sendt',
      'pre-advice-pup-reminder-to-sender-email-sent': 'Preadvice PUP påminnelse til avsender sendt',
      'pre-advice-return-return-to-sender-email-sent': 'Preadvice RETURN retur til avsender sendt',
      'pre-advice-pull-return-to-sender-email-sent': 'Preadvice PULL retur til avsender epost sendt',
      'pre-advice-push-return-email-sent': 'Preadvice PUSH retur til avsender epost sendt',
      'pre-advice-manual-attempt': 'Manuelt preadvice-forsøk',
      'pre-advice-manual-request': 'Manuell preadvice startet',
      'pre-advice-status-changed': 'Aviseringstatus endret',
      'pre-advice-active-return-to-customer-email-sent': 'Preadvice aktiv retur til kunde, epost sendt',
      'pre-advice-active-return-not-picked-up-email-sent': 'Preadvice aktiv retur ble ikke hentet, epost sendt',
      'pre-advice-passive-return-to-customer-email-sent': 'Preadvice passiv retur til kunde, epost sendt',
      'notification-to-sender-failed': 'Epostvarsel til kunden feilet',
      'reported-damaged': 'Meldt skade',
      'reported-missing-article': 'Rapportert manglende produkt',
      'delivery-sms-sent': 'Leverings SMS sendt',
      'parcelbox-allocated': 'Reservert plass i pakkeboks',
      'apartment-information-updated': 'Leilighetsinformasjon oppdatert',
      'claim-registered-email-sent': 'E-post om skadede- eller manglende varer sendt',
      'invoicing-failed': 'Fakturering feilet',
      'invoiced-ok': 'Fakturering OK',
      'invoice-awaiting-acknowledgement': 'Avventer fakturabekreftelse',
      'parcel-locker-tracking-sms-sent': 'Sporings-SMS for pakkeboks-leveranse sendt',
      'service-upgrade-purchased': 'Mersalg: Innbæring bestilt av mottake',
      serviceUpgradePurchasedDescription: 'Innbæring bestilt av mottaker',
      'preliminary-loaded': 'Foreløpig lastet',
      'manual-price-override': 'Pris overstyrt',
      'incomplete-price-approved': 'Ufullstendig pris godkjent',
      'incomplete-price-unapproved': 'Godkjenning av ufullstendig pris trukket tilbake',
      unloaded: 'Avlastet',
      'manually-changed-measurements': 'Manuelt endret vekt og volum',
      'internal-trade-order-created': 'Ordre for internfakturering opprettet',
      'load-carrier-ready-for-distribution': ' Lastbærer klar for distribusjon'
    },
    rejectedReason: {
      reason: 'Årsak',
      private: 'Private årsaker',
      notEnoughTime: 'Rekker ikke stoppet i tide',
      notEnoughSpaceOnVehicle: 'Forsendelse får ikke plass på bil',
      other: 'Andre årsaker'
    },
    idCheck: {
      idCheckFailed: 'ID-sjekk feilet, men ble levert med {{idCheckFailedResult}}',
      idCheckSummaryOther: 'Annet ({{otherType}}), fødselsdato {{birthDate}}',
      idCheckSummary: '{{idCheckType}}, fødselsdato {{birthDate}}'
    },
    damaged: {
      damageType: 'Type skade',
      causeCode: 'Årsakskode',
      courierInput: 'Melding fra sjåfør',
      showPictureLink: 'Vis bilde {{idx}}',
      type: { A: 'Skade på eske/emballasje', D: 'Trykk/klemskade', E: 'Totalskadet' }
    },
    invoiceInitiationType: {
      automatic: 'Planlagt fakturering',
      'manual-department-level': 'Manuell avdelingsfakturering',
      'manual-order-level': 'Manuell ordrefakturering',
      'multiple-order-level': 'Manuell fakturering av flere ordre'
    },
    changedFrom: 'Endret fra -',
    name: 'Navn',
    deliveryOrderedByReceiver: 'Levering til pakkeboks valgt av mottaker',
    resolved: {
      source: {
        DepartmentLocation: 'koordinater på avdeling',
        GeocodingAPI: 'Google',
        DatabaseLookup: 'lagrede koordinater',
        PickupPointAPI: 'Pickup Point API',
        GlowFrontend: 'Glow',
        EDI: 'ukjent',
        CustomerSite: 'kundens lokasjon',
        ParcelInMailbox: 'postkassens koordinater',
        Driver: 'sjåfør',
        OriginalOrder: 'Opprinnelig ordre',
        Google: 'Google',
        GoodsReturnAddress: 'returadresse for varer'
      },
      pickup: {
        coordinatesSetBy: 'Hentekoordinater satt av {{source}}',
        coordinatesManuallySet: 'Hentekoordinater har blitt satt manuelt',
        coordinatesRestored: 'Hentekoordinater gjenopprettet',
        coordinatesSwapped: 'Hentekoordinater satt til tidligere leveringskoordinater'
      },
      delivery: {
        coordinatesSetBy: 'Leveringskoordinater satt av {{source}}',
        coordinatesManuallySet: 'Leveringskoordinater har blitt satt manuelt',
        coordinatesRestored: 'Leveringskoordinater gjenopprettet',
        coordinatesSwapped: 'Leveringskoordinater satt til tidligere hentekoordinater'
      }
    },
    created: {
      recurringOrder: 'Opprettet fra gjentakende ordre.',
      showOriginalOrder: 'Vis originalen'
    }
  },
  planner: {
    canLogIn: 'Kan logge inn',
    chooseYourDepartment: 'Velg din avdeling',
    unassignedShipments: 'Uanviste forsendelser',
    filterCustomers: 'Filtrer kunder',
    filterServices: 'Filtrer tjenester',
    autoRoute: 'Automatisk rute',
    saved: 'Lagret',
    allUserRoles: 'Alle brukerroller',
    userRolesCount_plural: '{{count}} brukerroller',
    createNewUser: 'Opprett ny bruker',
    userRolesCount: '{{count}} brukerrolle',
    role: 'Rolle',
    searchForNameOrNumber: 'Søk etter navn eller telefonnummer',
    noPlannersFound: 'Ingen planleggere funnet',
    typeOfRole: 'Type brukerrolle',
    selectUserRole: 'Velg brukerrolle',
    users: 'Brukere',
    connectDepartment: 'Koble avdeling til bruker',
    banner: {
      tryNewRoutePlanner:
        'Fra 1. mai vil <0>ruteplanlegger 2.0</0> være den eneste tilgjengelige ruteplanleggeren. Hvis du ikke har brukt ruteplanleggeren før, oppfordrer vi deg til å begynne å bruke den fra nå av. <1>Les mer på Zendesk</1> og andre relaterte artikler for å få en oversikt over hvordan den fungerer.'
    },
    totalTitle: 'Kolli',
    pickList: 'Plukkliste',
    export: 'Eksporter for fakturering',
    deleteSlot: 'Slett',
    editSlot: 'Rediger',
    deleteSlotConfirmDialogText: 'Er du sikker du vil slette denne slotten?',
    deleteFilteredSlots: 'Slett alle ruter',
    deleteAllSlotsConfirmDialogText: 'Er du sikker på at du vil slette alle rutene?',
    created: 'Planlegger opprettet',
    updated: 'Planlegger oppdatert',
    deleted: 'Planlegger har blitt slettet',
    removeDepartment: 'Fjern avdeling {{name}}',
    connectAirlineTerminal: 'Koble flyplassterminal(er) til bruker',
    connectedAirlineTerminals: 'Tilkoblede flyplassterminaler ({{count}})',
    noConnectedTerminals: 'Ingen tilkoblede terminaler ennå',
    removeTerminal: 'Fjern terminal {{name}}',
    searchTerminals: 'Søk etter flyplassterminaler',
    youMustSelectTerminal: 'Du må velge flyplassterminal',
    confirmDelete: 'Er du sikker på at du vil slette denne brukeren?',
    youMustProvideName: 'Du må oppgi brukerens navn',
    youMustProvidePhonenumber: 'Du må oppgi telefonnummer',
    routePlanning: 'Ruteplanlegging',
    routePlanningConsignmentsAssigned: 'tildelt',
    routePlanningRangeDescription: 'Filtrer pakker basert på leveringstidspunkt',
    unresolvedConsignments: 'Ufullstendige',
    consignments: 'Leveranser',
    couriers: 'Sjåfører',
    units: 'Enheter',
    invoicing: 'Fakturering',
    planner: 'Planlegger',
    planners: 'Planleggere',
    noPlanners: 'Ingen planleggere',
    missingId: 'Id mangler',
    missingName: 'Navn mangler',
    search: 'Søk',
    searchForPlanner: 'Søk etter planleggere',
    name: 'Navn',
    phoneNumber: 'Telefonnummer',
    selectDepartments: 'Velg avdeling(er)',
    createSlot: 'Opprett slot',
    editSlotForm: 'Rediger slot',
    routeUpdated: 'Ruten har blitt oppdatert',
    createSlotFormName: 'Navn',
    createSlotFormUnit: 'Enhet',
    createSlotFormCourier: 'Sjåfør',
    createSlotFormSlotMode: 'Modus',
    createSlotFormSlotModePickup: 'Tegn hentingsområde i kart',
    createSlotFormSlotModeDelivery: 'Tegn leveringsområde i kart',
    createSlotFormSlotModeManual: 'Velg pakker manuelt',
    createSlotFormSlotModeSelected: 'Velg pakker',
    createSlotFormTimeRange: 'Tid',
    createSlotFormStartLocation: 'Start-lokasjon',
    createSlotFormEndTime: 'Sluttid',
    createSlotFormEndLocation: 'Slutt-lokasjon',
    createSlotFormSlotColor: 'Farge',
    createSlotFormTimeRangeTooBig: 'For stort tidsspenn. Maks er {{hours}} timer.',
    createSlotFormFilterCustomers: 'Filtrer kunder',
    createSlotFormFilterServices: 'Filtrer tjenester (ingen valgt betyr alle)',
    slotFilterInfo:
      'Denne ruten er filtrert, dvs at kun ordre som tilhører valgte kunde(r) og valgte tjeneste(r) vil kunne knyttes til ruten.',
    slotFilteredCustomer: 'Ruten inkluderer KUN ordre fra følgende kunder',
    slotFilteredService: 'Ruten inkluderer KUN ordre med følgende tjenester',
    onlyShowApplicable: 'Vis kun tildelte og gyldige for ruten basert på kunde-/tjenestefiltrering',
    optimizeButtonLabel: 'Optimaliser og tildel ruter',
    optimizeSlotButtonLabel: 'Re-optimaliser slot',
    reversedOptimizeSlotButtonLabel: 'Reverser leveringsrekkefølgen',
    optimizingSince: 'Optimalisering pågår, startet kl. {{startTime}}',
    alterSlotIdOnConsignment: 'Er du helt sikker på at du vil endre rute for denne leveransen?',
    addToRoute: 'Legg til rute...',
    pickup: 'Opphenting',
    delivery: 'Levering',
    notAssigned: 'Ikke tildelt',
    readOnly: 'Kun lesetilgang',
    plus: 'Ekstra rettigheter',
    readOnlyAccessPlannersWithCount: '{{count}} trafikkplanleggere med lese-tilgang',
    plusPlannersWithCount: '{{count}} trafikkplanleggere med ekstra rettigheter',
    normalPlannersWithCount: '{{count}} trafikkplanleggere med normale rettigheter',
    saveTemplate: 'Lagre som mal',
    loadTemplate: 'Hent fra mal',
    routeListForFilteredSlots: 'Kvitteringsliste (print alle)',
    loadListForFilteredSlots: 'Lasteliste (print alle)',
    sendPrePickupSmsesToAll: 'Forhåndssend sporings-sms',
    alertBeforeSendingPrePickupSms: 'Er du sikker på at du vil forhåndssende sporings-SMS for alle ruter for i dag?',
    createTemplateFormName: 'Navn på malen',
    createOrUpdateTemplateDescription:
      'For å kunne lagre må du ENTEN skrive ett unikt navn på en ny mal eller velge å oppdatere en eksisterende mal nedenfor',
    newTemplate: 'Lag en ny mal',
    overwriteTemplate: 'Oppdater mal',
    templateSaved: 'Malen ble lagret',
    updateTemplateDescription: 'Velg eksisterende mal for å oppdatere: ',
    updateTemplateConfirm: 'Er du sikker på at du vil oppdatere malen?',
    selectOrDeleteTemplateDescription: 'Her kan du velge å bruke en mal, eller velge en mal og deretter slette den',
    selectTemplate: 'Velg mal',
    deleteTemplate: 'Slett',
    selectTemplateDescription: 'Velg mal: ',
    templateSelected: 'Mal har blitt valgt',
    templateDeleted: 'Malen er nå slettet',
    templateDeleteConfirm: 'Er du sikker på at du vil slette malen?',
    missingDeliveryMarker: 'Ikke en gyldig leveringsadresse eller lokasjon',
    missingPickupMarker: 'Ikke en gyldig henteadresse eller lokasjon',
    unassignConsignmentFromSlot: 'Fjern leveransen fra tildelt slot',
    unassignBulkConsignmentsFromSlotsAlert: 'Ønsker du å fjerne sendingene fra sine respektive ruter?',
    unassignedFromSlot: 'Leveransen har nå blitt fjernet fra tilhørende slot',
    unassignBulkConsignmentsFromSlots: 'Sendingene har nå blitt fjernet fra sine respektive ruter',
    unassignTitle: 'Fjern leveransen fra tilhørende slot',
    unassignButton: 'Fjern fra slot',
    forceUnassignButton: 'Tving fjerning fra slot',
    moveOrder: 'Er du sikker på at du vil flytte denne leveransen?',
    driverName: 'Sjåfør',
    filters: { assigned: 'Tildelt', unassigned: 'Ikke tildelt', pickups: 'Opphentinger', deliveries: 'Leveringer' },
    saveSelected: 'Lagre valgte ({{count}})',
    showSaved: 'Vis lagrede ({{count}})',
    showAll: 'Vis alle',
    clearSaved: 'Fjern lagrede',
    sendPrePickupSms: 'Forhåndssend sporings-SMS til mottaker',
    sendingSmsText: 'Sendinger {{count}} sporings-SMS',
    prePickupSmsSent: '{{sentCount}} av {{totalCount}} sporings-SMS sendt',
    manualDelaySms: {
      smsSent: '{{count}} manuelle forsinkelse SMS sendt',
      heading: 'Manuell forsinkelse SMS',
      sendingSmsText: 'Sender {{count}} manuelle forsinkelse SMS-er',
      selectSmsDescription: 'SMS-en vil starte med "Hei, vi er dessverre forsinket". Velg SMS-avslutning nedenfor',
      selectSmsEnding: 'Velg SMS-avslutning',
      endingType: {
        none: 'Ingen',
        soon: '"Vi kommer til å levere så raskt som mulig"',
        tomorrow: '"Vi forsøker å levere neste virkedag"'
      },
      disabledTooltip: 'Ordrer kan ikke være i tilstanden Levert'
    },
    h2: { vasHelperText: 'Bruk VAS 1158 for MBH' },
    loadList: {
      header: 'Ruteoppsummering',
      listName: 'Lasteliste',
      unknownRouteName: 'Ukjent rutenavn',
      unknownUnitName: 'Ukjent enhetsnavn',
      noDataText: 'Ingenting å se her'
    },
    locationList: { listName: 'Lokasjonsliste' },
    assignedUnits: 'Tildelte enheter',
    unassignedUnits: 'Utildelte enheter',
    noUnitSelected: 'Ingen enhet valgt',
    unitAssignmentCount: 'Enhet tilordnet til {{count}} ruter',
    breakDeletedFromRoute: 'Pausetiden ble fjernet fra rute',
    autoRouteModal: {
      selectRoutes: 'Velg ruter',
      selectRoutesText: 'Velg rutene du vil bruke i den automatiske rutingen',
      lockAllToSlot: 'Lås alle tildelte ordre til ruten',
      useMapAreaRestrictions: 'Bruk kartområderestriksjoner',
      useGraphHopper: 'Bruk GraphHopper',
      usePtv: 'Bruk PTV',
      usePtvPilot: 'Premium optimering',
      usePtvCloud: 'Bruk PTV Cloud',
      useNoEngine: 'Ikke bruk optimaliseringsmotor på denne spesifikke ruten',
      optimizeOn: 'Optimer på',
      noRelevantRoutes: 'Fant ingen relevante ruter',
      startAutomaticRouting: 'Start automatisk ruting',
      selectOptimizingEngine: 'Velg optimaliseringsmotor',
      useFlexAndForceFromPrevious: 'Bruk fleks og tvungen rekkefølge fra tidligere optimalisering'
    }
  },
  plannerDashboard: {
    vehicleTypes: {
      car: 'Bil',
      bicycle: 'Sykkel',
      truck: 'Lastebil',
      van: 'Varebil',
      motorcycle: 'Motorsykkel',
      cargobike: 'Lastesykkel'
    }
  },
  updateServiceLevel: {
    menuItem: 'Endre servicenivå',
    selectOption: 'Velg servicenivå',
    heading: 'Endre servicenivå',
    confirmation: 'Servicenivå oppdatert',
    subtext: 'Endre servicenivå for {{count}} sending',
    subtext_plural: 'Endre servicenivå for {{count}} sendinger’',
    failed: {
      title: 'Kunne ikke endre servicenivå',
      message: 'Kunne ikke endre servicenivå for {{count}} sending',
      message_plural: 'Kunne ikke endre servicenivå for {{count}} sendinger'
    }
  },
  manualPreAdvice: {
    menuItem: 'Manuell preavisering',
    selectOption: 'Velg preaviseringstype',
    heading: 'Manuell preavisering',
    confirmation: 'Preavisering(er) opprettet',
    subtext: 'Manuell preavisering av {{count}} sending',
    subtext_plural: 'Manuell preavisering av {{count}} sendinger',
    failed: {
      title: 'Manuell preavisering feilet',
      message: 'Noe gikk galt ved preavisering av {{count}} sending',
      message_plural: 'Noe gikk galt ved preavisering av {{count}} sendinger'
    }
  },
  setPickupDeliveryDuration: {
    menuItem: 'Angi varighet for henting/levering',
    heading: 'Angi varighet for henting og levering',
    subtext: 'Angi varighet for henting og levering på {{count}} forsendelse',
    subtext_plural: 'Angi varighet for henting og levering på {{count}} forsendelser',
    pickupDuration: 'Varighet henting',
    deliveryDuration: 'Varighet levering',
    confirmation: 'Varighet oppdatert',
    tooltipTitle: 'Når du oppgir varigheter, er det maksimale antallet minutter og sekunder 59'
  },
  importExportOrders: {
    importExportOrder: 'Import / Eksport',
    importSuccess: 'Alle bestillinger i regnearket legges til eller oppdateres i systemet',
    importSuccessTitle: 'Suksess',
    importOrdersHeading: 'Importer ordre',
    importStatus: 'Det er for øyeblikket {{imports}} importer i kø med totalt {{orders}} ordre',
    exportForInvoice: 'Eksport for fakturering',
    fileTemplateLink: 'Last ned Excel-mal',
    uploadFile: 'Importer ordre',
    uploadingMessage: 'Laster opp filen og importerer bestillingene...',
    needToSelectFile: 'Du må velge en fil',
    exportSuccess: 'Alle bestillinger i den valgte datoperioden er lastet ned til regnearket',
    exportSuccessTitle: 'Suksess',
    testDataWarning: 'Ikke bruk personopplysninger i testmiljø',
    testDataInfo:
      'Dette er et qa/testmiljø, og du har ikke lov til å bruke produksjonsdata i test uten berørte brukeres eksplisitte samtykke.',
    fromDate: 'Fra dato',
    toDate: 'Til dato',
    fromDateNotAfterToDate: "Kan ikke være senere enn 'Til dato'",
    toDateNotBeforeFromDate: "Kan ikke være tidligere enn 'Fra dato'",
    removeControlColumns: 'Fjern kontrollkolonner',
    importResults: 'Importer resultater',
    fileName: 'Importert fil',
    processedAt: 'Behandlet',
    createdShipmentId: 'Opprettet forsendelses-ID',
    date: 'Dato',
    exportShipments: 'Eksporter forsendelser',
    createLabels: 'Opprett etiketter',
    selectSlot: 'Velg rute',
    selectExportLabel: 'Velg hva du vil eksportere',
    allOrders: 'Alle Ordre',
    deviatedOrders: 'Ordre med avvik',
    all: 'Alle',
    noSelection: 'Ingen valg',
    selectFile: 'Velg fil',
    noFileSelected: 'Ingen fil',
    updateSelectedFile: 'Oppdater fil',
    jobList: {
      title: 'Tidligere eksporter',
      content: 'Innhold',
      created: 'Opprettet',
      user: 'Bruker',
      dates: 'Datoer',
      download: 'Last ned',
      exporting: 'Eksporterer...',
      inQueue: 'I eksportkø',
      exportFailed: 'Eksporten mislyktes',
      updated: 'Oppdatert',
      previousImports: 'Tidligere import',
      fileName: 'Filnavn',
      numberOfOrders: 'Antall ordre',
      imported: 'Importert',
      viewOrders: 'Vis ordre',
      lastImports: 'Viser de siste {{count}} importene'
    },
    exportStartedNotification: 'Eksporten startet',
    exportStartedNotificationMessage: 'Rapporten din vil være tilgjengelig så snart den er behandlet.',
    exportFailedNotification: 'Eksporten mislyktes. Prøv igjen senere',
    refreshData: 'Oppdater data i eksisterende rapport',
    refreshDataDescription:
      'Velg dette alternativet hvis du allerede har opprettet en eksisterende rapport for de spesifikke datoene',
    types: {
      SHIPMENT_EXPORT: 'Forsendelser',
      AIREXPRESS_SHIPMENTS: 'Forsendelser (med AirExpress-data)',
      COURIER_WORK_REPORT: 'Arbeidsrapport for sjåfører',
      COURIER_WORK_REPORT_EXTENDED: 'Utvidet arbeidsrapport for sjåfører',
      SOCIAL_CONTROL: 'Sosialkontroll',
      LOCATION_LIST: 'Lokasjonsliste',
      ORDER_EXPORT: 'Eksport av ordre',
      ORDER_DEVIATION_EXPORT: 'Ordre (Avvik)',
      ORDER_EXPORT_ANONYMOUS: 'Ordre (Anonymisert Data)',
      ORDER_PARTNER_EXPORT: 'Ordre (For Partnere)',
      DEVIATION_EVENTS: 'Avvikshendelser',
      OPTIMIZATION_RESULTS: 'Optimeringsresultater',
      FINANCIAL_REPORT: 'Økonomisk rapport'
    },
    exportTitles: {
      SHIPMENT_EXPORT: 'Eksporter forsendelser',
      AIREXPRESS_SHIPMENTS: 'Eksporter forsendelser (med AirExpress-data)',
      COURIER_WORK_REPORT: 'Arbeidsrapport for sjåfører',
      COURIER_WORK_REPORT_EXTENDED: 'Utvidet arbeidsrapport for sjåfører',
      SOCIAL_CONTROL: 'Eksporter Sosialkontroll',
      LOCATION_LIST: 'Eksporter lokasjonsliste',
      ORDER_EXPORT: 'Eksporter ordre',
      ORDER_DEVIATION_EXPORT: 'Eksporter Ordre med avvik',
      ORDER_EXPORT_ANONYMOUS: 'Eksporter Ordre Anonymisert',
      ORDER_PARTNER_EXPORT: 'Eksporter Ordre for partnere',
      DEVIATION_EVENTS: 'Eksporter avvikshendelser',
      OPTIMIZATION_RESULTS: 'Eksporter optimeringsresultater',
      FINANCIAL_REPORT: 'Eksporter økonomisk rapport'
    },
    exportDescriptions: {
      AIREXPRESS_SHIPMENTS: '',
      ORDER_EXPORT:
        'Hvis du ønsker å bruke data i testmiljøer, anbefaler vi å bruke alternativet "Ordre (anonymiserte data)"',
      ORDER_DEVIATION_EXPORT:
        'Hvis du ønsker å bruke data i testmiljøer, anbefaler vi å bruke alternativet "Ordre (anonymiserte data)"',
      ORDER_EXPORT_ANONYMOUS: 'Kan brukes hvis data skal importeres til et testmiljø',
      ORDER_PARTNER_EXPORT: 'Tilpasset excelfil som kan brukes for import i Opter',
      OPTIMIZATION_RESULTS: 'Tilpasset excelfil med optimeringsresultater',
      FINANCIAL_REPORT: 'Inneholder tilleggsark med ordre nedbrukket på debiteringsrader der det er relevant'
    }
  },
  unit: {
    unitWithCount: '{{count}} enhet',
    unitWithCount_plural: '{{count}} enheter',
    new: 'Ny enhet',
    edit: 'Endre enhet',
    addCourier: 'Legg til sjåfør',
    hideAddCourier: 'Skjul legg til sjåfør',
    requiresCourierUsers: 'Må ha minst én sjåfør',
    created: 'Enheten har blitt opprettet',
    updated: 'Enheten har blitt oppdatert',
    search: 'Søk etter enheter fra andre avdelinger...',
    unitsInCurrentDepartment: 'Enheter i nåværende avdeling',
    unitsInOtherDepartments: 'Enheter i andre avdelinger',
    saveUnit: 'Lagre enhet',
    addCourierToUnit: 'Legg sjåfør til enhet',
    clearSearch: 'Tøm søk',
    noCouriersFound: 'Ingen sjåfør funnet',
    addCourierToUnitSearch: 'Du kan bare søke etter sjåfører i avdelinger du har tilgang til',
    addedCouriers: 'Lagt til sjåfør ({{count}})',
    newCourier: 'Opprett ny sjåfør',
    name: 'Navn',
    unitId: 'Enhets-ID',
    unitId_glow: 'Alystra-ID',
    couriers: 'Sjåfør',
    active: 'Aktiv',
    vehicle: 'Kjøretøy',
    deleteQuestion: 'Fjern enheten fra nåværende avdeling',
    deleteDescription: 'Enheten kan kobles til igjen senere hvis den fortsatt er tilkoblet andre avdelinger',
    unassigned: 'Enheten ble fjernet fra avdelingen',
    setDefaultVehicle: {
      add: 'Legg til kjøretøy',
      change: 'Bytt kjøretøy',
      title: 'Legg kjøretøy til {{unit}}',
      selectVehicle: 'Velg kjøretøy',
      addSuccess: 'Kjøretøyet ble lagt til {{unit}}',
      changeSuccess: 'Kjøretøyet ble endret på {{unit}}'
    }
  },
  courier: {
    isActive: 'Er aktiv',
    canLogIn: 'Kan logge inn',
    recipientName: 'Mottakernavn',
    created: 'Sjåføren har blitt opprettet',
    updated: 'Sjåføren har blitt oppdatert',
    courier: 'Sjåfør',
    couriers: 'Sjåfører',
    noCouriers: 'Ingen sjåfører',
    courierWithCount: '{{count}} sjåfør',
    courierWithCount_plural: '{{count}} sjåfører',
    numberOfCouriers: 'Fant {{number}} sjåfører',
    multipleDrivers: 'Flere transportører',
    missingId: 'Id mangler',
    missingName: 'Navn mangler',
    search: 'Søk etter sjåfører',
    new: 'Ny sjåfør',
    edit: 'Endre sjåfør',
    name: 'Navn',
    phoneNumber: 'Telefonnummer',
    driverId: 'Sjåfør-ID',
    capacity: 'Kapasitet',
    capacityLabel: 'Kapasitet (antall)',
    weightCapacity: 'Vektkapasitet (kg)',
    volumeCapacity: 'Volumkapasitet (m³)',
    maxRangeKm: 'Rekkevidde (km)',
    color: 'Farge',
    resetColor: 'tilbakestill farge',
    speedFactor: 'Hastighetsfaktor',
    speedFactorExplanation: 'lavere = tregere',
    serviceTimeFactor: 'Opphentings- og leveransehastighetsfaktor',
    serviceTimeFactorExplanation: 'lavere = raskere',
    defaultStopLocation: 'Sluttlokasjon',
    defaultStartLocation: 'Startlokasjon',
    slotNumDeliveries: '{{count}} levering',
    slotNumDeliveries_plural: '{{count}} leveringer',
    slotNumPickups: '{{count}} opphenting',
    slotNumPickups_plural: '{{count}} opphentinger',
    deliverPerformButtonLabel: 'Levér',
    handoverPerformButtonLabel: 'Overlever',
    deviationPerformButtonLabel: 'Avvik',
    deliverWithoutSignatureButtonLabel: 'Uten signatur',
    deliverWithSignatureButtonLabel: 'Signatur',
    deviateNotAtHomeButtonLabel: 'Ikke hjemme',
    deviateLocNotFoundButtonLabel: 'Fant ikke destinasjonen',
    deviateRecipientDeclinedButtonLabel: 'Mottaker ville ikke ha den',
    deviateTimeWindow: 'Fikk ikke mulighet å levere innen tidsvinduet',
    addressNotFound: 'Adresse ikke funnet',
    deliverySpotUnavailable: 'Avtalt leveringssted utilgjengelig',
    noAvailableHatchInParcelBoxMachine: 'Ingen tilgjengelig luke',
    packageDoesNotFitInParcelBox: 'Pakken overskrider tillatte dimensjoner/vekt',
    unknownErrorForParcelBoxDelivery: 'Annet ukjent',
    errorInParcelBoxMachine: 'Maskinen er ute av drift',
    deviation: {
      'H-12': 'Sendingen er forsøkt levert, men mottaker var ikke tilstede',
      'H-11': 'Pakken ble forsøkt levert, men mottakeren ble ikke funnet på stedet',
      'H-10': 'Pakken ble forsøkt levert, men adressen ble ikke funnet',
      'H-13': 'Sendingen er forsøkt levert, men er nektet mottatt',
      '7-10': 'Leveransen kunne ikke plukkes opp pga ufullstending adresse',
      '7-12': 'Avsenderen var ikke tilgjengelig. Leveransen har ikke blitt hentet',
      '7-82': 'Leveransen var ikke klar for å bli plukket opp',
      'V-41-K': 'Sendingen er forsinket, den vil bli videresendt så raskt det lar seg gjøre',
      'H-07-R': 'Mislykket sosialkontroll',
      'H-07-Z': 'Mislykket sosialkontroll',
      'H-08-Z': 'Mislykket alderskontroll',
      'H-09-Z': 'Mislykket ID-kontroll',
      'VI-16-X': 'Fikk ikke mulighet å levere innen tidsvinduet',
      'H-11-Z': 'Adresse ikke funnet',
      'H-BL-A': 'Avtalt leveringssted utilgjengelig',
      'V-100-CA': 'Ingen pakkeboks tilgjengelig. Pakken leveres som hjemlevering',
      'V-100-CI': 'Ingen tilgjengelig luke',
      'V-80-CI': 'Pakken overskrider tillatte dimensjoner/vekt',
      'V-99-CI': 'Annet ukjent',
      'V-AO-CI': 'Maskinen er ute av drift'
    },
    completionProgressCounter: '{{numCompleted}}/{{numTotal}} ferdige',
    lockCode: 'Dørkode',
    flexDelivery: 'Forenklet levering',
    fuelType: 'Drivstofftype',
    fuelTypes: {
      gasoline: 'Bensin',
      natural_gas: 'Naturgass',
      ethanol: 'Etanol',
      bio_gas: 'Biogass',
      electric: 'Elektrisitet',
      bicycle: 'Sykkel',
      diesel: 'Diesel',
      rme: 'RME',
      hvo: 'HVO',
      hydrogen: 'Hydrogen'
    },
    fuelCategory: 'Drivstoffkategori',
    fuelCategories: {
      fossil: 'Fossilt drivstoff',
      fossil_free: 'Fossilfritt drivstoff',
      zero_emissions: 'Null utslipp'
    },
    latestLogin: 'Siste pålogging',
    latestVersion: 'Siste versjon',
    failedSocialControl: 'Mislykket sosialkontroll',
    failedIdControl: 'Mislykket ID-kontroll',
    failedAgeControl: 'Mislykket alderskontroll',
    incompletePickupAddress: 'Leveransen kunne ikke plukkes opp pga ufullstending adresse.',
    custNotAvailableAtPickupAddress: 'Avsenderen var ikke tilgjengelig. Leveransen har ikke blitt hentet.',
    goodsNotReadyAtPickupForCust: 'Leveransen var ikke klar for å bli plukket opp.',
    shipmentNotAvailableAtTerminal: 'Sendingen er forsinket, den vil bli videresendt så raskt det lar seg gjøre.',
    registrationNumber: 'Registreringsnummer',
    commercialTrafficEligibility: 'Kommersiell trafikk berettigelse',
    vehicleBrand: 'Kjøretøymerke',
    vehicleModel: 'Kjøretøymodell',
    vehicleControl: 'Kjøretøykontroll',
    vehicleControlComment: 'Kommentar om kjøretøykontroll',
    euroClass: 'Euroklasse',
    euroClassTypes: { euro5: 'Euro 5', euro6: 'Euro 6', euroEL: 'Euro EL' },
    alcoholDeliveryEducation: 'Alkoholleveringsutdanning',
    approved: 'Godkjent',
    notApproved: 'Ikke godkjent',
    couriersInCurrentDepartment: 'Sjåfører i nåværende avdeling',
    couriersInOtherDepartments: 'Sjåfører i andre avdelinger',
    inCurrentDepartment: 'Nåværende avdeling',
    inOtherDepartments: 'Ulike avdelinger',
    noCouriersExplain: 'Vi fant ingen resultater. Prøv å søke med et annet begrep',
    confirmConnect: 'Koble sjåfør til nåværende avdeling',
    confirmModalDescription: '',
    searchForCouriers: 'Søk etter sjåfør',
    dateOfBirthWithFormat: 'Fødselsdato (dd-mm-yyyy)',
    occupationalInjuryInsurance: 'Yrkesforsikring',
    editCourier: 'Rediger Sjåfør:',
    courierUserUpdated: 'Sjåfør har blitt oppdatert.',
    courierScanEnabled: 'Skann',
    breakTime: 'Pause',
    breakDuration: 'Pausens varighet (min)',
    timeWindow: 'Start på pause (tidligste og seneste)',
    breakTimeDescription:
      'I feltene <i>Tidligst</i> og <i>Senest)</i> angir du tidligste og seneste tidspunkt for pause. For eksempel betyr 11:00 - 17:00 at sjåføren senest kan starte pausen kl. 17:00.',
    between: 'Mellom',
    editBreakTime: 'Rediger pausetid',
    updateBreakTime: 'Oppdater pausetid',
    latestBreakStart: 'Seneste start på pause',
    radioButtons: { noBreakTime: 'Ingen pause', timeWindow: 'Tidsvindu', workingTime: 'Arbeidstid' },
    workingTimeDescription:
      'Du setter maksimal arbeidstid før sjåføren må ta en pause. For eksempel";" etter 8 timer må sjåføren ta en 30-minutters paus',
    maxWorkingTime: 'Maksimal arbeidstid',
    selectMaxWorkingTime: 'Velg maksimal arbeidstid',
    hours: '{{hours}} timer',
    breakTooltip: 'Du kan bare ha én pausetype per enhet',
    secureLoginEnabled: 'BankID-innlogging',
    alystraId: 'Alystra-ID',
    unitId: 'Enhets-ID',
    minShipmentWeightKg: 'Minimum fraktvekt på dette kjøretøyet (kg)',
    maxShipmentWeightKg: 'Maksimal fraktvekt på dette kjøretøyet (kg)',
    co2: 'CO2',
    dateOfBirth: 'Fødselsdato',
    dateNotValid: 'Ugyldig dato'
  },
  slot: {
    minutesDelay: 'minutter forsinket',
    minutesAhead: 'minutter før tiden',
    minutes: 'minutter',
    time: 'Tid',
    created: 'Opprettet',
    assigned: 'Tildelt',
    lock: 'Lås',
    lockDescription:
      'Når slotten er låst, så vil den ikke være en del av nye ruteoptimaliseringer, og kan ikke bli endret på.',
    toggleVisibleSlotDescription: 'Skjul slot.',
    inProgress: 'I behandling',
    onHold: 'På vent',
    complete: 'Fullført',
    unknownState: 'Ukjent tilstand',
    courierSelect: 'Velg sjåfør',
    unitSelect: 'Velg enhet',
    printableSlotList: 'Slot-liste for utskrift',
    followsRoute: 'Følger',
    doesNotFollowRoute: 'Avviker',
    hasLocation: 'Synlig',
    hasNoLocation: 'Ikke synlig',
    showActiveSlots: 'Vis aktive',
    showSlotNotStarted: 'Vis ikke startet',
    showCompletedSlot: 'Vis fullførte',
    showPerfectSlot: 'Vis perfekte',
    filterSlots: 'Filtrer slots',
    planningCompleted: 'Ferdig',
    addMapArea: 'Velg område i kart',
    editMapArea: 'Endre område i kart'
  },
  login: {
    heading: 'Logg Inn',
    phone: 'Tast inn ditt Glow registerte telefonnummer, med landkode (f.eks. +47 ...), og vi sender SMS kode',
    code: 'Vi har sendt deg en SMS kode, dersom ditt nummer er registrert. Denne koden er gyldig i 5 minutter. Tast inn koden.',
    requestNewCode: 'Be om en ny kode.',
    verifyFailed:
      'Kode eller telefonnummer er feil, eller du har forsøkt å logge inn for mange ganger. Prøv igjen, eller be om ny kode.',
    requestFailed: 'Kunne ikke sende kode, prøv igjen..',
    verifyTokenButton: 'Verifiser kode',
    requestTokenButton: 'Be om kode',
    privacyLink: 'Personvernerklæring',
    passwordWrong: 'Feil passord',
    customer: 'Jeg er en kunde',
    forgotPassword: 'Glemt passordet?',
    tryApp: 'Prøv den nye appen!',
    appDownload: 'Last ned sjåfør-appen her og få tilgang til mere funksjonalitet!',
    appInfo:
      'Er du en sjåfør med en Android-telefon? Last ned sjåfør-appen <1>her</1> og få tilgang til mere funksjonalitet!',
    note: 'Merk! Bruk bare webversjonen av budprogrammet hvis du av en eller annen grunn ikke kan bruke appene til iOS og Android',
    resendToken: 'Send kode på nytt',
    submitPassword: 'Send inn passord',
    loginAsCustomer: 'Logg inn som kunde',
    loginAsPlanner: 'Logg inn som planlegger',
    token: 'Kode',
    password: 'Passord',
    tokenRequired: 'Kode kreves',
    forgotPasswordDescription:
      'For å fortsette må du angi et passord. Vi sender deg en e-post med en lenke for å angi dette. Hvis e-postadressen din er endret, må du kontakte en administrator for å endre den.,',
    sendEmailDescription:
      'For å fortsette trenger vi e-posten din. Vi sender deg en bekreftelseslenke til den oppgitte adressen. Med denne lenken kan du angi 2-faktor passord.',
    emailFormatValidation: 'E-post må være i gyldig format,',
    sendEmailSuccess: 'E-posten skal komme innen kort tid',
    sendEmailFailed: 'Sending av e-post mislyktes, prøv igjen',
    setYourPassword: 'Angi passordet ditt',
    sendEmail: 'Send e-post',
    passwordsDontMatch: 'Passord stemmer ikke',
    passwordNotMeetRequirements: 'Passord oppfyller ikke kravene,',
    passwordRequired: 'Passord er påkrevd,',
    newPassword: 'Nytt passord',
    repeatPassword: 'Gjenta passordet ditt',
    lowercaseLetter: 'En liten bokstav',
    uppercaseLetter: 'En stor bokstav',
    numberCharacter: 'Et tall',
    minimumCharacters: 'Minimum 15 tegn',
    passwordDescription: 'Du kan nå angi passordet ditt, det må inneholde følgende'
  },
  error: {
    '404Title': 'Fant ikke siden',
    '404Message': 'Siden du leter etter er ikke tilgjengelig',
    '500Message': '{{error}} - Beklager, men vi får ikke tak i dine data... prøver igjen om litt..',
    title: 'Noe gikk galt!',
    dateRequiredWhenSecureLogin: '*Obligatorisk felt om BankID er aktivert',
    dateNotValid: 'Ugyldig dato',
    errorMessage: 'Beklager! Dette gikk ikke bra, men utviklerne har fått beskjed om feilen..',
    errorClickToReload: 'Trykk her for å laste siden på nytt',
    phoneNumber: 'Du må angi en gyldig telefonnummer, f.eks. +47 12345678',
    phoneNumberPrefix: 'Du må angi en landkode, f.eks. +47 ...',
    somethingWentWrong: 'Noe gikk galt når vi forsøkte å lagre...',
    notAValidNumber: 'Må være et nummer',
    notAValidAlphaNumeric: 'Må være tall og/eller bokstaver',
    notAValidDate: 'Må være en gyldig dato (yyyy-mm-dd)',
    notAValidTime: 'Må være et gyldig klokkeslett (hh:mm)',
    notAValidDuration: 'Må være en gyldig varighet (mm:ss)',
    notAValidZipCode: 'Må være et gyldig postnummer',
    notAValidTimezone: 'Må være en gyldig tidssone. Prøv "Europe/Oslo" eller lignende',
    somethingWentWrongOnTheServer: 'Noe gikk galt på serveren...',
    somethingWentWrongWithTheRequest: 'Noe gikk galt med operasjonen...',
    somethingWentWrongWithTheResponse: 'Noe gikk galt med operasjonen...',
    selectAtLeastOne: 'Du må velge minst ett av valgene',
    requiredBreakDuration: 'Du må angi pausevarighet',
    notAValidTimeWindow: 'Den tidligste pausen må starte før den seneste',
    requiredEarliestStartBreak: 'Du må angi tidligste pause',
    requiredLatestStartBreak: 'Du må angi seneste pause',
    requiredIfWorkingTimeDuration: 'Du må angi maksimal arbeidstid',
    timeInWrongFormat: 'Tiden er ikke gyldig',
    invalidEmail: 'Du må oppgi en gyldig e-postadresse, f.eks. name@gmail.com',
    invalidUrlParameterCombination: 'Denne kombinasjonen av URL-parametere er ugyldig og siden kan ikke vises',
    numberCannotContainDecimals: 'Tallet kan ikke inneholde desimaler'
  },
  loading: 'Vi jobber med å finne dine data...',
  newAppVersion: 'Vi har akkurat lagt ut en ny versjon, den lastes nå.',
  loadingReceiptList: 'Kvitteringslister blir generert...',
  invoicing: { departmentInvoicingInProgress: 'Fakturering pågår...' },
  months: {
    january: 'Januar',
    february: 'Februar',
    march: 'Mars',
    may: 'Mai',
    june: 'Juni',
    july: 'Juli',
    october: 'Oktober',
    december: 'Desember'
  },
  weekdays: {
    monday: 'Mandag',
    tuesday: 'Tirsdag',
    wednesday: 'Onsdag',
    thursday: 'Torsdag',
    friday: 'Fredag',
    saturday: 'Lørdag',
    sunday: 'Søndag'
  },
  weekdaysShort: {
    sunday: 'Søn',
    monday: 'Man',
    tuesday: 'Tir',
    wednesday: 'Ons',
    thursday: 'Tor',
    friday: 'Fre',
    saturday: 'Lør'
  },
  maps: { addressLookup: 'Søk etter adresse' },
  notifications: {
    dismiss: 'Lukk',
    dismissAll: 'Lukk alle',
    error: 'Feil',
    reload: 'Last inn på nytt',
    reloadCourier: 'Trykk her for å laste på nytt',
    navigate: 'Gå til siden',
    networkError:
      'Det ser ut til at du har mistet nettverketstilkoblingen, dette kan ha vært midlertidig, men siden må lastes på nytt',
    networkErrorCourier: 'Du har vært uten nettverk, last siden på nytt.'
  },
  grid: {
    columns: {
      addressWashRecipientId: 'Mottaker ID',
      addressWashAddressId: 'Adresse ID',
      palletSpace: 'Pallplass',
      department: 'Avdeling',
      deadline: 'Frist',
      failedReason: 'Feilmelding',
      exceptions: 'Unntak',
      dispatchAsGroup: 'Dispatch som gruppe',
      name: 'Navn',
      senderName: 'Avsenders navn',
      delearly: 'LEV',
      dellate: 'LEV',
      zip: 'Postnr',
      estimated: 'Estimert tid',
      adjusted: 'Justert',
      timeWindow: 'Tidsvindu',
      onCar: 'på enhet',
      frequency: 'Frekvens',
      startDate: 'Startdato',
      endDate: 'Sluttdato (valgfritt)',
      from: 'Fra',
      to: 'Til',
      days: 'Dager',
      holidays: 'Helligdager',
      startdate: 'Startdato',
      enddate: 'Sluttdato',
      orders: 'Ordre',
      id: 'ID',
      shipmentId: 'Sendingsnummer',
      packageId: 'Kollinummer',
      errorMessage: 'Feilmelding',
      customer: 'Kunde',
      pickup: 'Opphenting',
      delivery: 'Levering',
      service: 'Tjeneste',
      address: 'Adresse',
      zipcode: 'Postnummer',
      area: 'By',
      weight: 'Vekt',
      volume: 'Volum{{unit}}',
      qty: 'Ant',
      preplanned: 'Planlagt',
      preplannedRouteTemplate: 'Rute',
      group: 'Gruppe',
      totalOrders: 'Totalt antall',
      ordersFailed: 'Ordre feilet',
      date: 'Dato',
      finished: 'Ferdig',
      failed: 'Feilet',
      ordersInvoiced: 'Ordre fakturert',
      stop: 'Stopp',
      pickupContactPerson: 'Kontaktperson henting',
      pickupPhone: 'Telefon henting',
      deliveryContactPerson: 'Kontaktperson levering',
      deliveryPhone: 'Telefon levering',
      contactPerson: 'Kontaktperson',
      phone: 'Telefon',
      deliveryReferencePlaceholder: 'Mottakers referanse',
      specificationDescriptionPlaceholder: 'Forsendelsesinformasjon',
      groupName: 'Gruppenavn',
      customerNumber: 'Kundenummer',
      orderNote: 'Notat',
      returned: 'Returnert',
      price: 'Pris',
      slotName: 'Rute',
      waitingTime: 'Ventetid',
      deliveryEtaSmsSentAt: 'Leverings ETA-SMS sendt',
      trip: 'Tur',
      addresses: {
        title: 'Adresser',
        search: 'Adressesøk',
        searchAddresses: 'Søk etter adresser',
        filterAddresses: 'Filtrer adresser',
        deleteConfirmation: 'Er du sikker på at du vil slette denne adressen?',
        deleteMultipleConfirmation: 'Er du sikker på at du vil slette alle valgte adresser?',
        deleteSuccess: 'Adresse slettet',
        deleteMultipleSuccess: 'Adresser slettet',
        delete: 'Slett',
        name: 'Navn',
        phone: 'Telefonnummer',
        address: 'Adresse',
        zipArea: 'Poststed',
        zipCode: 'Postnummer',
        country: 'Land',
        contactPerson: 'Kontaktperson',
        instructions: 'Instruksjoner',
        deleteAddress: 'Slett adresse',
        deleteMultipleAddresses: 'Slett adresser ({{count}})',
        loc: 'Sted',
        locTitle: 'Vis på kartet'
      },
      orderGrid: { vehicleType: 'Kjøretøy' },
      dispatch: {
        id: 'ID',
        address: 'Addresse',
        adjusted: 'Justert',
        alreadyAssigned: '<< Allerede tildelt',
        customerName: 'Kunde',
        service: 'Tjeneste',
        name: 'Navn',
        timeWindow: 'Tidsvindu'
      },
      orderSource: 'Ordrekilde',
      postalCodes: '# postnumre',
      displayEndCustomerPriceShd: 'Vis kundepris',
      courier: 'Sjåfør',
      createdBy: 'Opprettet av',
      createdAt: 'Opprettet',
      plannedDeliveryDate: 'Planlagt dato',
      orderedDeliveryDate: 'Bestilt dato',
      lastUpdatedAt: 'Sist oppdatert',
      route: 'Rute',
      instructions: 'Instruksjoner',
      pickupDuration: 'PU varighet',
      deliveryDuration: 'LEV varighet',
      originalPickupAddress: 'Opprinnelig avsenderadresse',
      originalPickupZipCode: 'Opprinnelig avsenderpostnummer',
      originalPickupZipArea: 'Opprinnelig avsendersted',
      originalPickupCountry: 'Opprinnelig avsenderland',
      originalDeliveryAddress: 'Opprinnelig leveringsadresse',
      originalDeliveryZipCode: 'Opprinnelig leveringspostnummer',
      originalDeliveryZipArea: 'Opprinnelig leveringssted',
      originalDeliveryCountry: 'Opprinnelig leveringsland',
      packagesArrivedAtDip: 'Ankommet',
      packagesCollected: 'Hentet',
      packagesDelivered: 'Levert',
      originalDeliveryEarliest: 'Opprinnelig LEV',
      originalDeliveryLatest: 'Opprinnelig LEV',
      preAdvices: 'Preavisering',
      preAdviceScheduledDate: 'Preavisering utsendt',
      hasPreAdviceSettings: 'Preaviseringsregler',
      arrivedAtHub: 'Ankommet HUB',
      returnToSender: 'Retur til avsender',
      preAdviceFailed: 'Mislykket',
      deliveryAttempts: 'Leveranseforsøk',
      loadCarrierId: 'Lastbærer-id'
    }
  },
  openBooking: {
    pickupReferencePlaceholder: 'Kundereferanse – synlig på faktura',
    customerContactPlaceholder: '$t(instant.booking.customerContact) – synlig på faktura',
    invoiceInfoPlaceholder: 'Fakturainformasjon – synlig på faktura',
    confirmed: {
      header: 'Takk!',
      text: 'Din leveranse er nå booket. Oppdateringer om opphenting og leveranse sendes på mail til {{email}}.',
      support: 'Har du spørsmål angående din bestilling og leveranse? Ring kundeservice på telefon 04050.',
      book: 'Vil du booke flere bud til en fordelaktig pris? <0>Ta kontakt med oss</0> for å få et tilbud.'
    },
    pickupTimePlaceholder: 'Tidligste hentetidspunkt',
    error: {
      notInRangeZipCode: 'Tjenesten er ikke tilgjengelig i valgt område',
      notInSameZipCodeRange: 'Tjenesten er ikke tilgjengelig mellom de valgte områdene',
      notInAreaRangeZipCode: 'Postnummeret samsvarer ikke med noen kode for området',
      pickupTimeNotWithinServiceTimeWindow: 'Hentetiden er ikke innenfor tjenestetidsvinduet',
      pickupTimeBeforeEarliestServicePickupTime: 'Hentetiden kan ikke være før den tidligste hentetiden for tjenesten',
      pickupNotAvailableForWeekend: 'Hentetjeneste er ikke tilgjengelig i helgene',
      invalidOrganisationNumber: 'Ugyldig organisasjonsnummer'
    }
  },
  instant: {
    openMap: 'Åpne kart',
    group: 'Grupper valgte',
    editGroup: 'Endre gruppe',
    groupName: 'Gruppenavn',
    groupOrders: 'Grupper ordre',
    viewGroup: 'Vis gruppe',
    ordersToGroup: 'Ordre i gruppen',
    fixedOrder: 'Fast rekkefølge',
    paidTogether: 'Betales sammen',
    saveGroup: 'Lagre gruppe',
    ungroup: 'Avgrupper',
    removeSelected: 'Fjern valgte',
    split: 'Splitt valgte',
    noCourierSelected: 'Ingen sjåfør valgt',
    noOrderSelected: 'Ingen ordre valgt',
    workingHours: 'Arbeidstid',
    chatButton: 'Chat med sjåfør',
    offeredShipments: 'Tilbudte leveringer',
    rejectedShipments: 'Avviste leveringer',
    rejectedShipmentsSnackbar: '{{count}} avvist levering av "{{name}}"',
    rejectedShipmentsSnackbar_plural: '{{count}} avviste leveringer av "{{name}}"',
    toBeDone: 'Leveringer som skal gjøres',
    finishedRoutePoints: 'Fullførte leveringer',
    suggestedRouteApplied: 'Foreslått rute lagret',
    suggestedRouteCancelled: 'Foreslått rute slettet',
    reorderValidationError: 'Foreslått rute er ikke gyldig',
    planSlot: 'Planlegg rute',
    columnSettings: {
      reset: 'Nullstill',
      settings: 'Innstillinger',
      hideColumns: 'Vis kolonner',
      saveAndClose: 'Lagre og lukk'
    },
    confirmation: {
      heading: 'Ordrebekreftelse',
      from: 'Fra',
      to: 'Til',
      service: 'Tjeneste',
      pickup: 'Henting',
      delivery: 'Levering',
      shipmentNumber: 'Sendingsnummer',
      printLabel: 'Skriv ut merkelapp',
      copyOrder: 'Kopier ordre',
      editOrder: 'Rediger ordre'
    },
    goods: {
      custom: 'Egendefinert',
      letter: 'Brev',
      smallbox: 'Liten boks',
      mediumbox: 'Mellomstor boks',
      largebox: 'Stor boks',
      tube: 'Rør',
      quarterpallet: 'Kvartpall',
      halfpallet: 'Halvpall',
      fullpallet: 'Pall',
      suitcase: 'Koffert',
      movingbox: 'Flytteeske',
      trolley: 'Vogn',
      parcel: 'Løs kolli',
      expressbox: 'Express boks'
    },
    instantGrid: {
      editColumnTooltip: 'Trykk for å redigere',
      deleteColumnTooltip: 'Trykk for å slette sending',
      unassignColumnTooltip: 'Trykk for å ta sending av enhet',
      dragColumnTooltip: 'Klikk og dra for å tildele',
      filtersActive: 'Aktive filtre',
      clearFilters: 'Fjern alle filtre'
    },
    booking: {
      standaloneInvoiceCheckbox: 'Marker som separat faktura',
      standaloneInvoiceDescription:
        'Ved å velge dette alternativet vil denne orderen bli behandlet individuelt og ikke bli gruppert med andre ordre for fakturering.',
      addPackage: 'Legg til ny pakke',
      removePackageRow: 'Fjern pakke',
      cannotRemoveLastPackageRow: 'Kan ikke fjerne siste pakke',
      copyPackageRow: 'Kopier pakken',
      headerCreate: 'Ny ordre',
      headerEdit: 'Rediger av ordre',
      headerCopy: 'Kopier ordre',
      headerRestore: 'Gjenopprett ordre',
      customer: 'Kunde',
      customerPlaceholder: 'Kunde id',
      invalidSubcustomer: 'Subcustomer-navn er ugyldig',
      invoiceSplitPlaceholder: 'Faktura splitting',
      invoiceSplitPlaceholderLegend: 'Velg faktura splitting',
      shipmentOrPackageId: 'Sendingsnummer eller kollinummer',
      creditBlockedCustomer: 'Kredittsperret kunde',
      sender: 'Avsender',
      setAsDefaultAddress: 'Sett som standardadresse',
      defaultAddressUpdated: 'Standardadresse oppdatert',
      recipient: 'Mottaker',
      pickupNamePlaceholder: 'Navn',
      pickupAddressPlaceholder: 'Addresse',
      pickupZipCodePlaceholder: 'Postnummer',
      pickupZipAreaPlaceholder: 'Poststed',
      pickupCountryPlaceholder: '-- Velg land --',
      pickupPhoneNumberPlaceholder: 'Telefon',
      pickupContactPerson: 'Kontaktperson',
      customerReference: 'Kundereferanse',
      pickupReferencePlaceholder: 'Kundereferanse – dette feltet er synlig på kundens faktura',
      pickupInformationPlaceholder: 'Henteinformasjon',
      customerContact: 'Kontaktperson hos kunden',
      customerContactPlaceholder: '$t(instant.booking.customerContact) – dette feltet er synlig på kundens faktura',
      customerInfoPlaceholder: 'Kundeinformasjon',
      customerInfoHelper:
        'Denne informasjonen er knyttet til kunden. Endringer som blir gjort vil være synlig for alle ordre relatert til denne kunden.<br><br>For å legge til ordrespesifikk informasjon, bruk «notater» feltet under.',
      deliveryNamePlaceholder: 'Navn',
      deliveryAddressPlaceholder: 'Addresse',
      deliveryZipCodePlaceholder: 'Postnummer',
      deliveryZipAreaPlaceholder: 'Poststed',
      deliveryCountryPlaceholder: '-- Velg land --',
      deliveryPhoneNumberPlaceholder: 'Telefon',
      deliverySecondPhoneNumberPlaceholder: 'Telefon 2',
      deliveryEmailPlaceHolderPlaceholder: 'E-post',
      deliveryReferencePlaceholder: 'Mottakers referanse',
      deliveryInformationPlaceholder: 'Leveringsinformasjon',
      invalidInvoiceSplit: 'Ugyldig fakturaoppdelingstekst',
      orderNote: 'Notat',
      clearData: 'Slett data',
      deliveryContactPerson: 'Kontaktperson',
      dimensions: 'Forsendelse',
      weightPlaceholder: 'Vekt (kg)',
      lengthPlaceholder: 'Lengde (cm)',
      widthPlaceholder: 'Bredde (cm)',
      heightPlaceholder: 'Høyde (cm)',
      specificationDescription: 'Forsendelse',
      specificationDescriptionPlaceholder: 'Forsendelsesinformasjon',
      service: 'Tjeneste',
      serviceSelect: 'Velg tjeneste',
      serviceSelectDisabled: 'Endring av tjenester på HD ordre må gjøres i HappyFlow',
      goodsEditDisabled: 'Endringer på gods på HD ordre må gjøres i HappyFlow',
      standardServices: '-- STANDARD TJENESTER --',
      customizedServices: '-- KUNDESPESIFIKKE TJENESTER --',
      serviceCode: 'Tjenestekode',
      servicePlaceholder: 'Tjenestekode',
      invoiceInfo: 'Fakturainformasjon',
      invoiceInfoPlaceholder: 'Fakturainformasjon – denne teksten er synlig på kundens faktura',
      manualInvoiceInfoPlaceholder:
        'Manuell fakturahåndtering (Hvis dette feltet er fylt ut vil ordren måtte faktureres manuelt)',
      pickup: 'Henting',
      delivery: 'Levering',
      pickupDatePlaceholder: 'Hentedato',
      pickupTimePlaceholder: 'Hentetid',
      deliveryDatePlaceholder: 'Leveringsdato',
      deliveryTimePlaceholder: 'Leveringstid',
      restoreButton: 'Gjenopprett',
      orderButton: 'Bestill',
      editButton: 'Oppdater',
      copyButton: 'Bestill',
      created: 'Ordren er registrert',
      edited: 'Ordren er oppdatert',
      quickSelect: 'Hurtigvalg',
      volume: 'Volum',
      totalVolume: 'Totalt volum {{volume}}',
      totalWeight: 'Totalvekt {{weight}}',
      removeItem: 'Fjern',
      copyItem: 'Kopier',
      addItem: 'Legg til',
      calculatePrice: 'Beregn pris',
      priceExplanations: {
        header: 'Prisforklaring',
        article: 'Artikkel: {{article}}',
        priceListName: 'Prisliste:: {{pricelist}}',
        explanations: 'Forklaringer',
        noInfo: 'Ingen prisinformasjon tilgjengelig',
        details: 'Detaljer',
        notFound: 'Ingen prisforklaring tilgjengelig.'
      },
      approveIncompletePrice: 'Jeg godkjenner alle rader med nullpris',
      prePlan: 'Planlegg',
      prePlannedToCourier: 'Velg sjåfør',
      prePlannedToRouteTemplate: 'Velg rute',
      prePlannedToRouteText:
        'Tilgjengelige ruter avhenger av malene som er lagret på avdelingen. For at automatisk ruting skal fungere riktig, må du konfigurere ruteinnstillinger <0>på avdelingsnivå</0> på administrasjonssidene.',
      automaticDepartment: 'Automatisk',
      departmentLabel: 'Avdeling',
      notifications: 'Varsler',
      emailNotifications: 'E-post varslinger',
      eventRegistered: 'Registrert',
      eventPickup: 'Opphenting',
      eventDelivery: 'Levering',
      emailAddress: 'E-postadresse',
      emailRecipientText: 'Følgende e-postadresser vil motta varslinger:',
      invalidEmail: 'Ugyldig e-post',
      editIndicator: 'Rediger',
      updateIndicator: 'Uppdater',
      copyIndicator: 'Kopier',
      quantity: 'Antall',
      additionalServices: 'Tilleggstjenester',
      paymentInformation: 'Betalingsinformasjon',
      paidTogetherLabel: 'Faktureres sammen?',
      groupingLabel: 'Sendingsgruppering',
      groupIdPlaceholder: 'Unik gruppe ID',
      groupOrderPlaceholder: 'Ordningsrekkefølge',
      addRecipient: 'Legg til mottaker',
      addPickup: 'Legg til avsender',
      vehicleType: 'Kjøretøytype',
      vehicleTypeUpdated: 'Kjøretøytype oppdatert',
      selectVehicleType: 'Velg kjøretøytype',
      customerPrice: 'Kundepris',
      resourcePrice: 'Unitpris',
      priceDetails: 'Prisdetaljer',
      priceList: 'Prisliste',
      manualPrice: 'Manuell',
      vehicleTypes: {
        car: 'Bil',
        bicycle: 'Sykkel',
        truck: 'Lastebil',
        van: 'Varebil',
        motorcycle: 'Motorsykkel',
        cargobike: 'Lastesykkel'
      },
      date: 'Dato',
      unNumber: 'UN-nummer',
      hazardous: 'Klasse',
      group: 'Gruppe',
      netWeight: 'Netto vekt',
      name: 'Navn',
      restrictions: 'Begrensninger',
      totalPoints: 'Totale poeng'
    },
    courierSorting: { alystraId: 'Sjåfør id', empty: 'Tom' },
    courierFilter: {
      title: 'Filtrer sjåfører',
      all: 'Vis alle',
      inactive: 'Skjul inaktive',
      offline: 'Skjul offline',
      both: 'Skjul inaktive og offline'
    },
    invoicingFilter: {
      ALL: 'Vis alle',
      INVOICED_OK: 'Vis fakturerte',
      TO_BE_INVOICED: 'Klare for fakturering',
      ORDER_CORRECTION: 'Med ordre-korreksjon',
      INVOICED_FAILED: 'Fakturert med feil'
    },
    shipmentFilter: {
      title: 'Filtrer sendinger',
      showAll: 'Vis alle',
      notCollected: 'Ikke plukket opp',
      hideAll: 'Skjul alle'
    },
    offer: {
      carEmpty: 'Enheten er tom',
      suggestedDelivery: 'Foreslått leveringsrekkefølge',
      newShipments: 'Nye sendinger',
      manualRoute: 'Manuell ruteplanlegging',
      confirmRoute: 'Bekreft rute',
      assigning: 'Tildel',
      replanning: 'Endre ruten til',
      shipmentsTo: 'sendinger til',
      shipmentTo: 'sending til'
    },
    useFlexible: 'Bruk fleksible tider',
    forceOptimizing: 'Tving leveringsrekkefølge',
    replanRoute: 'Endre ruten',
    recurring: {
      successMessage: '{{number}} sending(er) ble lagt til på valgte gjentakende grupper',
      recurringOrders: 'Gjentagende ordre',
      includedOrders: 'Inkluderte ordre',
      details: 'Detaljer',
      editOriginal: 'Rediger original',
      copyOriginal: 'Kopier original',
      notStarted: 'Ikke startet',
      executing: 'Kjører...',
      ended: 'Ferdig',
      pending: 'Gjenstår',
      total: 'Totalt',
      failedOrders: 'Feilede ordre',
      exceptionsHeading: 'Unntak for ordre',
      addExceptionHeading: 'Ny unntaksperiode',
      exceptionEnd: 'Slutt',
      editExceptions: 'Rediger unntak',
      addRecurring: 'Gjentagende',
      addToGroup: 'Velg gjentagende gruppe',
      exceptions: 'Unntak',
      recurringOrderAddedConfirmation: 'Ordre lagt til i gruppe',
      exceptionAddedConfirmation: 'Unntak lagret',
      dispatchAsGroup: 'Dispatch som gruppe',
      name: 'Navn',
      frequency: 'Frekvens',
      frequencies: {
        weekly: 'Hver uke',
        second_week: 'Annenhver uke',
        third_week: 'Hver tredje uke',
        monthly: 'Hver måned'
      },
      daysOfWeek: 'Ukedager',
      includeHolidays: 'Inkludert helligdager',
      startDate: 'Startdato',
      endDate: 'Sluttdato (valgfritt)',
      save: 'Lagre',
      fromDate: 'Fra dato',
      confirmDelete: 'Er du sikker på at du vil slette valgte gjentagende ordre?',
      from: 'Fra',
      createOrders: 'Lag ordre',
      showResult: 'Vis resultat',
      startdate: 'Startdato',
      enddate: 'Sluttdato',
      orders: 'Ordre',
      failed: 'Feilet',
      by: 'Av',
      on: 'den',
      reset: 'nullstill',
      confirmReset: 'Vil du nullstille gjentagende ordre for {{date}}?',
      startDateExplanation: 'Ordre vil bli opprettet på samme dag som startdatoen'
    },
    corrections: {
      title: 'Korrigeringer',
      headline: 'Orderekorrigering for {{date}}',
      accepted: 'Korreksjon akseptert',
      rejected: 'Korreksjon avvist',
      approve: 'GODKJENN',
      decline: 'AVVIS',
      shipmentId: 'Sendingsnummer',
      courier: 'Enhet',
      from: 'Fra',
      to: 'Til',
      details: 'Detaljer',
      pickupWaitingTime: 'Ventetid opphenting',
      deliveryWaitingTime: 'Ventetid levering',
      pickupLoadingTime: 'Lastetid opphenting',
      deliveryLoadingTime: 'Lastetid levering',
      comment: 'Kommentar',
      weight: 'Vekt',
      volume: 'Volum',
      packages: 'Pakke',
      serviceCode: 'Tjenestekode'
    },
    suggestedUnits: {
      unitName: 'Enhetsnavn',
      driverName: 'Sjåførnavn',
      slotName: 'Rute',
      timeAtLocation: 'Tid på plassering',
      distanceFromLocation: 'Avstand fra lokasjon',
      shipmentInformation: 'Sendingsinformasjon',
      suggestedVehicles: 'Foreslåtte kjøretøy',
      suggestedVehiclesOnPickup: 'Foreslåtte kjøretøy ved opphenting',
      suggestedVehiclesOnDelivery: 'Foreslåtte kjøretøy ved levering',
      suggestedUnits: 'Foreslåtte enheter',
      noSuggestionsAvailable: 'Ingen forslag tilgjengelig',
      pickupTime: 'Opphentingstid',
      deliveryTime: 'Leveringstid',
      filterBy: 'Sorter etter'
    }
  },
  errors: {
    ALREADY_ENQUEUED_FOR_INVOICING: 'Allerede køet for fakturering',
    DEPARTMENT_INVALID_ALYSTRA_ID: 'Ugyldig alystra id',
    ENTITY_DOES_NOT_EXIST: 'Entiteten eksisterer ikke',
    INVALID_DATE_RANGE: 'Ugyldig dato tidsrom',
    INVALID_DELIVERY_LATITUDE: 'Ugyldig breddegrad',
    INVALID_DELIVERY_LONGITUDE: 'Ugyldig lengdegrad',
    INVALID_LATITUDE: 'Ugyldig breddegrad',
    INVALID_LONGITUDE: 'Ugyldig lengdegrad',
    INVALID_OR_MISSING_TIME: 'Ugyldig eller manglende tid',
    INVALID_PHONE_NUMBER: 'Ugyldig telefonnummer',
    DUPLICATE_PHONE_NUMBER: 'Duplikat telefonnummer',
    DUPLICATE_PHONE_NUMBER_COURIER: 'En sjåfør er allerede registrert med dette telefonnummeret',
    INVALID_PICKUP_LATITUDE: 'Ugyldig opphenting breddegrad',
    INVALID_PICKUP_LONGITUDE: 'Ugyldig opphenting lengdegrad',
    INVALID_SLOT_TYPE: 'Ugyldig slot type',
    MISSING_ADDRESS: 'Manglende addresse',
    MISSING_ALYSTRA_ID: 'Manglende alystra id',
    MISSING_AREA: 'Manglende område',
    MISSING_CAPACITY: 'Manglende kapasitet',
    MISSING_COLOR: 'Manglende farge',
    MISSING_CONSIGNMENT_INPUTS: 'Manglende pakke inndata',
    MISSING_CONSIGNMENT: 'Manglende pakke',
    MISSING_CONSIGNMENTS: 'Manglende pakker',
    MISSING_COURIER_ID: 'Manglende sjåførs id',
    MISSING_COURIER: 'Du må angi sjåfør',
    MISSING_CREATED_AT: 'Manglende opprett tid',
    MISSING_CUSTOMER_ID: 'Manglende kunde id',
    MISSING_CUSTOMER_REF: 'Manglende kundereferanse',
    MISSING_DATE: 'Manglende dato',
    MISSING_DAYS: 'Manglende dager',
    MISSING_DEPARTMENT: 'Manglende kontor',
    MISSING_DESCRIPTION: 'Manglende beskrivelse',
    MISSING_DEVIATION_TEXT: 'Manglende avvikstekst',
    MISSING_END_LOCATION_LATITUDE: 'Manglende slutt-lokasjon breddegrad',
    MISSING_END_LOCATION_LONGITUDE: 'Manglende slutt-lokasjon lengdegrad',
    MISSING_EVENT_DATA: 'Manglende eventdata',
    MISSING_EVENT_TYPE: 'Manglende eventtype',
    MISSING_FILE_NAME: 'Manglende filnavn',
    MISSING_FROM_DATE: 'Manglende fra dato',
    MISSING_ID: 'Manglende id',
    MISSING_LOCATION_LATITUDE: 'Manglende lokasjon breddegrad',
    MISSING_LOCATION_LONGITUDE: 'Manglende lokasjon lengdegrad',
    MISSING_LOCATION_POSITION: 'Manglende lokasjon position',
    MISSING_LOCATION_TIME: 'Manglende lokasjon tid',
    MISSING_NAME: 'Manglende navn',
    MISSING_GROUP: 'Manglende gruppering',
    MISSING_PACKAGE_ID: 'Manglende pakke id',
    MISSING_PHONE: 'Manglende telefonnummer',
    MISSING_PLANNER_ID: 'Manglende planner id',
    MISSING_SERVICE_CODE: 'Manglende tjenestekode',
    MISSING_SHIPMENT_ID: 'Manglende shipment id',
    MISSING_SLOT_ID: 'Manglende slot id',
    MISSING_SLOT_TYPE: 'Manglende slot type',
    MISSING_SMS_TOKEN: 'Manglende sms token',
    MISSING_START_LOCATION_LATITUDE: 'Manglende start lokasjon breddegrad',
    MISSING_START_LOCATION_LONGITUDE: 'Manglende start lokasjon lengdegrad',
    MISSING_TO_DATE: 'Manglende til dato',
    MISSING_ZIP_AREA: 'Manglende poststed',
    MISSING_ZIP_CODE: 'Manglende postnummer',
    MISSING_COUNTRY_CODE: 'Manglende landkode',
    NO_SUCH_USER: 'Finner ikke bruker',
    PARAM_NOT_OF_EXPECTED_TYPE: 'Parameter ikke av forventet type',
    PARAM_NULL_NOT_ALLOWED: 'Parameter null er ikke lov',
    QUERY_NAME_MISSING: 'Query navn mangler',
    QUERY_PARAMS_MISSING: 'Query params mangler',
    USER_NOT_EXIST_OR_INCORRECT_ROLE: 'Bruker eksisterer ikke eller har ugyldig rolle',
    USER_EXIST_BUT_INCOMPATIBLE_ROLE: 'Bruker eksisterer men har en annen rolle',
    SLOT_CANNOT_BE_STARTED: 'Slot kan ikke startes',
    SLOT_CANNOT_BE_DELETED: 'Slot kan ikke slettes',
    COURIER_NOT_PART_OF_DEPARTMENT: 'Sjåfør ikke den del av avdeling',
    CONSIGNMENT_IN_INCORRECT_STATE_CANNOT_UPDATE: 'Pakke i feil tilstand, kan ikke oppdatere',
    ORDER_DOES_NOT_EXISTS: 'Ordre eksisterer ikke',
    OPTIMIZE_ALREADY_RUNNING: 'Ruteoptimalisering pågår',
    CANNOT_CHANGE_ORDER: 'Kan ikke endre ordre',
    NO_ACCESS: 'Ingen tilgang',
    NO_RELEVANT_CONSIGNMENTS_OR_SLOTS: 'Ingen relevante leveringer for gitte slots',
    NO_RELEVANT_CONSIGNMENTS: 'Ingen relevante leveringer for gitte slots',
    NO_RELEVANT_SLOTS: 'Kun ruter som ikke er startet eller er satt på pause kan brukes for denne optimaliseringen',
    NOT_EQUAL_PICKUP_ADDRESSES: 'Alle henteadresser må være like for å begrense alle forsendelser til én sjåfør',
    NO_SUGGESTIONS_FOUND_FOR_ORDERS: 'Ingen sjåfører er kvalifisert for bestillingene.',
    EXCEL_MISSING_SHIPMENT_ID: 'Manglende shipment id oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_CUSTOMER_REF: 'Manglende kundereferanse oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_CUSTOMER_ID: 'Manglende kunde id oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_DEPARTMENT: 'Manglende avdeling oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_PACKAGE_ID: 'Manglende pakke id oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_NAME: 'Manglende navn oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_INVALID_PHONE: 'Ugyldig telefonnummer oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_ADDRESS: 'Manglende adresse oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_ZIP_AREA: 'Manglende postnummer oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_ZIP_CODE: 'Manglende poststed oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_FROM_DATE: 'Manglende fra dato oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_TO_DATE: 'Manglende til dato oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_NOTIFICATION_SMS: 'Manglende notifikasjons SMS oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_SIZE: 'Manglende størrelse oppgitt i celle {{col}} på rad {{row}}',
    EXCEL_MISSING_ORDERS: 'Fant ingen ordrer i regnearket.',
    EXCEL_MISSING_COUNTRY: 'Fant ikke land kode eller navn i celle {{col}} på rad {{row}}',
    EXCEL_NO_ACCESS_DEPARTMENT: 'Ikke tilgang til avdeling i {{col}} on row {{row}}',
    EXCEL_INVALID_FROM_DATE: 'Fra dato kan ikke være før i dag i cell {{col}} på rad {{row}}',
    EXCEL_INVALID_TO_DATE: 'Til dato kan ikke være før i dag i cell {{col}} på rad {{row}}',
    EXCEL_MISSING_DESCRIPTION: 'Manglende ordrebeskrivese i "{{cell}}" på rad {{row}}',
    EXCEL_MISSING_PICKUP_INSTRUCTIONS: 'Manglende hentebeskrivelse i "{{cell}}" på rad {{row}}',
    EXCEL_MISSING_DELIVERY_INSTRUCTIONS: 'Manglende leveringsbeskrivelse i "{{cell}}" på rad {{row}}',
    MISSING_PICKUP_LOCATION_AND_TIME: 'Mangler henteadresse og tidspunkt',
    MISSING_EARLIEST_PICKUP_DATE: 'Mangler tidligste hentetidspunkt',
    MISSING_LATEST_PICKUP_DATE: 'Mangler seneste hentetidspunkt',
    MISSING_EARLIEST_DELIVERY_DATE: 'Mangler tidligste leveringstidspunkt',
    MISSING_LATEST_DELIVERY_DATE: 'Mangler seneste leveringstidspunkt',
    INVALID_EARLIEST_PICKUP_DATE: 'Ugyldig hentetidspunkt',
    INVALID_LATEST_PICKUP_DATE: 'Ugyldig hentetidspunkt',
    INVALID_EARLIEST_DELIVERY_DATE: 'Ugyldig leveringstidspunkt',
    INVALID_LATEST_DELIVERY_DATE: 'Ugyldig leveringstidspunkt',
    CANNOT_MODIFY_ORDERS_FROM_DIFFERENT_SOURCE: 'Du kan ikke overskrive ordre fra andre kilder enn Excel',
    PICKUP_EARLIEST_SHOULD_BE_BEFORE_DELIVERY_LATEST: 'Opphentingstid må være før leveransetid',
    DELIVERY_EARLIEST_SHOULD_BE_BEFORE_DELIVERY_LATEST:
      'Tidligste leveringstidspunkt må være før siste leveringstidspunkt',
    CANNOT_DELIVER_ON_A_HOLIDAY: 'Kan ikke levere på en helligdag',
    MISSING_TEMPLATE_ID: 'Mangler id på mal',
    TEMPLATE_DOES_NOT_EXIST: 'Malen finnes ikke',
    TEMPLATE_CANNOT_BE_DELETED: 'Malen kan ikke slettes',
    MISSING_TEMPLATE_SLOTS: 'Malen mangler slots',
    TEMPLATE_NAME_ALREADY_EXISTS: 'Det finnes allerede en mal med dette navnet',
    COURIER_ALREADY_EXISTS: 'Det finnes allerede en sjåfør med dette telefonnummeret',
    ORDER_EXIST_FOR_SERVICE: 'Kan ikke slette tjenesten da det eksisterer ordre tilknyttet denne',
    NO_SERVICE_FOUND: 'Legg til ny tjeneste før du tilknytter en kunde',
    CANNOT_DELETE_AS_SERVICE_EXIST: 'Fjern tilknytt til kunde før du sletter tjenesten',
    SLOT_IN_INCORRECT_STATE_CANNOT_UPDATE: 'Slot er ikke i en gyldig tilstand for denne endringen',
    GRAPHHOPPER_UNASSIGNED:
      'Klarte ikke å tildele alle leveransene (pga. tid, kapasitet eller andre faktorer), detaljer: {{details}}',
    GRAPHHOPPER_REQUEST_FAILED: 'Forespørsel om å optimere ruten feilet',
    GRAPHHOPPER_LATEST_ARRIVAL_AT_DELIVERY_AFTER_EARLIEST_DEPARTURE:
      'Siste ankomst for levering kan ikke være etter tidligste avgang - er tidsvinduet passert?',
    GRAPHHOPPER_EARLIEST_GREATER_THAN_LATEST: 'Tidligste tid kan ikke være etter seneste - er noen av tidene passert?',
    GRAPHHOPPER_PICKUP_EARLIEST_AFTER_LATEST:
      'Tidsvindu for avgang for pakke "{{packageId}}" kan ikke være passert ({{pickupEarliest}} - {{pickupLatest}})',
    GRAPHHOPPER_DELIVERY_EARLIEST_AFTER_LATEST:
      'Tidsvindu for levering for pakke "{{packageId}}" kan ikke være passert ({{deliveryEarliest}} - {{deliveryLatest}}',
    SLOT_CANNOT_BE_EDITED: 'Slot er ikke i en gyldig tilstand for denne endringen',
    INVALID_BASIC_SERVICE_CODE: 'Ugyldig basis tjenestekode',
    MISSING_DELIVERY_DATE_LATEST: 'Missing delivery date latest',
    NO_SERVICE_MATCHING_SERVICE_CODE: 'Ingen tjenester matcher tjenestekoden',
    NO_SERVICE_MATCHING_DAY_OF_WEEK: 'Ingen tjenester matcher ukedagen',
    NO_SERVICE_MATCHING_PICKUP_AND_DELIVERY_TIMES: 'Ingen tjenester matcher opphentings- og leveringstidene',
    ORDER_CANNOT_BE_MOVED: 'Leveransen kan ikke flyttes, den kan ikke være koblet til en rute',
    MISSING_DESTINATION_DEPARTMENT_ID: 'Mangler mottakeravdeling',
    SHIPMENT_NOT_FOUND: 'Fant ikke sendingen',
    DUPLICATE_EXCEL_IMPORT:
      'Det samme filnavnet er allerede importert til denne avdelingen i dag. Du kan se resultatene av importen <0>her</0>. Endre filnavnet hvis du vil importere igjen.',
    SHIPMENT_INVALID_QUERY:
      'Under Peak vil søk være begrenset for å sikre ytelse. Du kan søke etter alle bestillinger på en dag eller legge til et søkeparameter.',
    DURATION_IS_MORE: 'Vennligst begrens ditt søk til {{maxDuration}} dager.',
    ADDRESS_CANNOT_BE_DELETED: 'Adresse brukes som standardadresse og kan ikke slettes.',
    UNKOWN_ERROR: 'Endringer kunne ikke lagres',
    INCORRECT_STATES_OR_ASSIGNED_TO_SLOT: 'Ugyldig status eller planlagt på rute',
    CANNOT_MOVE_ORDERS: 'Kan ikke flytte ordre',
    DESTINATION_DEPARTMENT_INELIGIBLE: 'Destinasjonsavdelingen er ugyldig',
    DEPARTMENT_MISSING_ADDRESS_DETAILS: 'Avdelingen mangler adressedetaljer',
    MISSING_DELIVERY_PHONE_NUMBER: 'Telefonnummer mangler for',
    INVALID_DELIVERY_PHONE_NUMBER: 'Telefonnummeret er ikke gyldig for',
    ROLE_IN_USE: 'Rollen kan ikke fjernes siden rollen er i bruk.',
    INVALID_NAME: 'Rollenavnet må være unikt. En rolle med dette navnet eksisterer allerede.',
    TEXT_CANNOT_BE_DELETED:
      'Teksten brukes på en tjeneste og kan ikke slettes. Koble teksten fra tjenesten for å slette den.',
    INVALID_INVOICING_STATUS: 'Bestillingen har en status som ikke er gyldig for fakturering',
    ORDER_NOT_READY_FOR_INVOICING: 'Bestillingen er ikke klar for fakturering',
    ORDER_IS_PAUSED_FROM_INVOICING: 'Bestillingen er satt på pause for fakturering',
    ORDER_IS_EXCLUDED_FROM_INVOICING: 'Bestillingen er ekskludert fra fakturering',
    MISSING_ORDER_CORRECTION: 'Bestillingen mangler en korrigering',
    INVALID_MULTILEG_STATE_FOR_INVOICING: 'Det er bestillinger i multileg-gruppen som ikke er klare for fakturering',
    DUPLICATE: 'Dupliserte data',
    MISSING_DEPARTMENT_DETAILS:
      'Avdelingen har ikke område- og postnummerinformasjon. Vennligst legg til dette før du fortsetter.',
    INVALID_BANNER_NAME: 'Ugyldig bannernavn.',
    INVALID_WHATS_THIS_NAME: 'Ugyldig Hva er dette-modalnavn.',
    INVALID_CREDIT_ORDER: 'Kan ikke kreditere en ordre som ikke har blitt fakturert eller allerede er kreditert.',
    OVERLAPPING_BANNERS: 'URL overlapper med en eksisterende banner.',
    OVERLAPPING_WHATS_THIS_MODALS: 'URL overlapper med en eksisterende Hva er dette-modal.',
    noOrdersForDateAndCourier: 'Ingen bestillinger for den valgte datoen og enheten.',
    INVALID_ORDER_BILLING_CUSTOMER_NUMBER:
      'Faktureringskundenummer for type {{orderBillingType}} er enten null eller ugyldig.',
    MISSING_ORDER_BILLING_TYPE: 'Manglende ordre faktureringstype.',
    INVALID_ORDER_BILLING_TYPE:
      'Ordrefaktureringstype må være en av {{orderBillingTypes}}, men var - {{orderBillingType}}.',
    INVALID_ORDER_BILLING_INFO: 'Ugyldig ordre faktureringsinformasjon.',
    SMS_SERVICE_NOT_ORDERED: 'SMS-tjeneste er ikke bestilt for.',
    CANNOT_SEND_SMS_FOR_MESTER_GRONN_FUNERAL: 'Mester Grønn-begravelse er bestilt for.',
    SLOT_ROUTED_IN_HAPPY_FLOW: 'Tidsluke er rutet i Happy Flow for.',
    SERVICE_LEVEL_SHOULD_NOT_BE_NONE: 'Tjenestenivået er ingen for.',
    SLOT_NAME_ALREADY_CREATED: 'Rutenavn finnes allerede.',
    ORDER_IN_INCORRECT_STATE: 'Ordren er i feil tilstand.',
    ORDER_IN_INCORRECT_STATE_plural: 'Ordrene er i feil tilstand.',
    INVALID_PARAMETERS: 'Ugyldige parametere, {{description}}.',
    FAILED_DELETING_WEBHOOK_SUBSCRIPTION: 'Webhook-abonnement kunne ikke slettes. Prøv igjen senere.',
    DEFAULT_ROLE_ALREADY_EXISTS: 'En annen rolle {{name}} er allerede standard for samme brukertype.'
  },
  customer: {
    useTerminalAddressModalHeading: 'Bruk terminaladresse',
    settings: { webhook: { overviewTitle: 'Webhook-konfigurasjon' } },
    returnLastLegModalHeading: 'Opprett returben',
    returnLastLegModalDescription:
      'Å velge "ja" betyr at vi deler opp bestillingen i to ledd og legger til et returledd. "Nei" betyr at ingen returledd trenger å opprettes. "Ikke angitt" betyr at returhåndteringsregelen ikke er definert for en spesifikk kunde.',
    connectMatrices: 'Koble sammen matriser',
    brandedTrackingModalHeading: 'Branded Tracking fakturering',
    scanToCreatePackages: { title: 'Skann for å opprette pakker', disabled: 'Deaktivert' },
    invoiceSplit: { title: 'Fakturadeling', NotSet: 'Ikke satt' },
    activateNPSFromDate: 'Aktiver NPS fra dato',
    AddEdit: 'Legg til/rediger',
    startTypingPlaceHolder: 'Begynn å skrive',
    findCustomer: 'Finn en kunde',
    showAll: 'Vis alle kunder',
    customer: 'Kunde',
    groups: {
      title: 'Kundegruppe',
      titleWithCount: '{{count}} kundegrupper',
      name: 'Gruppenavn',
      description: 'Beskrivelse',
      numberOfCustomers: 'Antall kunder',
      none: 'Ingen kundegrupper',
      new: 'Ny kundegruppe',
      edit: 'Rediger kundegruppe',
      searchCustomer: 'Søk kunde',
      members: 'Kunder i gruppen',
      save: 'Lagre',
      deleteConfirm: 'Er du sikker på at du vil slette kundegruppen?',
      deleted: 'Kundegruppen er slettet.',
      cannotDelete: 'Kundegruppen kan ikke slettes fordi den brukes i en aviseringsregel.'
    },
    customerTitle: 'Kunder',
    customerNo: 'Kundenr',
    name: 'Navn',
    emailToSender: 'E-post til avsender',
    deviations: 'Avvik',
    add: 'Legg til',
    noOrders: 'Ingen ordre i Glow',
    noOrdersDescription: 'Kundene du har tilgang til har ingen ordre i Glow',
    choose: 'Vennligst velg kunde',
    search: 'Søk',
    customerNumber: 'Kundenummer',
    searchHint: 'Søk etter leveranser basert på sendings id, kundereferanse, pakke id, leveringsnavn eller adresse',
    senderName: 'Avsendernavn',
    addSenderName: 'Legg til avsendernavn',
    senderNameAdded: 'Avsendernavn lagt til',
    addInvoiceHDOrdersFromDate: 'Legg til startdato for fakturering av HD-ordre gjennom Glow',
    invoiceHDOrdersFromDate: 'Startdato for fakturering av HD-ordre gjennom Glow',
    invoiceHDOrdersFromDateAdded: 'Startdato for fakturering av HD-ordre endret',
    billingCustomerNumber: 'Faktureringskundenummer',
    addBillingCustomerNumber: 'Legg til faktureringskundenummer',
    billingCustomerNumberAdded: 'Faktureringskundenummer lagt til',
    searchPage: {
      fromDate: 'Fra dato',
      toDate: 'Til dato',
      today: 'I dag',
      tomorrow: 'I morgen',
      yesterday: 'I går',
      last30days: 'Siste 30 dager',
      plusMinus15Days: '+/- 15 dager',
      plusMinus30Days: '+/- 30 dager'
    },
    smsText: {
      title: 'SMS-tekst',
      addEditSmsText: 'Opprett/rediger SMS-tekst',
      smsTextUpdated: 'SMS-tekst opprettet/endret',
      description: 'Beskrivelse',
      submit: 'Lagre',
      smsType: 'SMS type',
      smsTypeTexts: {
        pickup: 'Sporings-SMS',
        pre_pickup: 'Forhåndssend sporings-SMS',
        manual_delay: 'Manuell forsinkelse SMS',
        eta_pickup: 'ETA SMS opphenting',
        eta_delivery: 'ETA SMS levering',
        flex_delivery_confirmation: 'Flex levering SMS-bekreftelse',
        advise: 'Rådgi SMS',
        delivery_confirmation: 'Leveringsbekreftelse SMS',
        delivery: 'Leverings SMS',
        deviated: 'Leveringsavvik SMS'
      }
    },
    showNotCollected: 'Ikke lastet',
    booking: {
      pickupReady: 'Klar for henting',
      pickup: 'Hentes',
      delivery: 'Leveres',
      deliveryAvailable: 'Levering tilgjengelig',
      priceComment:
        'Du blir fakturert i henhold til din kundeavtale med Bring. Avgifter er ikke inkludert i denne prisen.',
      serviceUnavailable: 'Den valgte tjenesten er ikke tilgjengelig for postnummer {{postalCode}}',
      recipientPrice: 'Mottakerpris:',
      customerContactPlaceholder: 'Bestilt av – Dette feltet er synlig på kundens faktura'
    },
    email: {
      emailAddress: 'E-mail address',
      true: 'yes',
      false: 'no',
      informationToCustomer: 'Informasjon til kunden',
      returnToCustomer: 'Retur til kunden',
      addEmailToList: 'Legg til e-post på listen',
      registeredEmailSubscription: 'Registrert e-postabonnement',
      updateEmail: 'Oppdater e-post',
      noRegisteredEmails: 'Ingen registrerte e-poster',
      detailsSavedSuccessfully: 'E-post til avsender ble lagret med suksess'
    },
    customerDeviations: {
      deviationType: 'Type avvik',
      deviation: 'Avvik',
      deviationCode: 'Avvikskode',
      deviationCode_plural: 'Avvikskoder'
    },
    customerInfo: 'Kundeinfo',
    customerName: 'Kundenavn',
    invoicingAddress: 'Fakturaadresse',
    organisationNumber: 'Organisasjonsnummer',
    visitingAddress: 'Besøksadresse',
    creditBlockedFrom: 'Kredittblokkert fra dato',
    creditBlockedFromInfo: 'Blokkér kreditt for denne kunden fra valgt dato',
    from: 'Fra',
    address: 'Adresse',
    zipCode: 'Postnummer',
    zipArea: 'Sted',
    country: 'Land',
    edit: 'Rediger {{name}}',
    selectCountry: 'Velg land',
    addNewCustomer: 'Legg til ny kunde',
    bookingPageModalHeading: 'Booking i kundeportalen',
    bookingPageModalDescription:
      'Skru av booking dersom kunden ikke skal kunne booke sendinger på dette kundenummeret i Glow kundeportal.',
    bookingActive: 'På',
    bookingInactive: 'Av',
    brandedTrackingActive: 'På',
    brandedTrackingInactive: 'Av',
    bookingPageAvailable: 'På',
    bookingPageHidden: 'Av'
  },
  feedback: {
    title: 'Tilbakemelding',
    message: 'Gi oss forslag til hvordan denne siden kan bli bedre',
    linkText: 'Gi tilbakemelding'
  },
  texts: {
    tabTitle: 'Tekster og oversettelser',
    title: 'Tekster',
    update: 'Oppdater tekst',
    group: 'Gruppering',
    edit: 'Endre',
    delete: 'Slett',
    add: 'Legg til tekst',
    reset: 'Nullstill',
    confirmDelete: 'Er du sikker på at du vil slette teksten?',
    added: 'Tekst lagt til',
    deleted: 'Tekst slettet',
    updated: 'Text endret',
    nameToolTip: 'Navn vises når tekst kobles til tjenesten'
  },
  countries: { norway: 'Norge', sweden: 'Sverige', denmark: 'Danmark', germany: 'Tyskland' },
  billingType: { day: 'dag', evening: 'kveld', weekend: 'helg', weekendEvening: 'helgekveld' },
  shipmentsExport: {
    unit: 'Enhet',
    exportButton: 'Eksporter til Excel',
    shipmentId: 'Sendingsnummer',
    returned: 'Returnert',
    department: 'Avdeling',
    customer: 'Kunde',
    customerNumber: 'Kundenummer',
    date: 'Dato',
    pickupEarliest: 'Tidligste opphenting',
    pickupLatest: 'Seneste opphenting',
    deliveryEarliest: 'Tidligste levering',
    deliveryLatest: 'Seneste levering',
    serviceCode: 'Tjeneste',
    additionalServices: 'Tilleggstjenester',
    pickupName: 'Opphenting',
    pickupAddress: 'Opphentingsadresse',
    pickupZipCode: 'Opphentingspostnummer',
    pickupZipArea: 'Opphentingssted',
    pickupCoordinates: 'Opphentingskoordinater',
    pickupInstructions: 'Henteinstrukser',
    deliveryName: 'Levering',
    deliveryAddress: 'Leveringsaddresse',
    deliveryZipCode: 'Leveringspostnummer',
    deliveryZipArea: 'Leveringssted',
    deliveryCoordinates: 'Leveringskoordinater',
    deliveryInstructions: 'Leveringsinstrukser',
    quantity: 'Antall',
    weight: 'Vekt',
    volume: 'Volum',
    customerRef: 'Kundereferanse',
    pickupContactPerson: 'Kontaktperson henting',
    pickupPhone: 'Telefon henting',
    deliveryContactPerson: 'Kontaktperson levering',
    deliveryPhone: 'Telefon levering',
    deliveryEmail: 'E-post levering',
    recipientRef: 'Mottakerreferanse',
    price: 'Pris',
    group: 'Gruppe',
    pickupTime: 'Opphentet/ forsøk',
    delivered: 'Levert/ forsøk',
    deliveryEtaSmsSentAt: 'Leverings ETA-SMS sendt',
    courierName: 'Sjåfør',
    goodsInfo: 'Forsendelsesinformasjon',
    note: 'Notat',
    slotName: 'Rute',
    estimatedArrivalTime: 'Estimert',
    orderSource: 'Ordrekilde'
  },
  importExport: {
    dropText: 'Dra fil hit for å laste opp',
    export: 'Eksporter',
    exportAddresses: 'Eksporter kundeadresser til Excel',
    importExcel: 'Importer fra Excel',
    imported: 'adresser importert',
    noRecipients: 'Ingen gyldige mottakere ble funnet',
    or: 'eller',
    recipients: 'mottakere',
    selectFile: 'Velg fil',
    uploadingFile: 'Laster opp...'
  },
  customMeasurements: {
    name: 'Navn',
    weight: 'Vekt',
    height: 'Høyde',
    length: 'Lengde',
    width: 'Bredde',
    heading: 'Egendefinerte godstyper',
    error: 'Feil ved lagring av godstype: ',
    confirmDelete: 'Er du sikker på at du vil slette denne godstypen?',
    new: 'Lag ny',
    customizeGoodsTypes: 'Definer egne godstyper',
    customGoodsTypes: 'Egendefinerte godstyper',
    defaultGoodsTypes: 'Standardgodstyper',
    invalidName: 'Navnet må inkludere minst én bokstav'
  },
  unresolved: {
    unresolvedAddresses: 'Uløste adresser',
    allResolved: 'Alle adresser er løst!',
    address: 'Addresse',
    noOfShipments: 'Antall leveranser',
    shipment: 'leveranse',
    shipment_plural: 'leveranser',
    shipmentNumber: 'Sendingsnummer',
    customerRef: 'Kundereferanse',
    senderName: 'Avsenders navn',
    senderPhone: 'Avsenders telefonnummer',
    recipientName: 'Mottakers navn',
    recipientPhone: 'Mottakers telefonnummer',
    searchAndSetLocation: 'Søk og velg lokasjon',
    setALocationToContinue: 'Velg lokasjon for å fortsette',
    resolveAddress: 'Løs adresse',
    suggestedAddressFound: 'Foreslått adresse funnet',
    thereIsOneUnresolvedAddress: 'Det er {{count}} uløst adresse',
    thereIsOneUnresolvedAddress_plural: 'Det er {{count}} uløste adresser'
  },
  optimizing: {
    department: 'Avdeling',
    userName: 'Bruker',
    viewReqResp: 'Vis',
    totalTime: 'Tid',
    kbSize: 'KB request',
    showAll: 'Vis alle',
    numberOfShipments: 'Antall sendinger',
    numberOfVehicles: 'Antall kjøretøy',
    processingTime: 'Behandlingstid'
  },
  date: {
    date: 'Dato',
    today: 'I dag',
    tomorrow: 'I morgen',
    yesterday: 'I går',
    dateAndTime: 'Dato og tid',
    from: 'Fra',
    fromDate: 'Fra dato',
    rangeOptions: {
      '15d': '+/- 15 dager',
      '30d': '+/- 30 dager',
      custom: 'Tilpassede datoer',
      last30d: 'Siste 30 dager',
      last7d: 'Siste 7 dager',
      month: 'Måned',
      months: 'Måneder',
      today: 'I dag',
      tomorrow: 'I morgen',
      yesterday: 'I går'
    },
    to: 'Til',
    toDate: 'Til dato'
  },
  routeReceipt: {
    printRouteReceiptList: 'Skriv ut kvitteringsliste',
    noRoutesInfoAvailable: 'Ingen ruteinformasjon tilgjengelig ennå'
  },
  homeDeliveryRouteList: {
    receiptList: 'Kvitteringsliste',
    shipments: 'Sendinger',
    weight: 'Vekt',
    volume: 'Volum',
    loadingMeters: 'Lastmeter',
    palletFootPrints: 'Pallplasser',
    sender: 'Avsender',
    package: 'Kolli',
    shipmentNo: 'Sendingsnummer',
    recipient: 'Mottaker',
    time: 'Tid',
    mobile: 'Mobil',
    other: 'Øvrig',
    attemptedDelivery: 'Bomtur',
    receipt: 'Kvittering',
    dateOfBirth: 'Fødselsdato',
    signature: 'Signatur',
    nameClarification: 'Navn',
    goodsLine1: 'Husk å sjekke varene for synlige skader før signering.',
    goodsLine2: 'Hvis det er synlige skader, be sjåføren registrere dem innen du signerer.',
    damage: 'Skade',
    missing: 'Mangler',
    packageNumber: 'Kollinummer',
    comments: 'Kommentar'
  },
  columnCategories: {
    aboutTheShipment: 'Om forsendelsen',
    goods: 'Varer',
    timeAndDate: 'Tid og dato',
    planning: 'Planlegging',
    pickupInformation: 'Henteinformasjon',
    customer: 'Kunde',
    deliveryInformation: 'Leveringsinformasjon',
    originalSender: 'Opprinnelig avsender',
    originalDelivery: 'Opprinnelig levering',
    service: 'Tjeneste',
    preAdvice: 'Forhåndsvarsel',
    invoicingInformation: 'Faktureringsinformasjon'
  },
  shipment: {
    offeredOrdersPending: 'Tilbudte ordrer venter på svar',
    shipment: 'Leveranse',
    customer: 'Kunde',
    customerContact: 'Kontaktperson hos kunden',
    customerReference: 'Kundereferanse',
    customerNumber: 'Kundenummer',
    route: 'Rute',
    service: 'Tjeneste',
    additionalServices: 'Tilleggstjenester',
    quantity: 'Antall',
    totalWeight: 'Total vekt',
    totalVolume: 'Totalt volum',
    totalLoadingMeters: 'Lastmeter totalt',
    totalPalletFootPrints: 'Pallplasser totalt',
    goodsInfo: 'Forsendelsesinformasjon',
    packages: 'Kolli',
    pickupInfo: 'Henteinformasjon',
    pickupDate: 'Hentedato',
    pickupTime: 'Hentetid',
    pickupInstructions: 'Henteinstrukser',
    deliveryInfo: 'Leveringsinformasjon',
    deliveryDate: 'Leveringsdato',
    deliveryTime: 'Leveringstid',
    receiverReference: 'Mottakerreferanse',
    deliveryInstructions: 'Leveringsinstrukser',
    trackingPage: 'Mottakers sporingsside',
    internalNote: 'Internt notat',
    closeWhileEditing: 'Er du sikker på at du vil stenge redigeringen?\nAlle ulagrede endringer vil bli tapt.',
    name: 'Navn',
    address: 'Addresse',
    zipCode: 'Postnummer',
    zipArea: 'Poststed',
    phone: 'Telefon',
    secondPhone: 'Telefon 2',
    email: 'E-postadresse'
  },
  bookingTimeWindow: {
    week: 'Uke',
    bookButton: 'Bestill',
    customButton: 'Tilpasset tidsvindu',
    removeBookingButton: 'Fjern bestilling',
    bookedInfo: 'Bestilt {{date}} mellom {{timeWindow}}',
    notBookedInfo: 'Ikke bestilt',
    tableHeaderDate: 'Dato',
    tableHeaderSlots: 'Tilgjengelige tidsvinduer',
    serverError: 'En feil har oppstått. Vennligst forsøk igjen senere.',
    previousButton: 'Forrige',
    nextButton: 'Neste',
    noAvailableDeliveryTimes: 'Ingen tilgjengelige leveringstider denne måneden',
    customTimeTitle: 'Angi tilpasset tidsvindu for forsendelse',
    customDateLabel: 'Dato',
    customTimeWindowLabel: 'Leveringstidsvindu',
    backFromCustomTimeButton: 'Tilbake til tilgjengelige tider',
    errorEarliestNotBeforeLatest: 'Tidligste leveringstid må være før seneste',
    arrivedAtTerminal: 'Ankommet på terminal',
    bookCustomTimeSlot: 'Book tidsvindu',
    shipmentBooked: 'Sending booket',
    shipmentBookedWithCustomTimeWindow: 'Sending booket med tilpasset tidsvindu',
    updateBooking: 'Oppdater booking',
    removeTimeSlot: 'Fjern tidsvindu',
    bookingChanged: 'Bookingen er endret',
    bookingSuccessful: 'Bookingen ble gjennomført',
    customTimeWindowAdded: 'Tilpasset tidsvindu for sending ble lagt til',
    timeFrom: 'Tid fra',
    timeTo: 'Tid til',
    timeFieldError: 'Den tidligste leveringstiden må være før den siste',
    availableTimeSlot: 'Tilgjengelige tidsvinduer',
    updateButtonTooltip: 'For å oppdatere bestillingen må du først velge et nytt tidsvindu'
  },
  manualOverride: {
    scannedFormDescription: 'Type ankomstscan trigges av hvilken avdeling du velger.',
    eventType: {
      delivered: 'Levert',
      collected: 'Hentet',
      returned: 'Returnert',
      deviated: 'Avvik',
      scanned: 'Skannet',
      preadvised: 'Preadvice-forsøk',
      'not-arrived-at-distributing-terminal': 'Ikke ankommet'
    },
    selectEventType: 'Velg en hendelsestype',
    noEventSelected: 'Ingen hendelsestype valgt',
    selectManifest: 'Velg flight',
    noManifestSelected: 'Ingen flight valgt',
    disabledEventReason: {
      returned: ' - Avvik påkrevd',
      collected: ' - Dispatching påkrevd',
      default: ' - Ikke tillatt'
    },
    fillDeliverOrReturnEventInfo: 'Fyll inn navn, dato og tid',
    fillDeviationEventInfo: 'Velg avvik og fyll inn dato og tid',
    fillGeneralEventInfo: 'Fyll inn dato og tid',
    eventMessage: 'Hendelser opprettet',
    allPackagesSelected: 'Alle kolli valgt',
    noActiveDriverError: 'Ingen aktive sjåfører på enheten',
    selectPackages: 'Velg kolli',
    selectDriver: 'Velg sjåfør',
    selectUnit: 'Velg enhet',
    selectDepartment: 'Overstyr avdeling'
  },
  zendesk: {
    email: 'Din epostadresse:',
    confirmEmail: 'Gjenta epostadresse:',
    send: 'Lagre',
    missingEmail:
      'For å kunne bruke de nye supportsidene, må du registrere din Posten- eller Bring-epostadresse. Du trenger bare å gjøre dette én gang.'
  },
  instantPlannerUnification: {
    message:
      'Modulene Instant og Planlegger er nå kombinert. Vennligst bruk Planlegger for å finne alt som tidligere lå under Instant.',
    linkTitle: 'Les mer her.'
  },
  shipmentFormPage: {
    findShipment: 'Finn sending',
    searchPhraseError: 'Du må oppgi minst 3 tegn',
    selectStateLabel: 'Velg status nedtrekksmeny',
    selectDateLabel: 'Velg dato nedtrekksmeny',
    submit: 'Vis resultater',
    inputPlaceholder: 'Søk sendingsnummer, telefonnummer, adresse, område, postnummer ...',
    clearButtonLabel: 'tøm søkefraseknapp',
    allStates: 'Alle statuser',
    customDate: 'Tilpasset',
    noDate: 'Ingen datobegrensning',
    selectFromDate: 'Velg fra dato',
    selectToDate: 'Velg til dato',
    include: 'Inkluder',
    exclude: 'Ekskluder',
    selectAdvancedOperator: 'Velg avansert operatør nedtrekksmeny',
    ofTheseRequirements: 'av disse kravene',
    tableColumn: 'Tabellkolonne',
    condition: 'Betingelse',
    searchTerm: 'Søkeord',
    all: 'ALLE',
    any: 'NOEN',
    tableDropdown: 'Velg tabell nedtrekksmeny',
    conditionDropdown: 'Velg betingelse nedtrekksmeny',
    typeSearch: 'Skriv inn søkeord',
    addNewRow: 'Legg til ny rad',
    deleteRow: 'Slett nåværende rad',
    service: 'Tjeneste',
    goodsDescription: 'Varebeskrivelse',
    route: 'Rute',
    unit: 'Enhet',
    deliveryArea: 'Leveringsområde',
    deliveryZipcode: 'Leverings postnummer',
    state: '{{count}} Status',
    state_plural: '{{count}} Statuser',
    today: 'I dag',
    tomorrow: 'I morgen',
    yesterday: 'I går',
    last30Days: 'Siste 30 dager',
    plusMinus15Days: '+/- 15 dager',
    plusMinus30Days: '+/- 30 dager',
    shipmentIds: 'Flere forsendelser',
    advancedFilter: 'Avansert filter',
    selectTable: 'Velg tabell',
    selectCondition: 'Velg betingelse',
    selectState: 'Velg status',
    orderId: 'Internt ordrenummer',
    allAnd: 'Alle (og)',
    anyOr: 'Noen (eller)'
  },
  shipmentResultPage: {
    noResultTitle: 'Ingen resultater funnet',
    batchLimitReached: 'Viser maksimalt 2000 sendinger, vurder å begrense søket',
    withoutDateLimitReached:
      'Når du søker uten dato, vil vi vise maksimalt {{limit}} forsendelser. Vurder å begrense søket ditt.',
    noResultExplanation: 'Vi fant ingen resultater. Prøv å søke med et annet ord.',
    findYourShipments: 'Finn dine sendinger',
    findYourShipmentsDescription:
      'Skriv inn det du leter etter i søkefeltet ovenfor. Flere alternativer finnes under avansert søk',
    searchByShipmentIdsLimitReached:
      'Ved søk etter sendings-ID viser vi maksimalt {{limit}} sendinger. Vurder å begrense søket ditt.',
    searchError: 'Søket mislyktes. Prøv å laste siden på nytt eller endre søkeparametrene'
  },
  shipmentTable: {
    quantity: 'Kvantitet',
    delEta: 'LEV ETA',
    delDuration: 'LEV Varighet',
    delDurationTitle: 'Leveringsvarighet',
    delDeliveryFrom: 'LEV fra',
    delDeliveryFromTitle: 'Leveransetid fra',
    delDeliveryTo: 'LEV til',
    delDeliveryToTitle: 'Leveransetid til',
    timeOfDelivery: 'Leveransetidspunkt',
    puDuration: 'HENT Varighet',
    puDurationTitle: 'Opphentingsvarighet',
    puPickupFrom: 'HENT fra',
    puPickupFromTitle: 'Opphenting fra',
    puPickupTo: 'HENT til',
    puPickupToTitle: 'Opphenting til',
    timeOfPickup: 'Opphentingstidspunkt',
    delEtaTitle: 'Forventet leveringstidspunkt',
    recipientRef: 'Mottakerreferanse',
    loading: 'Laster...',
    pickupName: 'Opphentingsnavn',
    deliveryName: 'Leveringsnavn',
    selectAllOnPage: 'Velg alle på siden ({{count}})',
    selectAll: 'Velg alle ({{count}})',
    deselectAll: 'Fjern alle valg',
    numSelectedShipments: '{{numSelected}} valgt',
    createLabels: 'Lag etiketter',
    editShipments: 'Rediger forsendelsesinformasjon',
    moveShipments: 'Flytt til et annet avdeling',
    yourSearchResult: 'Ditt søkeresultat',
    numberOfShipmentsFound: '{{count}} forsendelser funnet',
    orderedDeliveryDate: 'Bestilt leveringsdato'
  },
  shipmentResultActions: {
    confirmDeleteTitle: 'Slette sending?',
    confirmDuplicateTitle: 'Dupliser sending?',
    confirmDuplicateButton: 'Dupliser',
    deleteSuccess: 'Sendingen ble slettet',
    deleteFailure: 'Kunne ikke slette sendingen',
    manuallyPreAdviceSuccess: 'Preavisering(er) opprettet for forsendelse',
    manuallyPreAdviceSuccess_plural: 'Preadvice request saved for forsendelser',
    confirmDeleteTitle_plural: 'Slette valgte sendinger?',
    deleteSuccess_plural: 'Sendelse slettet',
    deleteFailure_plural: 'Kan ikke slette sendinger',
    invoiceActions: 'Fakturahandlinger',
    shipmentActions: 'Sendingshandlinger',
    showInvoicePayloadDisableTooltip: 'Sendinger må ha faktureringsstatus mislykket eller OK'
  },
  tablePagination: { goToPage: 'Gå til side', page: 'Side', of: 'av' },
  moveDepartmentModal: {
    errorMessage: 'Ordre: {{orderIds}}, feilmelding: {{error}}',
    ordersMovedText: 'Ordre {{orderIds}} har blitt flyttet til avdeling {{department}}',
    partialSuccessMessage: '{{count}} av {{fullCount}} sendinger ble flyttet',
    title: 'Flytt valgte sending(er) til en annen avdeling'
  },
  sendManualDelaySmsModal: {
    errorMessage: '{{error}} {{shipmentIds}}',
    partialSuccessMessage: '{{count}} av {{fullCount}} SMS ble sendt',
    successMessage: 'Manuell forsinkelses-SMS ble sendt vellykket'
  },
  multistopModal: {
    title: 'Gruppeinformasjon',
    description: 'Gruppevisning for bestillinger som betales sammen.',
    debitLines: 'Debetlinjer',
    notFound: 'Ingen gruppeinformasjon funnet',
    shipmentId: 'Sendingsnummer',
    deliveryDate: 'Leveringsdato',
    stopAddress: 'Stoppadresse',
    postalCode: 'Postnummer',
    noPrice: 'Ingen prisinformasjon tilgjengelig for gruppe',
    priceUpdated: 'Gruppens priser har blitt oppdatert'
  },
  shipmentDetails: {
    eventWasAdded: 'Hendelse ble lagt til',
    issueSaving: 'Det oppstod et problem med lagringen',
    noSelectedPackages: 'Ingen pakker ble valgt',
    pickupAndDelivery: 'Henting og levering',
    whereIsTheOrder: 'Hvor er bestillingen',
    communicationToRecipient: 'Kommunikasjon til mottaker',
    yesPremium: 'Ja, premium',
    alerts: 'Varsler',
    pickupDeliveryInfo: 'Hentings- og leveringsinformasjon',
    quickEdit: 'Hurtigredigering',
    quickEditTitle: 'Rediger hentings- og leveringsdetaljer',
    aboutShipment: 'Om sending',
    mapLinkText: 'Vis kartkoordinater',
    copyShipment: 'Kopier sending',
    editShipment: 'Rediger sendingsdetaljer',
    location: 'Sted',
    time: 'Tid',
    selectDate: 'Velg dato',
    manualChangeEvent: 'Manuelt endre hendelse',
    disabledEventReason: { returned: 'Avvik kreves', collected: 'Må være tildelt rute', default: 'Ikke tillatt' },
    selectDeviation: 'Velg avvik',
    required: 'Påkrevd',
    allPackages: 'Alle kolli',
    packages: 'kolli',
    package: 'kolli',
    changeDepartment: 'Endre avdeling',
    moveDepartment: 'Flytt avdeling',
    shipmentMoved: 'Sending flyttet',
    shipmentMovedText: 'Sending {{shipmentId}} er flyttet til avdeling {{department}}',
    shipmentMovedGoBack: 'Gå tilbake til søkesiden',
    moveShipmentDescription:
      'Hvis du ikke har tilgang til avdelingen, vil du ikke kunne finne sendingen etter flyttingen',
    moveShipmentConfirmText: 'Flytt sendingen til en annen avdeling?',
    addShipmentToSlot: 'Legg til sending i {{slot}}?',
    changeSlotConfirm: 'Endre rute for sending til {{slot}}?',
    changeSlot: 'Endre rute',
    selectSlot: 'Velg rute',
    AddToSlot: 'Legg til på rute',
    optimisationInProgress: 'Optimalisering pågår. Du kan ikke endre rute mens optimalisering pågår.',
    removeFromSlot: 'Fjern fra rute',
    removeShipmentFromSlot: 'Fjern sendingen fra rute',
    removeShipmentFromSlotHeading: 'Fjern sendingen fra denne ruten?',
    successRemoveFromSlot: 'Sendingen ble fjernet fra rute',
    successChangeSlot: 'Sendingen endret rute',
    successAddToSlot: 'Sendingen ble lagt til i rute',
    changeSlotDescription: 'Endring av rute utføres uten ruteoptimalisering.',
    addToSlotDescription:
      'Den valgte ruten vil bli optimalisert med de eksisterende sendingene som forblir i samme rekkefølge',
    pickupOrDeliveryChange: 'Hentingen eller leveringen er endret',
    pickupOrDeliveryChangeDescription:
      'Hentings- eller leveringsadressen er endret. Hvis du vil endre adressen tilbake, trykk på en av knappene nedenfor.',
    addressWasUnresolved: 'Adressen ble gjort uløst',
    eventTimeCannotBeInFuture: 'Hendelsestidspunktet kan ikke være i fremtiden'
  },
  location: 'Plassering',
  scannedAtDelegatedDepartment: 'Skannet fra den delegerte avdelingen.',
  pickupOrders: {
    acceptOrders: 'Aksepter ordre',
    selected: 'valgte',
    handleOrders: 'Håndter Opphentingsordre',
    found: 'funnet',
    accept: 'Aksepter',
    reject: 'Avvis',
    noResultText: 'Vi kunne ikke finne noe resultat. Vennligst forsøk med en annen dato.',
    last7d: 'I dag + 7 forrige dager',
    rejectOptions: {
      notFound: 'Avsender ikke funnet på opphentingsadressen. Ingen flere forsøk. Opphenting stengt.',
      terminatesPickupOrder: 'Avsender avslutter opphentingsoppdrag. Ingen flere forsøk. Opphenting stengt.',
      handedOver: 'Avsender har avlevert opphentingen til en annen tranrutetør. Ingen flere forsøk. Opphenting stengt.',
      noMoreAttempts: 'Avsender har flyttet. Ingen flere forsøk. Opphenting stengt.',
      pickupCorrected: 'Avsender har flyttet. Opphenting korrigert og sendt tilbake til underleverandør.',
      goodsNotReady: 'Gods ikke klart for opphenting. Ingen flere forsøk. Opphenting stengt.',
      incorrectBooking: 'Feil bestilling. Opphenting kansellert.',
      senderRefuses: 'Avsender nekter å overlevere godset. Ingen flere forsøk. Opphenting stengt.',
      addressCorrected: 'Feil opphentingsadresse. Adressen er korrigert.',
      incorrectAddressNoMoreAttempts: 'Feil opphentingsadresse. Ingen flere forsøk. Opphenting stengt.',
      senderNotified: 'Feil opphentingsadresse. Ingen flere forsøk. Opphenting stengt.',
      forbiddenGoods: 'Forbudte varer. Ingen flere forsøk. Opphenting stengt.',
      timeFrame: 'Henting kan ikke gjøres innen tidsrammen. Hentebestillingen er sendt tilbake til bestillingskontoret.'
    },
    mustSelectReason: 'Du må velge en grunn for avslaget',
    selectRejectReason: 'Velg grunn for avslaget*',
    selectReason: 'Velg grunn',
    commentPlaceholder: 'Valgfritt - gi ekstra informasjon for avslaget',
    acceptedSuccessToast: 'ble godkjent',
    rejectedSuccessToast: 'ble avvist',
    errorToast: 'Noe gikk galt',
    rejectOrders: 'Avvis bestillinger',
    shipment: '{{count}} forsendelse',
    shipment_plural: '{{count}} forsendelser'
  },
  editShipments: {
    pickupShouldBeBeforeDelivery: 'Opphentingstidsvindu må være før leveringstidsvindu',
    pickupEarliestShouldBeBeforeLatest: 'Den tidligste opphentingstiden må være før den seneste',
    deliveryEarliestShouldBeBeforeLatest: 'Den tidligste leveringstiden må være før den seneste',
    success: 'Sendingsinformasjon ble vellykket oppdatert',
    mixedShipmentsSelected: 'Du har valgt sendinger med og uten dato. Opphenting og leveringsinformasjon er påkrevd.',
    fieldNotEditable: 'Denne informasjonen kan ikke endres for ordre med status Tildelt til rute eller Opphentet',
    bulkActionDisabledReason: 'Ordre i status Avvik, Levert, Avvist eller Returnert kan ikke redigeres',
    withoutDateDisabledReason:
      'Kombinasjonen av ordre i status Tildelt til rute eller Opphentet og uten dato kan ikke redigeres',
    pickupDateTime: 'Opphenting dato og tid',
    deliveryDateTime: 'Levering dato og tid',
    earliestPickupTime: 'Tidligste hentetidspunkt',
    latestPickupTime: 'Seneste hentetidspunkt',
    earliestDeliveryTime: 'Tidligste leveringstid',
    latestDeliveryTime: 'Seneste leveringstid',
    pickupName: 'Opphenting navn',
    pickupPhoneNumber: 'Opphenting telefonnummer',
    deliveryName: 'Levering navn',
    deliveryPhoneNumber: 'Levering telefonnummer',
    pickupInstructions: 'Henteinstruksjoner',
    deliveryInstructions: 'Leveringsinstruksjoner'
  },
  routeManagement: { createRoute: 'Lag rute' },
  shipmentSelectColumn: {
    title: 'Tilpass kolonneoverskrift',
    addColumnHeader: 'Legg til kolonneoverskrift',
    addColumnBody:
      'Select the headings you want to be displayed in the data table. Our tip is to use 10-15 different\n    heading in order for the information not to be cut off. The exact amount depends on the size of the\n    device you are working on.',
    orderOfTable: 'Rekkefølgen på kolonneoverskriftene',
    orderOfTableDescription: 'Velg den foretrukne rekkefølgen for tabelloverskriftene ved å dra og slippe.'
  },
  bulkActions: {
    routeReceiptList: { disabledTooltip: 'Bestillinger kan ikke være i tilstanden løst eller uløst' },
    unassignShipmentFromSlot: 'Fjern sendingene fra rute',
    unassignShipmentFromSlotDisabledReason: 'Ordrene må være i status Tildelt til rute eller Tilbudt',
    forceUnassignShipmentFromSlot: 'Tving fjerning fra rute',
    disabledMoveDepartmentTooltip: 'Ordrer må være i tilstand Opprettet, Løst, Uavklart eller Avvist for å bli flyttet',
    deleteDisableTooltip:
      'Forsendelsen kan ikke slettes hvis den er i tilstand Opprettet, Uavklart, Løst, Tilbudt eller Tildelt',
    duplicateDisableTooltip: 'Sendinger må være i tilstand Avviket eller Returnert, og avdelingstype må være Bud',
    viewGroupDisabledTooltip: 'Bestillingen må tildeles før du kan vise og redigere gruppen'
  },
  dispatchModal: {
    title: 'Opphentinger og leveranser på rute {{name}}',
    flex: 'Fleks',
    force: 'Tvungen',
    invalidRouteOrder: 'Leveranse kan ikke være før opphenting. Endre rekkefølgen på ruten',
    submitDisabled: 'Planlegg rute på nytt for å aktivere knappen',
    replanDisabled: 'Rekkefølgen på ruten må være gyldig for å planlegge på nytt',
    noShipments: 'Ingen ordre på ruten',
    noShipmentsDescription: ' Du har fjernet alle ordre på ruten, trykk <i>Bekreft rute</i> for å lagre endringene',
    unassignShipmentFromRoute: 'Klikk for å fjerne ordre fra ruten',
    unassignBreakFromRoute: 'Klikk for å fjerne pausen fra ruten',
    editBreakOnRoute: 'Klikk for å endre pausen på ruten',
    earliestStartOfBreak: 'Tidligste start på pausen',
    latestStartOfBreak: 'Seneste start på pausen',
    replan: 'Planlegg rute på nytt',
    overrideOptimizingEngine: 'Overstyr standard optimaliseringsmotor',
    warning: 'Du har valgt å ikke bruke en optimaliseringsmotor på denne ruten. Det vil påvirke mottakeropplevelsen.',
    flexDisabled: 'Du kan ikke <strong>flekse</strong> individuelle ordre i en lastbærergruppe',
    forceDisabled: 'Du kan ikke <strong>tvinge</strong> individuelle ordre i en lastbærergruppe',
    removeDisabled: 'Du kan ikke <strong>fjerne</strong> individuelle ordre i en lastbærergruppe',
    orderDisabled: 'Du kan ikke <strong>endre rekkefølge</strong> på individuelle ordre i en lastbærergruppe'
  },
  vehicle: {
    weightCapacity: 'Vektkapasitet',
    volumeCapacity: 'Volumkapasitet',
    maxRangeKm: 'Rekkevidde',
    createNewVehicle: 'Opprett nytt kjøretøy',
    createAndEditVehicle: 'Opprett og rediger kjøretøy',
    listAllVehicle: 'Liste over alle kjøretøy i denne avdelingen',
    deleteSuccessMessage: 'Kjøretøyet ble slettet',
    createdSuccessMessage: 'Kjøretøyet ble opprettet',
    updatedSuccessMessage: 'Kjøretøyet ble oppdatert',
    deleteVehicle: 'Slett kjøretøy',
    notRecover: 'Denne handlingen kan ikke angres',
    createVehicle: 'Opprett kjøretøy',
    editVehicle: 'Rediger {{name}}',
    vehicleInformation: 'Kjøretøyinformasjon',
    selectFuelType: 'Velg drivstofftype',
    selectEuroClass: 'Velg Euro-klasse',
    settings: 'Innstillinger',
    activeDriver: 'Aktiv sjåfør',
    noVehicleControl: 'Ingen kjøretøykontroll',
    slower: 'Saktere',
    slow: 'Sakte',
    normal: 'Normal',
    faster: 'Raskere',
    speedFactorVehicle: 'Kjøretøyets hastighetsfaktor',
    driverSpeed: 'Sjåførhastighet',
    serviceSpeed: 'Servicehastighet',
    defaultStartLocation: 'Standard startsted er avdelingsadressen',
    specifyEndLocation: 'Angi sluttsted',
    earliest: 'Tidligst',
    latest: 'Senest'
  },
  invoicingStatus: {
    TO_BE_INVOICED: 'Ikke fakturert',
    INVOICING_FAILED: 'Fakturering mislyktes',
    CORRECTION_PENDING: 'Retting venter',
    INVOICING_PAUSED: 'Fakturering satt på pause',
    EXCLUDED_FROM_INVOICING: 'Ekskludert',
    NOT_READY_FOR_INVOICING: 'Ikke klar',
    INVOICED_OK: 'Fakturert OK',
    ZERO_PRICE: 'Mangelfull pris',
    AWAITING_ACKNOWLEDGEMENT: 'Avventer bekreftelse'
  },
  invoice: {
    articleCode: 'Artikkel',
    allOrdersInvoice: 'Alle ordre faktureres',
    allOrdersInvoiceDescription: 'Vi kunne ikke finne noen ufakturerte ordre.',
    handledBy: 'Håndtert av',
    invoicing: 'Fakturering',
    credit: 'Kreditt',
    invoice: 'Fakturér',
    create: 'Skape',
    invoiceOrderAction: 'Fakturere ordre',
    invoiceOrderAction_plural: 'Fakturer ordre',
    searchShipmentId: 'Søk sendingsnummer',
    state: '{{count}} status',
    state_plural: '{{count}} statuser',
    customerNumber: 'Kundenummer',
    carrierNumber: 'Enhetsnummer',
    details: 'Detaljer',
    information: 'Fakturainformasjon',
    reference: 'Fakturareferanse',
    items: 'Artiklar',
    quantity: 'Mengde',
    mustBeNegative: 'Må være < 0',
    mustBePositive: 'Må være > 0',
    description: 'Beskrivelse',
    customerAmount: 'Kundebeløp',
    carrierAmount: 'Enhetsbeløp',
    totalCustomerAmount: 'Totalt kundebeløp',
    totalCarrierAmount: 'Totalt enhetsbeløp',
    failedPriceListLink: 'Prisliste som feilet i Prismotor',
    failedPriceList: 'Mislykket prisliste',
    priceFailureDetail: 'Feildetaljer',
    priceFailureReason: 'Feilårsak',
    priceFailureReasons: {
      missingAttributeValue: 'Attributt mangler',
      matrixLookupMiss: 'Matrisefeil',
      invalidExpression: 'Ugyldig uttrykk',
      scriptError: 'Skriptfeil'
    },
    invoiceDepartment: 'Fakturér avdeling',
    invoiceDepartment_plural: 'Fakturér avdelinger',
    invoiceDepartments: 'Fakturér avdeling(er)',
    dashboard: 'Dashbord',
    invoicingJobsSummary: 'Oppsummering av faktureringsjobber',
    invoicingStatus: 'Faktureringsstatus',
    invoicingStatusDescription: 'Viser order fra de siste 3 månedene.',
    invoicingModalWarningInvoicedOk:
      'Forsendelsene vil ikke bli fakturert og vil bli ansett som håndtert. De vil forsvinne fra faktureringssiden.',
    notInvoicedOrders: 'Ikke fakturerte ordre',
    ordersOnPage: 'Viser {{count}} ordre på siden',
    ordersFoundCount: '{{count}} ordre funnet',
    ordersFoundCount_plural: '{{count}} ordre funnet',
    toBeInvoiced: 'Skal faktureres',
    ordersNotInvoiced: 'Ordre som ikke er fakturert ennå',
    invoicingFailed: 'Fakturering mislyktes',
    invoicingFailedDescription: 'Ordre som er behandlet, men mislykket',
    text: 'Fakturatekst',
    correctionPending: 'Med ordrekorrigering',
    correctionPendingDescription: 'Ordre med ventende rettelser',
    invoicingPaused: 'Fakturering satt på pause',
    ordersPausedFromInvoiced: 'Ordre som er satt på pause fra å bli fakturert',
    invoicingZeroPrice: 'Mangelfull pris',
    invoicingZeroPriceDescription: 'Ordre med ikke-godkjente nullrader',
    sendInvoices: 'Send fakturaer',
    sendInvoicesDescription:
      'Du er i ferd med å fakturere {{avdelinger}}. Velg dato eller datoperiode for faktureringsperioden.',
    sendInvoiceDateValidationError: 'Fra-dato kan ikke være senere enn Til-dato',
    sendInvoiceDateValidationErrorOneMonth: 'Maks datospenn er 1 måned',
    invoicingInProgress: 'Fakturering for {{departmentName}} pågår, {{count}} faktureringsjobber gjenstår',
    invoicingInFinished: 'Faktureringen for {{departmentName}} er fullført',
    orderEnqueuedForInvoicing: 'Ordren er satt i kø for fakturering',
    orderEnqueuedForInvoicing_plural: 'Ordre er satt i kø for fakturering',
    orderSuccessNotInvoiced: 'Ordren ble endret til ikke fakturert',
    orderSuccessNotInvoiced_plural: 'ordre ble endret til ikke fakturert',
    orderFailedInvoice: 'Fakturering for valgt ordre mislyktes',
    howManyInvoices: 'Hvor mange fakturaer vil bli sendt',
    fetchAmount: 'Hent beløp',
    wantToResend: 'Denne ordren er allerede fakturert. Er du sikker på at du vil sende den på nytt?',
    adHocInvoiceOrder: 'Du er i ferd med å fakturere denne ordren. Vil du fortsette?',
    adHocInvoiceOrder_plural: 'Du er i ferd med å fakturere disse ordrene. Vil du fortsette?',
    pauseOrders: 'Sett ordre på pause',
    datePeriod: 'Dato periode',
    ordersWereSuccessfullyPaused: '{{count}} ordre ble midlertidig stoppet fra fakturering',
    ordersWereSuccessfullyPaused_plural: '{{count}} ordre ble midlertidig stoppet fra fakturering',
    releaseOrders: 'Frigi ordre',
    recalculatePrices: 'Beregn priser på nytt',
    ordersSentForPriceRecalculation: '{{count}} order er blitt lagt i køen for ny beregning',
    ordersSentForPriceRecalculation_plural: '{{count}} ordre er blitt lagt i køen for ny beregning',
    ordersWereSuccessfullyReleased: '{{count}} ordre ble frigitt for fakturering',
    ordersWereSuccessfullyReleased_plural: '{{count}} ordre ble frigitt for fakturering',
    ordersWereSuccessfullyAcceptedWithIncompletePrice: '{{count}} ordre ble aksptert med ufullstendig pris',
    ordersWereSuccessfullyAcceptedWithIncompletePrice_plural: '{{count}} ordre ble aksptert med ufullstendig pris',
    addUnit: 'Legg til enhet',
    addUnitDescription: 'Legg til en enhet til dine valgte ordre. Denne enheten er den som vil bli egenfakturert.',
    addUnitToOrders: 'Legg til enhet i ordre',
    addUnitToOrder: '{{unit}} ble lagt til i {{count}} ordre',
    addUnitToOrder_plural: '{{unit}} ble lagt til i {{count}} ordre',
    addUnitBulkDisabled: 'Enhet kan kun legges til ordre uten enhet',
    approveIncompletePrices: 'Godkjenn med manglende priser',
    manualOverrideTitle: 'Manuell overstyring av faktureringsstatus',
    unitId: 'Enhets ID',
    bannerOldInvoicePage:
      'Den 26. august vil <0>den nye faktureringssiden</0> åpnes som standard. Vi oppfordrer deg til å begynne å bruke den nye siden. Mer info om den nye siden <1>her</1>.',
    acceptIncompletePricesTitle: 'Godkjenn mangelfulle priser',
    acceptIncompletePricesModalQuestion:
      'Du er i ferd med å godkjenne {{count}} ordre med mangelfull pris. Er du sikker på at du vil fortsette?',
    acceptIncompletePricesModalQuestion_plural:
      'Du er i ferd med å godkjenne {{count}} ordre med mangelfulle priser. Er du sikker på at du vil fortsette?',
    confirmCancelDialog: {
      title: 'Du har ulagrede endringer',
      question: 'Du har ulagrede endringer. Ønsker du å fortsette?',
      abort: 'Bli på siden',
      confirm: 'Fortsett uten å lagre'
    }
  },
  live: {
    onlyDeviated: 'Vis kun ruter med avvik',
    bannerOldLivePage:
      '<0>Prøv</0> den nye Live-siden, med forbedrede funksjoner og nytt design. Vi oppfordrer deg til å bruke den nye live-siden for å venne deg til den før vi fjerner den gamle siden fullstendig.',
    shipment: 'Leveranse',
    shipmentFrom: 'Fra',
    shipmentTo: 'Til',
    confirm: 'Bekreft',
    unconfirm: 'Avkreft',
    confirmedBy: 'Bekreftet av {{name}} klokken {{time}}',
    theCourier: 'Sjåføren',
    activeRoutes: 'Aktive ruter',
    routeDeviation: 'Avvik på ruten',
    routeDeviations: 'Avvik på ruten',
    multipleDeviations: 'Flere avvik',
    recentDeviations: 'Nylige avvik',
    noRoutesFound: 'Ingen ruter funnet',
    noDeviationsFound: 'Ingen avvik funnet',
    consignmentDamage: 'Skade på leveranse',
    shipmentDeviation: 'Avvik på leveranse',
    outsideServiceTime: 'Utenfor servicevindu',
    outsideEstimatedTime: 'Utenfor estimert tidsvindu',
    deliveryLocationDeviation: 'Uventet lokasjon',
    eventTextDeviationOne: '{{name}} har et leveranseavvik',
    eventTextDeviationMany: '{{name}} har {count} leveranseavvik',
    eventTextDamageOne: '{{name}} har skader på {count} leveranser',
    eventTextDamageMany: '{{name}} har skader på en leveranse',
    eventTextServiceTimeWindow: '{{name}} leverte utenfor servicevinduet',
    eventTextEstimatedTimeWindow: '{{name}} leverte utenfor det estimerte tidsvinduet',
    eventTextDeviated: '{{name}} har avviket fra ruten sin',
    eventTextDeliveryLocationDeviation: '{{name}} leverte/avvek {{distance}} meter fra forventet sted.',
    eventHelpDeviation: '{{name}} har registrert et avvik på leveranse {{shipmentId}}',
    eventHelpDamage: '{{name}} har registrert en skade på leveranse {{shipmentId}}',
    filterOngoing: 'Aktive',
    filterNotStarted: 'Ikke startet',
    filterFinished: 'Vis fullførte',
    filterOnlyDeviated: 'Vis kun ruter med avvik',
    filterShipmentDeviations: 'Avvik på leveranse',
    filterShipmentDamage: 'Skade på leveranse',
    filterEstimatedTime: 'Estimert tid',
    filterServiceTime: 'Servicetid',
    filterRouteDeviations: 'Avvik på ruten',
    filterDeliveryLocationDeviation: 'Uventet lokasjon',
    filterConfirmed: 'Bekreftet',
    routeProgress: 'Fullført {{complete}} av {{total}}',
    routeDuration: 'Fra {{from}} til {{to}}',
    routeDelayed: 'Forsinket',
    routeAhead: 'Foran',
    routeOnTime: 'Tidsnok',
    routeUnknown: 'Ukjent',
    routeDeviationsNone: 'Ingen avvik',
    routeDeviationCount: '{{unhandledDeviations}} av {{totalDeviations}}',
    routeHasHandleDeviations: 'Ruten har {{count}} håndtert avvik',
    routeHasHandleDeviations_plural: 'Ruten har {{count}} håndterte avvik',
    openRoute: 'Se rute',
    openShipment: 'Se leveranse',
    viewRoute: 'Se rute',
    deviated: 'Avvik',
    offRoute: 'Følger ikke ruten',
    onRoute: 'Følger ruten',
    routeStateFilter: 'Rutetilstandsfilter',
    allRoutesStates: 'Alle rutestatuser',
    selectedRouteStates: '{{count}} rutestatus',
    selectedRouteStates_plural: '{{count}} rutestatuser',
    noSelectedStates: 'Ingen valgte statuser',
    allDeviationTypes: 'Alle avvikstyper',
    selectedDeviationTypes: '{{count}} avvikstype',
    selectedDeviationTypes_plural: '{{count}} avvikstyper',
    showHandledDeviations: 'Vis håndterte avvik ({{count}})',
    noSelectedTypes: 'Ingen valgte typer',
    viewShipment: 'Se leveranse',
    selectAllRouteStates: 'Velg alle rutestatuser',
    noRoutesWithDeviations: 'Ingen ruter tilgjengelig',
    selectAllDeviationTypes: 'Velg alle avvikstyper',
    noDeviationOnRoute: 'Ingen avvik på ruten',
    noDeviationAvailable: 'Ingen ruter tilgjengelig',
    noDeviationsOnFilter: 'Det er ingen tilgjengelige avvik på det valgte filteret. Prøv et annet filter.',
    noRoutesOnFilter: 'Det er ingen tilgjengelige ruter på det valgte filteret. Prøv et annet filter.',
    eventHelpServiceTimeWindow:
      '{{name}} har levert leveranse {{shipmentId}} klokken {{actual}}, som er utenfor servicevinduet {{earliest}}-{{latest}}.',
    eventHelpEstimatedTimeWindow:
      '{{name}} har levert sendingsnummer {{shipmentId}} kl. {{actual}} som er utenfor det estimerte tidsvinduet {{earliest}}–{{latest}}.',
    eventHelpDriverDeviated: '{{name}} har levert leveranse {{shipmentId}} i feil rekkefølge.',
    eventHelpDriverDeviatedBefore:
      '{{name}} har levert leveranse {{shipmentId}} i feil rekkefølge ved å levere #{{actual}} før #{{expected}}.',
    eventHelpDriverDeviatedAfter:
      '{{name}} har levert leveranse {{shipmentId}} i feil rekkefølge ved å levere #{{actual}} etter #{{expected}}.',
    eventHelpDeliveryLocationDeviation: {
      withShipmentId:
        '{{name}} har levert eller avviket leveranse {{shipmentId}} <4>her</4>, {{distance}} meter unna <9>forventet lokasjon</9>.',
      withoutShipmentId:
        '{{name}} har levert eller avviket <4>her</4>, {{distance}} meter unna <9>forventet lokasjon</9>.'
    },
    search: 'Søk etter rute, enhet eller sjåfør',
    departmentFilter: 'Avdelingsfilter',
    allDepartments: 'Alle avdelinger',
    deviationTypeFilter: 'Avvikstypefilter',
    mostDeviations: 'Flest avvik',
    latestInTime: 'Senest på tid',
    earliestInTime: 'Tidligst på tid',
    sortOn: 'Sorter på',
    unhandled: 'uhåndterte',
    deviations: 'avvik',
    early: 'tidlig',
    stops: 'Stopp: {{stops}}',
    deviatedFromRoute: 'Avveket fra ruten',
    totalTimeMajorDeviations: 'Total tid for større avvik (≥5 min)',
    totalTimeMinorDeviations: 'Total tid for mindre avvik (<5 min)',
    lateOrEarlyFirstStop: 'For sen eller for tidlig til første levering',
    showAdjusted: 'Vis justert tid',
    showAdjustedTooltip: 'Justert tid vises på mottakerens sporingsside',
    showDiff: 'Vis stoppforskjell',
    diff: 'Stoppforskjell',
    showDiffTooltip: 'Forskjellen mellom planlagt (ETD) og faktisk tid mellom stopp',
    modalTableSummary: 'Total rutetid',
    liveModalActualTooltip1: 'Sammenlignet med ETD:',
    liveModalActualTooltip2: 'Grønn: Opptil 15 min',
    liveModalActualTooltip3: 'Oransje: 15–30 min',
    liveModalActualTooltip4: 'Rød: Mer enn 30 min'
  },
  userRolesAdmin: {
    userRoles: 'Brukerroller',
    successCreateMessage: '{{name}} ble opprettet',
    successUpdateMessage: '{{name}} ble oppdatert',
    saveRole: 'Lagre rolle',
    editRole: 'Rediger {{name}}',
    createNewRole: 'Opprett ny rolle',
    nameOfRole: 'Rollenavn',
    nameRequired: 'Navn er påkrevd',
    descriptionRequired: 'Beskrivelse er påkrevd',
    descriptionLabel: 'Beskrivelse (maks 240 tegn)',
    iconLabel: 'Velg hvilket ikon du ønsker for brukerrollen',
    selectRoleTitle: 'Velg tilgangsrett for rolle',
    emptyState: 'Ingen roller funnet for søkefrasen',
    deleteConfirm: 'Er du sikker på at du vil slette {{name}}?',
    deleteSuccess: 'Rolle {{name}} ble slettet',
    settings: 'Innstillinger',
    accessRights: 'Tilgangsrettigheter',
    settingsTitle: 'Innstillinger for rolle',
    settingsDescription: 'Engelsk er påkrevd. Hvis ingen andre språk velges, vil engelsk være reserve.',
    additionalLanguagesTitle: 'Legg til ekstra språk',
    customerPortalAccess: 'Tilgang til kundeportal',
    defaultForUserRole: 'Bruk som standardrolle (valgfritt)',
    event: 'Hendelse',
    financialAccess: 'Finansielle rettigheter',
    invoiceActions: 'Fakturahandlinger',
    orderAttributes: 'Ordreegenskaper',
    findApiOrder: 'Finn API-bestilling',
    optimus: 'Optimus',
    noAccessToApiOrder: 'Ingen tilgang til Finn API-bestilling',
    noAccessToOptimus: 'Ingen tilgang til Optimus',
    optimusViewDescription: 'Bruker kan se tjenester på avdelinger og kunder, men kan ikke gjøre endringer',
    optimusFullDescription: 'Bruker kan legge til og redigere tjenester for avdelinger og kunder',
    ordersApiSearchRestrictedDescription:
      'Bruker kan søke etter bestillinger fra avdelinger eller kunder de har tilgang til',
    ordersApiSearchFullDescription: 'Bruker kan søke etter bestillinger fra alle avdelinger og kunder',
    addRole: 'Legg til rolle',
    addedRole: 'Rolle lagt til',
    removeRole: 'Fjern rolle',
    importExport: 'Import / Eksport',
    importExportExtendedDescription: 'Inkludert tilgang til utvidet rapport for bud.',
    importExportExtendedSaaSDescription: 'Inkludert tilgang til sluttrapport for dagen.'
  },
  roles: {
    ADMIN_PRODUCTION_TIME_WINDOW: 'Produksjonstidsvindu'
  },
  validationError: {
    provideEmail: 'Du må oppgi en e-post',
    provideValidEmail: 'Du må oppgi en gyldig e-post',
    youMustSelectCustomer: 'Du må velge en kunde'
  },
  manageReturns: {
    title: 'Administrer returer',
    typesOfReturns: 'Returtype',
    ordersOverAPeriod: 'Viser ordre fra forrige måned.',
    pickedUpByCustomer: 'Hentet av kunde',
    pickedUpByCustomerDescription: 'Returer som skal hentes av kunden',
    transferToDifferentTerminal: 'Overfør til annen terminal',
    transferToDifferentTerminalDescription: 'Returer som skal sendes til en annen terminal',
    environmentalReturns: 'Miljøreturer',
    environmentalReturnsDescription: 'Returer for resirkulering, samlet i en container',
    returnForDelivery: 'Retur for levering',
    returnForDeliveryDescription: 'Returer som skal planlegges og leveres',
    MANUAL_RETURNS: 'Manuelle returer',
    manualReturnsDescription: 'Ordre hvor returdelen ennå ikke er opprettet',
    returnToSenderTitle: 'Vil du returnere sendingene til avsender?',
    manuallyMarkAsHandled: 'Marker som håndtert',
    manuallyMarkAsHandledTooltip: 'Når en forsendelse er merket som håndtert, forsvinner den fra listen',
    ordersFoundCount: '{{count}} ordre funnet',
    ordersFoundCount_plural: '{{count}} ordre funnet',
    PICKED_UP_BY_CUSTOMER: 'Hentet av kunde',
    TRANSFER_TO_DIFFERENT_TERMINAL: 'Overfør til annen terminal',
    ENVIRONMENTAL_RETURNS: 'Miljøreturer',
    RETURN_FOR_DELIVERY: 'Retur for levering',
    ordersAlreadyHandled: 'Alle ordre er håndtert',
    ordersAlreadyHandledDescription: 'Vi kunne ikke finne noen ubehandlede bestillinger.',
    manuallyMarkAsHandledModal: {
      title: 'Marker som håndtert',
      shipmentsCount: '{{count}} sendinger',
      shipmentsCount_plural: '{{count}} sendinger',
      description:
        'Når sendingen(e) er merket som håndtert, forsvinner de fra listen. Denne handlingen kan ikke angres. Forsendelsen(e) kan finnes på søkesiden.',
      confirm: 'Marker som håndtert'
    },
    manuallyMarkAsHandledSuccess: '{{count}} sending ble merket som håndtert',
    manuallyMarkAsHandledSuccess_plural: '{{count}} sendinger ble merket som håndtert',
    returnToSenderSuccess: '{{count}} sending ble returnert til avsender',
    returnToSenderSuccess_plural: '{{count}} sendinger ble returnert til avsender',
    selectCustomer: 'Velg kunde',
    customer: 'Kunde',
    customer_plural: 'Kunder',
    allCustomers: 'Alle kunder',
    selectDestination: 'Velg destinasjon',
    destination: 'Endelig destinasjon',
    destinationDIP: 'Mottaksterminal',
    returnShipmentColumns: 'Returinformasjon',
    allDestinations: 'Alle destinasjoner',
    noDate: 'Alle',
    zeroDays: '0 dager',
    oneTo6Days: '1–6 dager',
    moreThan7Days: '> 7 dager',
    moreThan15Days: '> 15 dager',
    moreThan30Days: '> 30 dager',
    customerDate: 'Tilpasset',
    daysOnTerminal: 'Dager på terminal',
    printList: 'Skriv ut liste',
    returnToSender: 'Returner til avsender',
    returnToSenderQueued: 'Retur til avsender er satt i kø',
    returnToSenderConfirmation: 'Vil du returnere denne sendingen til avsender?',
    returnToSenderFailed: 'Kunne ikke returnere sendingen til avsender',
    returnToSenderDisabledTooltip: 'Forsendelsen kan ikke returneres hvis den allerede er del av en multileg',
    senderDIP: 'Avsendende DIP',
    createReturnLabel: 'Opprett returetikett'
  },
  apolloAdmin: {
    title: 'Integrasjoner',
    emptyTitle: 'Ingen anrop',
    emptyDescription: 'Velg datoen du vil se og klikk på søk.',
    reqType: 'Type anrop',
    routeId: 'Rute-ID',
    routeName: 'Rutenavn'
  },
  preAdviceFollowup: {
    title: 'Administrer forhåndsvarsel',
    toBeHandled: 'Til håndtering',
    failedOrders: 'Feilede ordre',
    failedOrdersDescription: 'Mottakeren har ikke mottatt advisering',
    manualPreAdvice: 'Manuell advisering',
    manualPreAdviceDescription: 'Manuell advisering aktiveres fra administrasjonssiden',
    preAdviceLocked: 'Advisering låst',
    preAdviceLockedDescription: 'Ordre med låst advisering',
    missingRules: 'Adviseringsregel mangler',
    missingRulesDescription: 'Ordre som ikke er tilknyttet et adviseringsregelsett',
    inProgress: 'Advisering påbegynt',
    inProgressDescription: 'Ordre der adviseringsprosessen har startet',
    ordersFoundCount: '{{count}} ordre funnet',
    ordersFoundCount_plural: '{{count}} ordre funnet',
    FAILED_ORDERS: 'Advisering feilet',
    MANUAL_PRE_ADVICE: 'Manuell advisering',
    PRE_ADVICE_LOCKED: 'Advisering låst',
    MISSING_RULES: 'Adviseringsregler mangler',
    IN_PROGRESS: 'Advisering påbegynt',
    ordersAlreadyHandled: 'Alle ordre er håndtert',
    ordersAlreadyHandledDescription: 'Vi kunne ikke finne noen ubehandlede ordre.',
    updatePhoneNumber: 'Endre eller legg til telefonnummer',
    updateEmailAddress: 'Endre eller legg til e-postadresse',
    removeShipmentFromPreAdvice: 'Fjern ordre fra advisering',
    bookDateAndTime: 'Bestill dato og tid',
    manuallyPreAdvice: 'Manuell advisering',
    unlockPreAdvice: 'Lås opp advisering',
    lockPreAdvice: 'Lås advisering',
    triggerPreAdvice: 'Start advisering',
    selectedCustomers: '{{count}} kunde',
    selectedCustomers_plural: '{{count}} kunder',
    allCustomers: 'Alle kunder',
    selectRowWithOrderId: 'Velg rad med ordrenummer {{id}}',
    updateRecipientPhoneNumber: 'Oppdater telefonnummer',
    phoneNumberUpdated: 'Telefonnummeret er oppdatert for sendingsnummer {{shipmentId}}',
    phoneNumberUpdatedAndPreAdviceSent:
      'Telefonnummeret er oppdatert og advisering sendt for sendingsnummer {{shipmentId}}',
    samePhoneNumberAlreadySet: 'Duplikat. Angitt telefonnummer eksisterer allerede på sendingen.',
    updateRecipientEmailAddress: 'Oppdater e-postadresse',
    emailAddressUpdated: 'E-postadressen er oppdatert for sendingsnummer {{shipmentId}}',
    emailAddressUpdatedAndPreAdviceSent:
      'E-postadressen er oppdatert og advisering sendt for sendingsnummer {{shipmentId}}',
    sameEmailAddressAlreadySet: 'Duplikat. Angitt e-postadresse eksisterer allerede på sendingen',
    manuallyPreAdvicedSuccess: 'Vellykket advisering utført for {{count}} ordre',
    nrOfSelected: '{{count}} valgt',
    sendPreAdvice: 'Send advisering',
    unlockPreAdviceSuccess: 'Advisering låst opp for {{count}} ordre',
    unlockPreAdviceAndPreAdviceSent: 'Advisering låst opp og advisering sendt på {{count}} ordre',
    lockPreAdviceSuccess: 'Advisering låst opp for {{count}} ordre',
    saveAndSendPreAdvice: 'Lagre og send advisering',
    finishPreAdviceFollowupSuccess: 'Vellykket fjerning av {{count}} ordre fra advisering'
  },
  coordinates: {
    setCoordinates: 'Angi koordinater',
    title: 'Angi koordinater for adresser',
    setCoordinatesCount: 'Angi koordinater ({{count}})',
    zipCodeRange: 'Postnummerområde',
    goodJob: 'Bra jobbet',
    noUnresolvedShipments: 'Ingen uløste sendinger funnet for denne avdelingen eller avdelingsgruppen',
    coordinateSaved: 'Koordinater lagret for {{address}}',
    checkMapProvider: 'Sjekk på {{name}}',
    noDate: 'Ingen dato',
    applyFilter: 'Bruk filter',
    thereAreUnsetCoordinates: 'Du har {{count}} koordinat som må settes.',
    thereAreUnsetCoordinates_plural: 'Du har {{count}} koordinater som må settes.',
    noCoordinateFound:
      'Vi kunne ikke finne koordinatene til adressen. Søk etter adressen eller skriv inn koordinater (f.eks. 59.289976807684084, 18.010318840107406) i søkefeltet.'
  },
  relatedOrders: {
    NONE: 'Ingen relaterte ordre',
    MULTILEG: 'Multileg',
    PICKUP: 'Henting',
    TRANSPORT: 'Transport',
    DELIVERY: 'Levering',
    CUSTOMER: 'Kundeordre'
  },
  vendors: {
    title: 'Leverandører',
    viewDetails: 'Vis detaljer',
    searchLabel: 'Søk etter leverandørnavn, leverandør-ID eller enhets-ID',
    searchVendorPlaceholder: 'Søk etter leverandørnavn eller leverandør-ID',
    searchUnitPlaceholder: 'Søk etter enhets-ID',
    vendorOption: 'Leverandørnavn/ID',
    unitOption: 'Enhets-ID',
    notFound: 'Fant ingen leverandører med søkefrasen',
    initialSearch: 'Søk for å finne leverandører',
    informationAboutTheVendor: 'Informasjon om leverandøren',
    connectUnit: 'Koble til enhet',
    connectUnits: 'Koble til enheter',
    connectedUnits: 'Tilkoblet enhet ({{count}})',
    connectedUnits_plural: 'Tilkoblede enheter ({{count}})',
    paymentTerms: 'Betalingsbetingelser',
    connected: 'Tilkoblet',
    searchForUnitDescription: 'Å velge en enhet betyr at avdelingen enheten er lagt til vil ta kostnaden.',
    searchForUnitTitle: '{{vendorSite}} for {{operatingUnitName}}',
    successToast: 'Oppdaterte tilkoblede enheter til leverandør {{name}}',
    showConnectedUnits: 'Vis tilkoblede enheter',
    editConnectedUnits: 'Rediger tilkoblede enheter',
    searchForUnit: 'Søk etter enhet',
    searchPage: 'Søk side',
    currencyCode: 'Valutakode',
    connectedToCompany: 'Tilkoblet dette selskapet',
    noConnection: 'Ingen tilkobling',
    connectedTo: 'Tilkoblet til',
    nothingHereYet: 'Ingenting her ennå',
    emptyStateSelectedVendor: 'Velg en stedskode for å koble enheter eller se eksisterende tilkoblinger.',
    selfBilling: 'Avregning',
    setUnitSettingsTitle: 'Oppdater enhetsinnstillinger for {{name}}',
    setUnitSettingsSelfBillingInternalTrade: 'Avregning og intern handelstransaksjon er aktiv.',
    setUnitSettingsNone: 'Avregning og intern handelstransaksjon er ikke aktiv.',
    setUnitSettingsInternalTrade: 'Avregning er ikke aktiv, mens intern handelstransaksjon er aktiv.'
  },
  shdAdmin: {
    successfullyDeleted: 'Tidmatrisen ble slettet',
    successfullyUploaded: 'Tidmatrisen ble lastet opp',
    timeWindowForHomeDelivery: 'Tidsvindu for hjemlevering',
    filterByCustomerData: 'Filtrer etter kundens navn, underkunde eller kundens nummer',
    uploadTimeMatrixFile: 'Last opp tidmatrisefil',
    downloadExcelTemplate: 'Last ned Excel-mal',
    clickHereOrDragAndDrop: 'Klikk her eller dra og slipp',
    allowedFormats: 'Tillatte formater er XLS og XLSX',
    existingCustomers: 'Eksisterende kunder med SHD',
    customerPriceTooltip: 'Hvis sluttkundens pris skal vises, sett bryteren til på',
    deleteModalTitle: 'Slett tidsvinduer',
    deleteModalDescription:
      'Hvis du sletter dette tidsvinduet, vil alle SHD-tidsvinduer for denne kunden bli fjernet. Du vil ikke kunne angre denne handlingen.',
    exportToExcel: 'Eksporter til Excel',
    toggleLabel: 'Bytt kundens pris',
    customerNumber: 'Kundenummer',
    subCustomer: 'Underkunde',
    customerName: 'Kunde',
    amountOfPostalCodes: 'Antall postnummer',
    lastUpdatedAt: 'Sist oppdatert',
    showCustomerPrice: 'Vis kundens pris',
    uploadMaximumFileSizeLimit: 'Filstørrelsen kan ikke være større enn {{size}} KB'
  },
  serviceMapping: {
    title: 'Tjenester',
    serviceName: 'Tjenestenavn',
    pickupTime: 'Hentetid',
    deliveryTime: 'Levertid',
    internalName: 'Internt navn',
    internalDescription: 'Intern beskrivelse',
    externalName: 'Eksternt navn',
    externalDescription: 'Ekstern beskrivelse',
    alystraId: 'Alystra ID',
    allowFlexDelivery: 'Tillat fleksibel levering',
    timeWindow: 'Tidsvindu',
    serviceMappingType: 'Type',
    service: 'Tjeneste',
    vas: 'VAS',
    stopsBeforeShowEta: 'ETA stopp',
    minutesUntilDelivery: 'ETA Minutter',
    sendAutomaticEtaSmsHeading: 'ETA SMS',
    createNew: 'Lag ny',
    serviceAndVasCodes: 'Tjenester og VAS-koder',
    successfullyAdded: 'Tjenestekartlegging ble lagt til listen',
    successfullyUpdated: 'Tjenestekartlegging ble oppdatert',
    successfullyDeleted: 'Tjenestekartlegging ble slettet',
    editService: 'Rediger tjeneste',
    createNewService: 'Opprett ny tjeneste',
    settingsHeading: 'Innstillinger for tjeneste eller VAS',
    selectLevel: 'Velg nivå',
    standardLevel: 'Standard',
    customerLevel: 'Kunde',
    customerSpecific: 'Kunde spesifikk',
    selectServiceType: 'Velg tjenestetype',
    vasToolTip: 'Verditjeneste, f.eks. ID-kontroll',
    customerNumber: 'Kundenummer',
    pickupTimeInSeconds: 'Hentetid (sekunder)',
    pickupTimeSubsequentShipments: 'Hentetid for påfølgende forsendelser (sekunder)',
    deliveryTimeInSeconds: 'Levertid (sekunder)',
    deliveryTimeSubsequentShipments: 'Levertid for påfølgende forsendelser (sekunder)',
    pickupDurationSecondsApartment: 'Hentetid for leiligheter (sekunder)',
    deliveryDurationSecondsApartment: 'Levertid for leiligheter (sekunder)',
    deliveryDurationApartmentHeading: 'Levertid for leiligheter (for øyeblikket kun for H2)',
    timeWindowInMinutes: 'Tidsvindu (minutter)',
    stopsLeftHeading: 'Når vil du at eksakt ETA skal vises for kunden?',
    stopsLeftDescription: 'Hvis dette feltet er tomt, vil tidsvinduet vises',
    amountOfStops: 'Antall stopp',
    flexibleDelivery: 'Fleksibel levering',
    flexibleDeliveryDescription: 'Tillat mottakeren å velge at pakken blir levert utenfor døren',
    flexibleDeliveryCheckboxText: 'Ja, mottakeren skal kunne velge',
    linkTextToService: 'Lenk tekst til tjeneste',
    linkTextToServiceDescription: 'Hvis ingen tekst er valgt, vil standardtekst bli brukt',
    defaultText: 'Standardtekst',
    listOfServicesAndVases: 'Liste over tjenester og VAS-koder',
    searchService: 'Filtrer tjeneste, VAS eller kundenummer',
    customizedText: 'Egendefinert tekst',
    deleteModalTitle: 'Slett Tjenestekartlegging',
    deleteModalDescription: 'Denne handlingen kan ikke angres',
    infoModal: {
      title: 'Hva som vises hvor i Glow',
      subHeading1: 'Planleggere og sjåfører',
      description1: 'Internt navn: Navnet på tjenesten, f.eks. "Hjemmelevering"',
      description2:
        'Intern beskrivelse: Utvidet informasjon om tjenesten, f.eks. "Denne skal bæres inn i mottakerens valgte rom."',
      subHeading2: 'Kunder og mottakere',
      description3: 'Eksternt navn: Navnet på tjenesten, f.eks. "Hjemmelevering"',
      description4:
        'Ekstern beskrivelse: Utvidet informasjon om tjenesten, f.eks. "Varene dine vil bli brakt til et rom du velger".'
    },
    routeOptimizing: 'Ruteplanlegging',
    routeOptimizingDescription:
      'Informasjonen du gir her vil overskrive standardtiden og påvirke ruteoptimaliseringen. Standardoppsett for henting er 15 sekunder og for levering 180 sekunder.',
    settingsRecipientTrackingPage: 'Mottakers sporingsside',
    etaComponentHeading: 'Vis eksakt ETA på mottakers side',
    etaComponentDescription:
      'Ved å aktivere dette kan du velge når eksakt ETA skal vises for mottakeren i stedet for tidsvinduet.',
    displayExactEta: 'Ja, vis eksakt ETA',
    stopsLeftSelected: 'Antall stopp igjen til levering',
    minutesLeftSelected: 'Antall minutter igjen til levering',
    numberOfMinutes: 'Antall minutter',
    numberOfStops: 'Antall stopp',
    sendAutomaticEtaSmsTitle: 'Send automatisk SMS til mottakeren',
    sendAutomaticEtaSmsDescription:
      'Ved å aktivere dette, vil funksjonen for at sjåføren kan sende SMS fra sjåførappen også bli deaktivert.',
    sendAutomaticEtaSms: 'Send SMS automatisk',
    missingNumberOfStops: 'Vennligst oppgi et gyldig antall stopp når "Send SMS automatisk" er valgt',
    missingNumberOfMinutes: 'Vennligst oppgi et gyldig antall minutter når "Send SMS automatisk" er valgt',
    minutesNotInRange: 'Vennligst sett antall minutter mellom {{from}} og {{to}}',
    serviceDeliveryType: 'Tjenesteleveringstype',
    noServiceDeliveryTypeSelected: 'Ikke valgt',
    serviceDeliveryTypes: { Delivery: 'Levering', Return: 'Retur', PickupPoint: 'Hentepunkt' },
    Delivery: 'Levering',
    Return: 'Retur',
    PickupPoint: 'Hentepunkt',
    courierApp: 'Kurirapp',
    allowCourierDescription: 'Tillat kureren å akseptere eller avvise forsendelser med denne tjenestekoden',
    allowCourierCheckbox: 'Ja, kureren skal kunne velge'
  },
  matrix: {
    title: 'Matris',
    pageTitle: 'Matrix Admin',
    name: 'Navn',
    filename: 'Filnavn',
    uploaded: 'Opplastet',
    uploadedBy: 'Opplastet av',
    downloadTemplate: 'Last ned mal',
    byUser: 'Av bruker',
    country: 'Land',
    ROUTING: 'Ruting',
    LINEHAUL: 'Linehaul',
    HUB: 'Hub',
    DELIVERY: 'Levering',
    VAS: 'VAS',
    'booking-limits': 'Bokningsgrenser',
    searchLabel: 'Søk matrise etter navn eller kundenummer',
    'baggage-delivery': 'Bagasje',
    baggage: {
      title: 'Bagasjematriser',
      name: 'Bagasje {{country}}',
      legend: "Hvis du vil oppdatere eksisterende bagasjematriser, gjør du dette ved å velge 'last opp matrise data'.",
      uploadTitle: 'for {{country}}',
      custom: 'Egendefinert DS-kodematris',
      uploadCustomTitle: 'for egendefinerte DS-koder',
      status: 'Status',
      uploadFail:
        '{{label}} ble ikke akseptert på grunn av feil informasjon. Endre informasjonen og last opp matrisen på nytt.',
      statuses: { accepted: 'Akseptert', pending: 'Venter', rejected: 'Avvist' }
    },
    bookingLimits: {
      header: '{{name}} - Rediger bokningsgrenser',
      rules: '#Regler',
      total: 'Totalt',
      rank: 'Prioritet',
      customer: 'Kunde',
      customerNo: 'Kundenr',
      serviceCode: 'Tjeneste',
      timeWindow: 'Tidsvindu',
      selectTimeWindow: 'Velg tidsvindu',
      noTimeWindow: 'Ingen tidsvindu',
      noCustomer: 'Ingen kunde',
      bookingLimit: 'Bokningsgrense',
      removeLimit: 'Fjern grense',
      addMoreLimits: 'Legg til flere grenser',
      delete: 'Slett bokningsgrenser',
      deleteModalDescription: 'Du vil ikke kunne gjenopprette denne handlingen',
      deleted: 'Bokningsgrenser ble slettet',
      updated: 'Bokningsgrenser ble oppdatert'
    },
    master: 'Master',
    select: 'Velg {{matrix}} matrise...',
    overflow: '+{{overflow}} flere..',
    vasCodes: 'VAS-koder',
    connectedCustomers: {
      title: 'Koblede kunder',
      button: 'Se {{connectedCustomers}} koblede kunder',
      search: 'Søk etter kundens navn eller nummer',
      subcustomer: 'Underkunde'
    },
    upload: {
      success: 'Matrixdata ble lastet opp',
      question: 'Last opp matrise data',
      text: 'Last opp Excel',
      description: 'Last opp Excel-regneark med data for denne matrisen',
      error: 'Feil ved opplasting av data til {{name}}. {{error}}'
    },
    delete: {
      question: 'Slett matrise {{name}}',
      description: 'Dette kan ikke angres',
      success: 'Matrisen {{name}} ble slettet',
      error: 'Feil ved sletting av {{name}}. {{error}}'
    },
    new: {
      question: 'Lag ny matrise',
      description: 'Oppretter en ny matrise',
      success: 'Matrisen {{name}} ble opprettet',
      error: 'Feil ved oppretting av matrise. {{error}}'
    },
    edit: {
      question: 'Rediger matrise {{name}}',
      error: 'Feil ved oppdatering av {{name}}. {{error}}',
      success: 'Matrisen {{name}} ble lagret'
    },
    type: 'Velg type',
    day: { '1': 'Man', '2': 'Tir', '3': 'Ons', '4': 'Tor', '5': 'Fre', '6': 'Lør', '7': 'Søn' },
    leadTime: 'Ledetid',
    terminal: 'Terminal',
    terminalFrom: 'Terminal fra',
    terminalTo: 'Terminal til',
    postalCode: 'Postnummer',
    postalCodeFrom: 'Fra postnummer',
    postalCodeTo: 'Til postnummer',
    offsetDays: 'offsetdager',
    city: 'By',
    error: {
      duplicateName: 'Duplikat navn',
      duplicateServiceCode:
        'Bestillingsbegrensninger er allerede definert for denne kombinasjonen av kunde og tjenestekode',
      duplicateTimeWindow: 'Duplikat tidsvindu'
    }
  },
  shipmentDetailsBilling: {
    finanicalStakeholder: 'Finansielle interessenter',
    item: 'Vare',
    customerTotal: 'Kundens total',
    unitTotal: 'Enhetstotal',
    orderTotal: 'Ordretotal',
    disconnectToOrginalOrder: 'Koble fra opprinnelig forsendelsesnummer'
  },
  upsalesRulesAdmin: {
    title: 'Oppsalgsregler',
    titleSingular: 'Oppsalgregel',
    name: 'Navn',
    type: 'Type',
    searchPlaceholder: 'Søk i oppsalgsregler',
    settings: 'Innstillinger',
    successfullyAdded: '{{name}} ble lagt til i listen',
    successfullyUpdated: '{{name}} ble oppdatert',
    createNewRule: 'Opprett ny regel for mersalg',
    edit: 'Rediger',
    serviceUpgrade: 'Tjensteoppgradering',
    shorterTimeWindow: 'Kortere tidsvindu',
    ruleName: 'Navn på mersalgsregel',
    serviceUpgradeDescription1: 'Tjenesten oppgraderes fra 3123 til 2870.',
    serviceUpgradeDescription2: 'VAS 1381 vil automatisk bli lagt til for å sikre at sjåføren får korrekt betaling.',
    zipCodeRangeTitle: 'Postnummer',
    zipCodeRangeDescription:
      'Velg hvilke postnumre du vil inkludere eller ekskludere i regelen. Hvis du trenger å fjerne et spesifikt postnummer eller et område fra et inkludert område, bruk ekskluderingsalternativet for å finjustere valget ditt.',
    fromZipCode: 'Postnummer fra',
    toZipCode: 'Postnummer til',
    addRange: 'Legg til postnummerrange',
    addZipCode: 'Legg til postnummer',
    noZipCodeRangesAdded: 'Du må oppgi et postnummerrange',
    zipCodeRangeError: '"Postnummer fra" må ikke være større enn "Postnummer til"',
    deleteModalTitle: 'Slett mersalgsregel',
    deleteModalDescription: 'Du vil ikke kunne angre denne handlingen',
    successfullyDeleted: 'Mersalgsregel ble slettet',
    shorterTimeWindowDescription1: 'Kortere tidsvindu er tilgjengelig for 3332',
    windowOnlyInEvening: 'Kortere tidsvindu skal bare være tilgjengelig på kvelden',
    timeWindowOffer: 'Hvilket tidsvindu skal tilbys mottakeren?',
    oneHour: '1 time',
    twoHours: '2 timer',
    threeHours: '3 timer',
    mustSelectTimeWindow: 'Du må oppgi tidsvinduet som tilbys mottakeren',
    include: 'Inkluder',
    exclude: 'Ekskluder',
    zipCodeRangeType: 'Type',
    exactZipCode: 'Eksakt',
    zipCodeRange: 'Område',
    zipCode: 'Postnummer',
    mustSelectCountry: 'Du må velge et land først',
    includedZipCodes: 'Inkluderte postnumre ({{count}})',
    excludedZipCodes: 'Ekskluderte postnumre ({{count}})',
    noZipCodesAddedYet: 'Ingen postnumre er lagt til ennå',
    noMatchOnZipCodeSearch: 'Ingen postnummer funnet. Prøv å filtrere med et annet postnummerområde.',
    zipCodesFound: '{{count}} postnummer funnet',
    zipCodesFound_plural: '{{count}} postnumre funnet',
    filterOnDepartments: 'Filtrer på avdelinger',
    UPSALES_RULE_COLLISION_OCCURRED:
      'Regelen eksisterer allerede! Vennligst fjern enten følgende avdelinger: {{departments}} eller følgende kunder: {{customers}}',
    status: 'Status',
    active: 'Aktiv',
    paused: 'Pauset',
    pauseRule: 'Pause regel',
    activateRule: 'Aktiver regel',
    activateRuleModalDescription:
      'Du er i ferd med å aktivere regelen. Dette betyr at oppsalg vil være tilgjengelig for mottakerne. Ønsker du å fortsette?',
    pauseRuleModalDescription:
      'Du er i ferd med å pause regelen. Dette betyr at oppsalg ikke vil være tilgjengelig for mottakerne. Ønsker du å fortsette?',
    successfullyActivated: 'Oppsalgregelen ble aktivert',
    successfullyPaused: 'Oppsalgregelen ble pauset'
  },
  billingOrder: {
    standaloneInvoice: 'Separat faktura',
    standaloneInvoiceLabel:
      'Jeg vil at denne orderen skal behandles individuelt og ikke grupperes med andre ordre for fakturering.',
    noUnitNotSelfBilled: 'Ordre uten enhet vil ikke bli avregnet.'
  },
  welcomeToGlow: 'Velkommen til Glow',
  welcomeToGlowDescription: 'Bruk navigasjonen til venstre for å starte arbeidet ditt!',
  formValidation: {
    mustProvideName: 'Du må oppgi navn',
    mustProvideDescription: 'Du må oppgi beskrivelse',
    mustProvideServiceCode: 'Du må oppgi tjenestekode',
    mustProvideCalendarDays: 'Du må oppgi kalenderdager',
    mustProvideCutOffTime: 'Du må oppgi stoppetidspunkt',
    mustProvideCutOffMinutes: 'Du må oppgi stoppminutter',
    mustProvideDeliveryAttempts: 'Du må oppgi antall leveringsforsøk',
    mustProvideCustomer: 'Du må oppgi en kunde',
    mustProvideQuantity: 'Du må oppgi en mengde',
    mustProvideArticle: 'Du må oppgi en artikkel',
    mustProvideDate: 'Du må oppgi en dato',
    mustProvideCustomerOrUnitAmount: 'Du må oppgi kunde- eller enhetsbeløp'
  },
  units: {
    title: 'Enheter',
    new: 'Opprett ny enhet',
    connectFromOtherDepartments: 'Koble til fra andre avdelinger',
    connect: {
      title: 'Koble enheter til nåværende avdeling',
      description: 'Du kan bare søke etter enheter i avdelinger du har tilgang til',
      selected: 'Valgte enheter',
      noneSelected: 'Ingen enheter',
      submit: 'Koble til'
    },
    assigned: 'Enhet(er) ble koblet til'
  },
  productionTimeWindow: {
    addNew: 'Legg til nytt produksjonstidsvindu',
    search: 'Søk på mottaker-id',
    description:
      'Her kan du sette et produksjonstidsvindu for ordre som har en mottaker-id. For å legge til et produksjonstidsvindu, klikk på "Legg til nytt" eller søk opp et eksisterende for å redigere.',
    addNewDescription:
      'Legg til et tidsvindu for når vi kan levere pakker til en mottaker-id. Hvis produksjonstidsvinduet er lagt til, vil denne informasjonen bli brukt når vi ruter, i stedet for tjenestetidsvinduet.',
    recipientId: 'Mottaker-id',
    success: 'Produksjonstidsvindu for {{recipientId}} ble lagt til.'
  }
}
export default nb
